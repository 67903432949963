'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import getLocaleString from '../../helper/GetLocaleString';
import UserProfileImage from '../Elements/UserProfileImage';
import BaseComponent from '../../libs/components/BaseComponent';
import linkHashtags from '../../helper/LinkHashtags';
import renderCommentIcon from '../svgIcons/comment';
import I18n from 'i18n-js';
import $ from 'jquery';

export default class Message extends BaseComponent {
  componentDidMount = () => {
    $( this.message ).linkify({
      tagName: 'a',
      target: '_blank',
      newLine: '\n',
      linkClass: 'my-links',
      linkAttributes: null
    });
  };

  render() {
    // console.log('debug message!!!', this.props.message);
    var userAvatar = <UserProfileImage micropost={ this.props.message }/>;
    var userUrl = getLocaleString(this.props.locale) + "/users/" + this.props.message.user_infos.id;
    var headerClass = this.props.isOwner ? "dark-blue" : "blue";
    var sendPrivateMessage = "";
    var content = "";

    // To Do: delete own messages
    // console.log(this.props.isOwner)
    if( false /*this.props.currentUser.id != this.props.message.user_infos.id*/ ) {
      sendPrivateMessage = (
        <span className="comment-icon" style={{marginLeft: "0.4em"}}>
          { renderCommentIcon() }
        </span>
      );
    }

    if (this.props.message.body === 'system') {
      var text = "";

      if (this.props.message.system_message === 'left') {
        text = this.props.message.user_infos.name + I18n.t('conversations.left', {locale: this.props.locale});
      } else if (this.props.message.system_message === 'kicked') {
        text = this.props.message.user_infos.name + I18n.t('conversations.kicked', {locale: this.props.locale});
      } else if (this.props.message.system_message === 'joined' && this.props.myKey > 0) {
        text = this.props.message.user_infos.name + I18n.t('conversations.joined', {locale: this.props.locale});
      }

      if (text !== '') {
        content = (
          <span>
            <div className="clearfix"></div>
            <div className="bebas margin-top-10 gray" style={{ display: 'block', textAlign: 'center' }}>&mdash; { text } &mdash;</div>
          </span>
        );
      }
    } else {
      var foreignClass = "";
      var userImage = "";
      var userName = "";
      var messageSideClass = "ownMessage";

      if (this.props.currentUser.id != this.props.message.user_infos.id) {
        foreignClass = "foreignMessage";
        userName = (
          <a href={ userUrl } className="init-loading"><span className="bebas">{ this.props.message.user_infos.name }</span></a>
        );
        messageSideClass = "messageRight";
        userImage = (
          <div className="avatarLeft">
           <a href={ userUrl } className="init-loading">
             <div className="user-image-container">
               { userAvatar }
             </div>
           </a>
         </div>
        );
      }

      content = (
        <li className={ "message " + foreignClass }>
          { userImage }
          {/* <div className="avatarLeft">
            <a href={ userUrl }>
              <div className="user-image-container">
                { userAvatar }
              </div>
            </a>
          </div> */}

          <div className={ messageSideClass }>
            <div ref={ cancelImg => this.cancelImg = cancelImg } className="cancel-img hidden bebas-bold white dark-blue-bg" onClick={this.handleCancel}>x</div>
            <div className={ headerClass }>
              { /* userName */ }
              { sendPrivateMessage }
            </div>
            { this.props.message.image !== '' &&
              <img style={{ maxHeight: '200px', margin: '0 auto', borderRadius: '5px' }} src={ this.props.message.image } />
            }
            <div ref={ message => this.message = message } className="message-content dark-blue chat-body" dangerouslySetInnerHTML={{__html:  linkHashtags( this.props.locale, this.props.message.body ) }}></div>
            <div className="time right gray">{ this.props.message.time }</div>
          </div>
        </li>
      );
    }

    return (
      <div>{ content }</div>
    );
  }
}
