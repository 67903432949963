// require ../mixins/HandleShare.mixin
'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import getLocaleString from '../../helper/GetLocaleString';
import getFirstName from '../../helper/GetFirstName';
import UserProfileImage from '../Elements/UserProfileImage';
import OptionalLink from '../Elements/OptionalLink';
import BaseComponent from '../../libs/components/BaseComponent';
import linkHashtags from '../../helper/LinkHashtags';
import renderComment from '../svgIcons/comment';
import levelInWords from '../../helper/LevelInWords';
import Modal from '../Elements/Modal';
import renderPlaneIcon from '../svgIcons/i/plane';
import renderAngleRight from '../svgIcons/angleRight';

import $ from 'jquery';
import I18n from 'i18n-js';

export default class UserFeedItem extends BaseComponent {
	constructor(props) {
    super();
    this.state = {
      sending: false,
			isModalOpen: false,
			privateConvo: props.user.existing_private_convo,
    };
  }

	componentDidMount = () => {
    $( this.userContent ).linkify({
		  tagName: 'a',
		  target: '_blank',
		  newLine: '\n',
		  linkClass: 'my-links',
		  linkAttributes: null
		});

		var	backgroundSize = "100% auto";
		if( this.props.user.post_img_path == "/assets/icons/loader.svg" ) {
		var	backgroundSize = "auto";
		}

		if( this.props.user.post_img_path ) {
  		$( this.postImage ).css({'background-image': 'url(' + this.props.user.post_img_path + ')',
                                                 'background-size': backgroundSize, "background-repeat": "no-repeat"});
  	}

    var user = this.user;
    // user.addEventListener("unfollowAll", this.handleParticipation, false);
		user.addEventListener("layoutNeedsChange", this.handleLayoutNeedsChange, false);
	};

	componentWillUnmount = () => {
		var user = this.user;
    // user.removeEventListener("unfollowAll", this.handleParticipation, false);
		user.removeEventListener("layoutNeedsChange", this.handleLayoutNeedsChange, false);
	};

	handleLayoutNeedsChange = () => {
		if( this.props.handlePackeryFit ) {
			this.props.handlePackeryFit( this.user, this.state.layoutChange );
		}
	};

	userOrSignup = (e) => {
		if( this.props.currentUser.guest ) {
			e.preventDefault();
			e.stopPropagation();
			var customEvent = new CustomEvent("openModal", { bubbles: true });
      		e.target.dispatchEvent( customEvent );
		}
	};

	openModal = (e) => {
    e.preventDefault();
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

	createConversation = (e,node) => {
    e.preventDefault();
    e.stopPropagation();

    if( !this.props.currentUser.guest ) {
      var that = this;
      var form = this.messageForm;
      var body = this.message;
      var data = new FormData( form );
      var bufferContent = body.value;

      if( body.value.length > 0 ) {
        $.ajax({
          url: form.getAttribute('action'),
          type: 'POST',
          data: data,
          processData: false,
          contentType: false
        })
        .done(function(data) {
					that.setState({ privateConvo: [data] });
          // window.location.reload(false);
        })
        .fail(function( xhr, status, err ) {
          console.log(xhr)
          setTimeout(function() {
            that.setState({ sending: false });
            body.value = bufferContent;
          }, 300);
        });
      }
      else {
        $( this.error_messages ).text(I18n.t('conversations.empty_message', {locale: this.props.locale}));
        this.setState({ sending: false });
      }
    }
    else {
			var customEvent = new CustomEvent("openModal", { bubbles: true });
      this.sendMessage.dispatchEvent( customEvent );
    }
  };

	handleOnFocus = (e) => {
    // console.log('textarea got focus!!!!');
    $(this.profilePic).hide();
    $(this.sendMessage).show();
		$(this.shiftRight).removeClass('messageRight').css({ marginLeft: 0 });
    $(this.message).css({ width: '88%'});
  }

  handleOnBlur = (e) => {
    var input = $(this.message).val();
    // console.log('textarea lost focus!!!!', e);
    if (input.length === 0) {
      $(this.profilePic).show();
      $(this.sendMessage).hide();
      $(this.message).css({ width: '100%'});
			$(this.shiftRight).addClass('messageRight').css({ marginLeft: '46px' });
    }
  }

  render() {
  	var userUrl = getLocaleString(this.props.locale) + "/users/" + this.props.user.id;
		var userAvatar = <UserProfileImage micropost={ {user_infos: this.props.user.user_infos} } width={80} height={80} />;
		var smallUserAvatar = <UserProfileImage micropost={ {user_infos: this.props.currentUser.user_infos} } />;
		var titleClass = "blue";
		var titleHeight = "";
		var overlay = "";
		var openTarget = this.props.standalone ? "_blank" : "_self";
		var directMessage = "";
		// var placeholder = I18n.t('users.message_placeholder', {locale: this.props.locale, user: getFirstName(this.props.user.user_infos.name)});
		var sendText = this.state.sending ? "Loading..." : I18n.t('conversations.send', {locale: this.props.locale});
		var about = "";
		var now = new Date();
    now = new Date(now.getTime() + (now.getTimezoneOffset() * 60000));
    var premiumDate = new Date(this.props.user.user_infos.premium);
    var premiumStyle = {textAlign: 'center'};
    var premiumBadge = "";
    var verifiedBadge = "";
		var cardBorder = {};
		var contactRequestForm = "";

		if (premiumDate >= now) {
			// cardBorder = {borderBottom: "1px solid #E8D176", borderTop: "1px solid #E8D176", borderColor: '#E8D176'};

      premiumStyle = {
          color: '#E8D176',
					textAlign: 'center',
					fontWeight: 'bold',
      };

      premiumBadge = (
        <OptionalLink href="/premium" className="premium-badge dark-blue" style={{top: 0}} onClick={ this.userOrSignup } currentUser={ this.props.currentUser }>Premium</OptionalLink>
      );

      verifiedBadge = (
        <OptionalLink href="/premium" className="verified-badge dark-blue" style={{top: 0}} onClick={ this.userOrSignup } currentUser={ this.props.currentUser }>{ I18n.t('users.verified', {locale: this.props.locale}) }</OptionalLink>
      );
    } else if (this.props.user.user_infos.premium) {
      verifiedBadge = (
        <OptionalLink href="/premium" className="verified-badge dark-blue" style={{top: 0}} onClick={ this.userOrSignup } currentUser={ this.props.currentUser }>{ I18n.t('users.verified', {locale: this.props.locale}) }</OptionalLink>
      );
    }

		if (this.props.user.truncated_about) {
			about = (
				<div ref={ userAboutText => this.userAboutText = userAboutText } id="user-about-text" className="micropost-content dark-blue margin-bottom-05" dangerouslySetInnerHTML={{__html:  linkHashtags( this.props.locale, this.props.user.truncated_about, this.props.standalone ) }}></div>
			);
		}
		if( this.props.user.post_img_path ) {
			titleClass = "white title-shadow";
			titleHeight = "post-with-pic";
			overlay = "overlay";
		}
		// console.log('check data!!!!!', this.props);
		if( this.state.privateConvo.length > 0 && this.props.currentUser.id != this.props.user.id ) {
      var privateConversationUrl = getLocaleString(this.props.locale) + "/private_conversations/" + this.state.privateConvo[0].id;
      directMessage = (
				<div className="" style={{padding: '0'}}>
					<a href={ privateConversationUrl } className="send-private-message blue bebas">{ renderComment() } { I18n.t('users.open_private_chat', {locale: this.props.locale}) }</a>
				</div>
      );

			contactRequestForm = (
	        <div className="column full side-shadow center" style={{ padding: '0 0 0.4rem 0' }}>
	          { directMessage }
	        </div>
	    );
    }
    else if ( this.props.currentUser.id != this.props.user.id ) {
      directMessage = (
				<a href="javascript: false" className="white no-underline bold right" style={{ color: "#22a8b5", display: 'none'}} onClick={ this.createConversation } ref={ sendMessage => this.sendMessage = sendMessage } disabled={ this.state.sending }>
					{/* sendText */}
					{ renderPlaneIcon() }
				</a>
      );

			contactRequestForm = (
	        <div className="column full side-shadow" style={{ padding: '0.2rem 0 0.4rem 0' }}>
	          <span ref={ error_messages => this.error_messages = error_messages } className="red bebas"></span>
	          <form ref={ messageForm => this.messageForm = messageForm } className="new_message" action={ this.props.contactRequestForm.action } acceptCharset="UTF-8" method="post">
	            <div className="">
	              <div>
	                <div ref={ profilePic => this.profilePic = profilePic } className="avatarLeft" style={{ top: 0, width: '40px' }}>
	                  { smallUserAvatar }
	                </div>
	                <div className="messageRight" style={{ marginLeft: '46px' }}>
	                  <input className="full-width last-name-signup-input" type="text" tabIndex="-1" name="conversation[title]" placeholder={ I18n.t('createaccount.your_name', {locale: this.props.locale}) } value="" autoComplete="off"/>
	                </div>
	                <div ref={ shiftRight => this.shiftRight = shiftRight } className="messageRight" style={{ marginLeft: '46px' }}>
	                  <textarea ref={ message => this.message = message } rows="2" style={{ borderRadius: '5px' }} placeholder={ I18n.t('users.privateMessagePlaceholder', {locale: this.props.locale, user: this.props.user.user_infos.name.trim() }) } className="conversation_body" name="message[body]" onFocus={ this.handleOnFocus } onBlur={ this.handleOnBlur }></textarea>
										{ directMessage }
									</div>
	              </div>
	            </div>
	            <div className="bottom bebas font-size-11">

	            </div>

	            <input name="locale" type="hidden" value={ this.props.locale } />
	            <input type="hidden" name={ this.props.contactRequestForm.csrf_param } value={ this.props.contactRequestForm.csrf_token } />
	            <input value={ this.props.currentUser.id } type="hidden" name="conversation[sender_id]" />
	            <input value={ this.props.user.id } type="hidden" name="conversation[recipient_id]" />
	          </form>
	        </div>
	    );
    }


    return (
			<div>
				{/* <Modal isOpen={this.state.isModalOpen} closeFunction={ this.closeModal }>
					<div className="container">
						<h2 className="bebas margin-top-10 text-left">{ I18n.t('users.message_popup_heading', {locale: this.props.locale, user: getFirstName(this.props.user.user_infos.name)}) }</h2>
					</div>

					{ contactRequestForm }
				</Modal> */}

	      <article ref={ user => this.user = user } id={ this.props.user.id } className="feed-item user">
		      <div className="inner">
						<div className="body" style={cardBorder}>
							<div className="gray bebas profile-detail-icons location" style={{float: "right"}}>{ premiumBadge }{ verifiedBadge }</div>
							<div className="clearfix"></div>

							<div className="user-infos bebas" style={{display: "block", textAlign: "center", marginBottom: "1.5rem"}}>
						    <OptionalLink ref={ userLink => this.userLink = userLink } href={ userUrl } target={ openTarget } onClick={ this.userOrSignup } className="white-bg-link no-underline init-loading" currentUser={ this.props.currentUser }>
						    	{ userAvatar }
						    </OptionalLink>
						  </div>

							<OptionalLink href={ userUrl } className="white-bg-link dark-blue no-underline init-loading" onClick={ this.userOrSignup } style={premiumStyle} currentUser={ this.props.currentUser }><div className="user-name" style={{display: "block", textAlign: "center", left: 0}}>{ this.props.user.user_infos.name }</div></OptionalLink>
							<div className="profile-detail-icons location" style={{textAlign: "center", fontSize: '0.9rem', color: '#888'}}>{ this.props.user.location !== 'null' ? this.props.user.location : '-' }</div>
							<h5 className="margin-bottom-0" style={{textAlign: "center", margin: 0}} style={premiumStyle}><span className="star left-side">&#x2605;</span> <span style={{ fontWeight: 300 }}>{ levelInWords(this.props.user.level, this.props.locale) }</span> <span className="star right-side">&#x2605;</span></h5>
						  { about }

							<div className="clearfix"></div>

							<div className="post-icons" onClick={ this.userOrSignup } style={{display: "block", textAlign: "center", margin: "0 auto 0.3rem auto", maxWidth: "100%" }}>
								<OptionalLink href={ userUrl } className={"bebas text-right right dark-blue more init-loading"} style={{ position: 'relative', top: '3px' }} currentUser={ this.props.currentUser }>
									{ renderAngleRight() }
								</OptionalLink>
							</div>

							<div className="clearfix"></div>

							{ contactRequestForm }
							<div className="clearfix"></div>
						</div>

					</div>
				</article>
			</div>
    )
  }
}
