'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import getLocaleString from '../../helper/GetLocaleString';
import FacebookLogin from '../Elements/FacebookLogin';
import BaseComponent from '../../libs/components/BaseComponent';
import $ from 'jquery';
import I18n from 'i18n-js';

export default class UsersNewView extends BaseComponent {
  constructor() {
    super();
    this.state = {
     randomNumber: 0,
    };
  }

  componentDidMount = () => {
    this.generateRandomNumber();
  }

  generateRandomNumber = () => {
    if (this.state.randomNumber === 0 && typeof window !== 'undefined') {
      this.setState({
        randomNumber: Math.floor((Math.random() * 1000000) + 100000),
      });
    }
  }

  handleChange = () => {
    var $checkbox = $( this.accept );
  };

  render() {
    var termsUrl = getLocaleString(this.props.initialData.locale) + "/terms/";
    var loginPath = this.props.initialData.loginPath;

    return (
      <div className="row">
        <div className="full container" style={{ maxWidth: '850px'}}>
          <div className="column half text-center hide-for-small" style={{ padding: '15px 0'}}>
            <h3 className="dark-blue" style={{ fontWeight: 300 }}>{ I18n.t('createaccount.facts_heading', {locale: this.props.initialData.locale}) }</h3><br />
            <ul className="signup-check" style={{listStyle: ''}}>
              <li>{ I18n.t('createaccount.free', {locale: this.props.initialData.locale}) }</li>
              <li>{ I18n.t('createaccount.real_people', {locale: this.props.initialData.locale}) }</li>
              <li>{ I18n.t('createaccount.explore', {locale: this.props.initialData.locale}) }</li>
              <li>{ I18n.t('createaccount.no_ads', {locale: this.props.initialData.locale}) }</li>
              <li>{ I18n.t('createaccount.privacy', {locale: this.props.initialData.locale}) }</li>
            </ul>
          </div>

          <div className="column half">
            <div className="container margin-auto max-width-form">
              <form ref={ signUpForm => this.signUpForm = signUpForm } autoComplete="off" id="new_user" action={this.props.initialData.userForm.action} acceptCharset="UTF-8" method="post">
                <label className="text-left" htmlFor="user_name">Name</label>
                <input className="full-width" type="text" name="user[name]" placeholder={ I18n.t('createaccount.your_name', {locale: this.props.initialData.locale}) } value={ this.props.initialData.userForm.name || '' } id="user_name" autoComplete="off"/>

                <label className="text-left last-name-signup-label" htmlFor="user_last_name">Last Name</label>
                <input className="full-width last-name-signup-input" type="text" tabIndex="-1" name={"user[last_name_" + this.state.randomNumber + "]"} placeholder={ I18n.t('createaccount.your_name', {locale: this.props.initialData.locale}) } value="" id="user_last_name" autoComplete="off"/>

                <label className="text-left" htmlFor="user_email">Email</label>
                <input className="full-width" type="email" name="user[email]" placeholder={ I18n.t('createaccount.your_email', {locale: this.props.initialData.locale}) } value={ this.props.initialData.userForm.email || '' } id="user_email" autoComplete="off"/>

                <label className="text-left" htmlFor="user_password">{ I18n.t('createaccount.password', {locale: this.props.initialData.locale}) }</label>
                <input className="full-width" type="password" name="user[password]" placeholder={ I18n.t('createaccount.your_password', {locale: this.props.initialData.locale}) } id="user_password" autoComplete="off"/>

                <div className="tc-checkbox-container">
                  <input type="checkbox" ref={ accept => this.accept = accept } value="1" name="user[accepted]" id="user_accepted" onChange={this.handleChange} />
                  <label htmlFor="user_accepted" className="white-bg-link"  dangerouslySetInnerHTML={{__html: I18n.t("loginpage.accept_terms_html", { locale: this.props.initialData.locale,
                                                              terms: I18n.t("loginpage.terms_href", {locale: this.props.initialData.locale}), link: termsUrl }) }}>
                  </label>
                </div>

                <input type="hidden" name="user[language]" value={ this.props.initialData.locale } />
                <input type="hidden" name={ this.props.initialData.userForm.csrf_param } value={ this.props.initialData.userForm.csrf_token } />
                <input type="hidden" name="utf8" value="✓" />
                <input name="locale" type="hidden" value={ this.props.initialData.locale } />
                <input type="hidden" name="random_number" value={ this.state.randomNumber } />
                <div className="center">
                  <button type="submit" name="commit" className="big-submit-button margin-top-10 white display-inline bebas">{ I18n.t('loginpage.create_account', {locale: this.props.initialData.locale}) }</button>
                </div>
              </form>

              {/* <div className="center">
                <div className="margin-top-30 display-inline font-size-11 bebas">{I18n.t('createaccount.already',{locale: this.props.initialData.locale})} </div>
                <a className="white-bg-link font-size-11 bebas" href={ loginPath }> {I18n.t('loginpage.log_in_here',{locale: this.props.initialData.locale})}</a>
              </div> */}
            </div>

            <div className="container margin-auto max-width-form center" style={{ margin: '4rem auto' }}>
							<fieldset className="hr-line"><legend className="dark-blue">{ I18n.t('createaccount.or', {locale: this.props.initialData.locale}) }</legend></fieldset>
							<br />

							<FacebookLogin locale={ this.props.initialData.locale } register={ true } />
						</div>
          </div>
        </div>
      </div>
    );
  }
}
