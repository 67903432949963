'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import BaseComponent from '../../libs/components/BaseComponent';

export default class DateItem extends BaseComponent {
	constructor(props) {
    super(props);
    this.state = {
			followed: props.date.currentUserIsFollowing,
			followersCount: props.date.followersCount > 1 ? props.date.followersCount : false
    };
  }

	componentDidMount = () => {
		this.props.instance.restore(this);

		if( this.state.followed ) {
	  	this.checkbox.checked = true;
	  }
	};

	componentWillUnmount = () => {
	  var state = this.state
	  this.props.instance.save(function(ctx){
	  	ctx.setState(state)
	  })
	};

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if( !nextProps.justSavedDates ) {
			this.setState({ followed: nextProps.date.currentUserIsFollowing, followersCount: nextProps.date.followersCount });
			this.checkbox.checked = this.state.followed;
		}

		if( nextProps.conversationsCounter == this.props.conversationsCounter-1 ) {
			this.setState({ followersCount: this.state.followersCount-1 });
		}
	};

	changeChecked = () => {
		if( this.props.checkable ) {
			var newCheckedState = this.checkbox.checked ? false : true;
			var newFollowersCountState = newCheckedState ? this.state.followersCount + 1 : this.state.followersCount - 1;
			this.checkbox.checked = newCheckedState;
			this.setState({ followed: newCheckedState, followersCount: newFollowersCountState });
		}
	};

	handleChange = () => {
		if( this.props.checkable ) {
			var newCheckedState = this.checkbox.checked;
			var newFollowersCountState = newCheckedState ? this.state.followersCount + 1 : this.state.followersCount - 1;
			this.checkbox.checked = newCheckedState;
			this.setState({ followed: newCheckedState, followersCount: newFollowersCountState });
		}
	};

	render() {
		var checkboxName = "micropost[dates][]";
		var followersCount = this.state.followersCount > 1 ? this.state.followersCount : "";
	var dayAndCount = <span className="day-in-words">{ this.props.date.dayInWords }<sup className="blue bebas bold">{ followersCount }</sup></span>;
    var gray = this.state.followed ? "" : "gray";
		var value = this.props.date.appointment_id ? this.props.date.appointment_id : this.props.date.utcDate;

		return (
			<li ref={ dateItem => this.dateItem = dateItem } className={"date center display-inline " + gray} >
				<input type="checkbox" ref={ checkbox => this.checkbox = checkbox } className="animated fadeInUp" onChange={ this.handleChange } key={ this.props.date.utcDate } name={ checkboxName } defaultChecked={ this.props.date.currentUserIsFollowing } value={ value } />
				<label className="label-smaller" onClick={ this.changeChecked } >
					{ dayAndCount }
					<span className="day-date">{ this.props.date.dayInNumbers }</span>
				</label>
			</li>
		);
	}
}
