var getLocaleString = function( locale ) {
  var localeString = '';

  if (typeof locale !== 'undefined') {
    localeString = locale === 'de' ? '' : '/' + locale;
  }

  return localeString;
};

export default getLocaleString;
