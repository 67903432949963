'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import BaseComponent from '../../libs/components/BaseComponent';

export default class OptionalLink extends BaseComponent {
  constructor() {
    super();

    this.state = {
      // displayLink: true,
    };
  };

  componentDidMount = () => {
    /* const iOS = /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());
    const standalone = "standalone" in window.navigator && window.navigator.standalone;

    if (iOS && !standalone) {
      this.setState({ display: true });
    } */
  };

  render() {
    // console.log('debug refs!!!!', this.props);
    let { children, currentUser, className, ...rest } = this.props;
    className = className + ' link-hover';
    // console.log('debug ad!!!!', this.props.ad);
    const aElement = <a { ...rest } className={ className }>{ children }</a>
    const spanElement = <span onClick={ rest.onClick } className={ className }>{ children }</span>;

    var optionalLink = currentUser.guest === false ? aElement : spanElement;

    return optionalLink;
  }
}
