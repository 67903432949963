'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import getLocaleString from '../../helper/GetLocaleString';
import BadgeList from './BadgeList';
import MicropostFeed from '../Microposts/MicropostFeed';
import UserProfileImage from '../Elements/UserProfileImage';
import UserImagesThumbnails from './UserImagesThumbnails';
import BaseComponent from '../../libs/components/BaseComponent';
import getFirstName from '../../helper/GetFirstName';
import levelInWords from '../../helper/LevelInWords';
import renderMapIcon from '../svgIcons/i/map';
import renderTimeIcon from '../svgIcons/i/time';
import renderEnvelopeIcon from '../svgIcons/envelope';
import renderPowerOffIcon from '../svgIcons/powerOff';
import renderCogIcon from '../svgIcons/cog';
import renderPencilIcon from '../svgIcons/pencil';
import renderBuddyAvatar from '../svgIcons/i/profile';
import Modal from '../Elements/Modal';
import MBP from '../../helper/MobileBoilerplate';
// import SmallHeaderDisplayAd from '../Elements/Ads/SmallHeaderDisplayAd';
import $ from 'jquery';

export default class UsersShowView extends BaseComponent {
  constructor() {
    super();
    this.state = {
      sending: false,
			isConversationModalOpen: false,
    };
  }

  toggleHiddenBadges = (e) => {
    e.preventDefault();

    $(".not-achieved").toggleClass("hidden");
    $( this.showMoreBadges ).toggleClass("hidden");
    $( this.showLessBadges ).toggleClass("hidden");
  };

  componentDidMount = () => {

  };

  openConversationModal = (e) => {
    e.preventDefault();
    this.setState({ isConversationModalOpen: true });

    setTimeout(() => {
      var messageField = this.message ? this.message : false;

      if( messageField ) {
        new MBP.autogrow(messageField, 16); // 16 -- line height
        var customEvent = new CustomEvent("input", { bubbles: true });
        messageField.dispatchEvent( customEvent );
      }
    }, 300);
  };

  closeConversationModal = () => {
    this.setState({ isConversationModalOpen: false });
  };

  createConversation = (e,node) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.state.sending === true) return;
    this.setState({ sending: true });

    if( !this.props.initialData.currentUser.guest ) {
      var that = this;
      var form = this.messageForm;
      var body = this.message;
      var data = new FormData( form );
      var bufferContent = body.value;

      if( body.value.length > 0 ) {
        $.ajax({
          url: form.getAttribute('action'),
          type: 'POST',
          data: data,
          processData: false,
          contentType: false
        })
        .done(function(data) {
          // console.log(data);
          that.setState({ sending: false });
          that.closeConversationModal();
          if (data.body.indexOf('[email address removed]') !== -1 || data.body.indexOf('[phone number removed]') !== -1) {
            that.closeConversationModal();
            var customEvent = new CustomEvent("triggerPremiumModal", { detail: {highlight: 'contact_infos'}, bubbles: false });
            document.dispatchEvent( customEvent );

            setTimeout(function() {
              window.location.reload(false);
            }, 7000);
          } else {
            window.location.reload(false);
          }
        })
        .fail(function( xhr, status, err ) {
          console.log(xhr);

          setTimeout(function() {
            that.setState({ sending: false });
            body.value = bufferContent;
          }, 300);

          if (xhr.responseJSON.errors === 'premium_feature_unlimited') {
            var customEvent = new CustomEvent("triggerPremiumModal", { detail: {highlight: 'unlimited'}, bubbles: false });
            document.dispatchEvent( customEvent );
          }
        });
      }
      else {
        $( this.error_messages ).text(I18n.t('conversations.empty_message', {locale: this.props.initialData.locale}));
        this.setState({ sending: false });
      }
    }
    else {
      this.setState({ showConversationModal: true, sending: false });
    }
  };

	render() {
    // console.log('debug user image', this.props.initialData.profileUser.user_infos);
    var fakeMicropost = { user_infos: this.props.initialData.currentUser.user_infos };
    var userHeaderBar = "";
    var userAvatar = <UserProfileImage micropost={ fakeMicropost } />;
    var firstName = getFirstName( this.props.initialData.profileUser.name );
    var editUrl = getLocaleString(this.props.initialData.locale) + "/users/" + this.props.initialData.profileUser.id + "/edit";
    var sendText = this.state.sending ? "Loading..." : I18n.t('conversations.send', {locale: this.props.initialData.locale});
    var userLocation = "";
    var userLastSeen = "";
    var preferencesUrl = getLocaleString(this.props.initialData.locale) + "/users/" + "preferences/" + this.props.initialData.profileUser.id;
    var fakeMicropost = { user_infos: this.props.initialData.profileUser.user_infos };
    var userUrl = getLocaleString(this.props.initialData.locale) + "/users/" + this.props.initialData.profileUser.id;
    var editUrl = getLocaleString(this.props.initialData.locale) + "/users/" + this.props.initialData.profileUser.id + "/edit";
    var editTab = "";
    var headerBarLeftText = "";
    var userProfileNotice = "";
    var userBadges = this.props.initialData.badges;
    var messageLimitNotice = "";
    var unlockUnlimitedMessages = "";
    var now = new Date();
    now = new Date(now.getTime() + (now.getTimezoneOffset() * 60000));
    var premiumDate = new Date(this.props.initialData.profileUser.user_infos.premium);
    var ownPremiumDate = new Date(this.props.initialData.currentUser.user_infos.premium);
    var premiumStyle = {};
    var premiumBadge = "";
    var verifiedBadge = "";
    var directMessage = "";
    var placeholder = I18n.t('users.message_placeholder', {locale: this.props.initialData.locale, user: getFirstName(this.props.initialData.profileUser.user_infos.name)});

    if( this.props.initialData.privateConversation.length > 0 && this.props.initialData.currentUser.id != this.props.initialData.profileUser.id ) {
      var privateConversationUrl = getLocaleString(this.props.initialData.locale) + "/private_conversations/" + this.props.initialData.privateConversation[0].id;
      directMessage = (
        <div className="bebas message-button"><a href={ privateConversationUrl }><span className="">{ /*renderEnvelopeIcon()*/ }</span>{ I18n.t('users.message', {locale: this.props.initialData.locale}) }</a></div>
      );
    }
    else if ( this.props.initialData.currentUser.id != this.props.initialData.profileUser.id ) {
      directMessage = (
        <div className="bebas message-button"><a href="javascript: false" onClick={ this.openConversationModal }><span className="">{ /*renderEnvelopeIcon()*/ }</span>{ I18n.t('users.message', {locale: this.props.initialData.locale}) }</a></div>
      );
    }
    else if ( this.props.initialData.currentUser.id === this.props.initialData.profileUser.id ) {
      directMessage = (
        <div className="bebas message-button">
          <a className="init-loading" style={{}} href={userUrl + "/visitors"}>{ I18n.t('users.show.visitors', {locale: this.props.initialData.locale}) }</a>
        </div>
      );
    }

    if (premiumDate >= now) {
      premiumStyle = {
          color: '#E8D176'
      };

      premiumBadge = (
        <a href="/premium" className="premium-badge dark-blue">Premium</a>
      );

      verifiedBadge = (
        <a href="/premium" className="verified-badge dark-blue">{ I18n.t('users.verified', {locale: this.props.initialData.locale}) }</a>
      );
    } else if (this.props.initialData.profileUser.user_infos.premium) {
      verifiedBadge = (
        <a href="/premium" className="verified-badge dark-blue">{ I18n.t('users.verified', {locale: this.props.initialData.locale}) }</a>
      );
    }

    if (ownPremiumDate < now) {
      messageLimitNotice = I18n.t('conversations.message_limit_notice', {
        locale: this.props.initialData.locale, 
        week_count: this.props.initialData.currentUser.user_infos.weekly_message_count,
        week_limit: this.props.initialData.currentUser.user_infos.weekly_message_limit,
        month_count: this.props.initialData.currentUser.user_infos.monthly_message_count,
        month_limit: this.props.initialData.currentUser.user_infos.monthly_message_limit,
      });

      unlockUnlimitedMessages = <a style={{ fontWeight: 700 }} href="/premium">{ I18n.t('conversations.unlock_unlimited_messages', {locale: this.props.initialData.locale}) }</a>;
    }

    var contactRequestForm = (
        <div className="column full margin-top-25" style={{ padding: "1.2em 1em 0 1em" }}>
          <span ref={ error_messages => this.error_messages = error_messages } className="red bebas"></span>
          <form ref={ messageForm => this.messageForm = messageForm } className="new_message" action={ this.props.initialData.contactRequestForm.action } acceptCharset="UTF-8" method="post">
            <div className="">
              <div>
                <div className="">
                  <input className="full-width last-name-signup-input" type="text" tabIndex="-1" name="conversation[title]" placeholder={ I18n.t('createaccount.your_name', {locale: this.props.initialData.locale}) } value="" id="user_title" autoComplete="off"/>
                </div>
                <div className="">
                  <div className="message-limit-notice">{ messageLimitNotice }</div>
                  <div className="">{ unlockUnlimitedMessages }</div>
                  <textarea ref={ message => this.message = message } style={{ border: "1px solid #1e3646", borderRadius: '3px', fontSize: "1rem", lineHeight: "1.4rem" }} placeholder={ placeholder } className="message-body" name="message[body]" id="message_body"></textarea>
                </div>
              </div>
            </div>
            <div className="bottom bebas font-size-11">
              <a className="white no-underline bold" href="#">
                <button type="submit" ref={ sendMessage => this.sendMessage = sendMessage } disabled={ this.state.sending } style={{width: "100%", textAlign: "center", border: "none", fontSize: "1.3rem", padding: "0.7rem 0", backgroundColor: "white"}} className="display-inline bebas" onClick={ this.createConversation } >
                  { sendText }
                </button>
              </a>
            </div>

            <input name="locale" type="hidden" value={ this.props.initialData.locale } />
            <input type="hidden" name={ this.props.initialData.contactRequestForm.csrf_param } value={ this.props.initialData.contactRequestForm.csrf_token } />
            <input value={ this.props.initialData.currentUser.id } type="hidden" name="conversation[sender_id]" />
            <input value={ this.props.initialData.profileUser.id } type="hidden" name="conversation[recipient_id]" />
          </form>
        </div>
    );
    var micropostFeed = (
      <div className="column full container center">
        <div className="max-width-1260 margin-top-30">
          <h4 className="bebas">{ I18n.t('users.no_posts', {locale: this.props.initialData.locale}) }</h4>
        </div>
      </div>
    );
    var leaderboardPage = this.props.initialData.ranking % 10 == 0 ? Math.floor(this.props.initialData.ranking / 10) : Math.floor(this.props.initialData.ranking / 10) + 1;

    if( this.props.initialData.profileUser.location ) {
      userLocation = (
        <div className="profile-detail-icons bebas">
          <div className="gray" style={{fontSize: "0.9rem", marginBottom: "0.4rem"}}>{ I18n.t('users.show.location', {locale: this.props.initialData.locale}) }</div>
          <div>{ renderMapIcon() }</div>
          { this.props.initialData.profileUser.location !== 'null' ? this.props.initialData.profileUser.location : '-' }
        </div>
      );
    }

    if( this.props.last_seen_at_in_words ) {
      userLastSeen = (
        <div className="profile-detail-icons bebas">
          <div className="gray" style={{fontSize: "0.9rem", marginBottom: "0.4rem"}}>{ I18n.t('users.show.last_seen', {locale: this.props.initialData.locale}) }</div>
          <div>{ renderTimeIcon() }</div><span>{ this.props.last_seen_at_in_words }</span>
        </div>
      );
    }

    // If microposts are available, set heading
    if( this.props.initialData.microposts.length > 0 ) {
      var micropostsHeading = (
        <h1 className="bebas blue center margin-top-10">
          { I18n.t('users.show.interests', {locale: this.props.initialData.locale}) } { firstName }
        </h1>
      );

      micropostFeed = <MicropostFeed microposts={ this.props.initialData.microposts } micropostForm={ this.props.initialData.micropostForm }
                                     latitude={ this.props.initialData.lat } longitude={ this.props.initialData.lon } currentUrl={ this.props.initialData.currentUrl }
                                     location={ this.props.initialData.location } lastPage={ this.props.initialData.lastPage }
                                     locale={ this.props.initialData.locale } currentUser={ this.props.initialData.currentUser } loginForm={ this.props.initialData.loginForm }
                                     hideSearch={ true } hideToggler={ true } userForm={ this.props.initialData.userForm } loginPath={ this.props.initialData.loginPath }
                                     contactRequestForm={ this.props.initialData.contactRequestForm } screenWidth={ this.props.screenWidth } />
    }
    // No microposts, no heading
    else {
      var micropostsHeading = "";
    }

    // Handle users about text for different users
    // No about text and current user profile belongs to current user
    if( !this.props.initialData.profileUser.about && this.props.initialData.currentUser.id == this.props.initialData.profileUser.id ) {
      var userAbout = <div className="about-text no-margin-or-padding" dangerouslySetInnerHTML={{__html: I18n.t("users.edit.no_about", { locale: this.props.initialData.locale, link: this.props.initialData.editPath }) }}></div>;
    }
    else if( !this.props.initialData.profileUser.about && this.props.initialData.currentUser.id != this.props.initialData.profileUser.id ) {
      var userAbout = (
        <div className="about-text no-margin-or-padding" dangerouslySetInnerHTML={{__html: I18n.t("users.edit.no_infos", { locale: this.props.initialData.locale }) }}></div>
      );
    }
    else {
      var userAbout = (
        <blockquote>
          <p className="about-text no-margin-or-padding">{ this.props.initialData.profileUser.about !== 'null' ? this.props.initialData.profileUser.about : '-' }</p>
        </blockquote>
      );
    }

    if(
      this.props.initialData.currentUser.id == this.props.initialData.profileUser.id &&
      (!this.props.initialData.profileUser.about ||
       !this.props.initialData.profileUser.gender ||
       !this.props.initialData.profileUser.age ||
       !this.props.initialData.profileUser.location ||
       !this.props.initialData.profileUser.user_infos.avatar)
    ) {
      userProfileNotice = (
        <div style={{backgroundColor: 'rgba(245, 99, 99, 0.3)', marginBottom: '1rem', padding: '0.5rem', border: '1px solid #f56363'}}>
          { I18n.t('users.show.fill', {locale: this.props.initialData.locale}) } <a href={editUrl} className="init-loading">{ I18n.t('users.show.edit', {locale: this.props.initialData.locale}) }</a>
        </div>
      );
    }

    if( this.props.initialData.currentUser.id == this.props.initialData.profileUser.id ) {
      headerBarLeftText = I18n.t('menu.profile', {locale: this.props.initialData.locale});
      editTab = (
        <li className="tabs-menu-item bebas"><a href={ editUrl } className="init-loading" data-tab-id="3">{ I18n.t('users.edit_profile', {locale: this.props.initialData.locale}) }</a></li>
      );

      micropostFeed = <MicropostFeed microposts={ this.props.initialData.microposts } micropostForm={ this.props.initialData.micropostForm }
                                     latitude={ this.props.initialData.lat } longitude={ this.props.initialData.lon } currentUrl={ this.props.initialData.currentUrl }
                                     location={ this.props.initialData.location } lastPage={ this.props.initialData.lastPage }
                                     locale={ this.props.initialData.locale } currentUser={ this.props.initialData.currentUser } loginForm={ this.props.initialData.loginForm }
                                     hideSearch={ true } hideToggler={ true } userForm={ this.props.initialData.userForm } loginPath={ this.props.initialData.loginPath }
                                     contactRequestForm={ this.props.initialData.contactRequestForm } screenWidth={ this.props.screenWidth } />

      if( !this.props.initialData.profileUser.location ) {
        userLocation = (
          <div className="profile-detail-icons">
            <div>{ renderMapIcon() }</div>
            <a href={ editUrl } className="init-loading">{ I18n.t('users.edit.add_city', {locale: this.props.initialData.locale}) }</a>
          </div>
        );
      }

      userHeaderBar = (
        <div className="row">
          <div className="column full container dark-blue-bg bold header-bar" style={{padding: "0 1em"}}>
            <div className="max-width-1260">
              <h1 className="left white bebas font-size-12 header-bar-title display-inline"><a href={ userUrl } className="white-link init-loading">{ headerBarLeftText }</a></h1>
                <a className="headerBarButtons" title={ I18n.t('users.show.signout_link_title', {locale: this.props.initialData.locale}) } id="sign_out" rel="nofollow" data-method="delete" href={getLocaleString(this.props.initialData.locale) + "/signout"}>
                  <div className="red-bg signout-button post-infos right white display-inline">
                    { renderPowerOffIcon() }
                  </div>
                </a>

                <a className="headerBarButtons" title={ I18n.t('users.show.settings_link_title', {locale: this.props.initialData.locale}) } href={ preferencesUrl }>
                  <div className="signout-button post-infos right white display-inline" style={{borderRight: "none"}}>
                    { renderCogIcon() }
                  </div>
                </a>

                <a className="headerBarButtons init-loading" title={ I18n.t('users.show.edit_link_title', {locale: this.props.initialData.locale}) } href={ editUrl }>
                  <div className="signout-button post-infos right white display-inline" style={{borderRight: "none"}}>
                    { renderPencilIcon() }
                  </div>
                </a>
            </div>
          </div>
        </div>
      );
    }

    var userProfileDeactivated = "";

    if( this.props.initialData.blocks > 3 ) {
      userProfileDeactivated = (
        <div style={{backgroundColor: 'rgba(245, 99, 99, 0.3)', marginBottom: '1rem', padding: '0.5rem', border: '1px solid #f56363'}}>
        { I18n.t('users.userProfileDeactivated', {locale: this.props.initialData.locale}) }
        </div>
      );
    }

    return (
      <div>
        <Modal isOpen={this.state.isConversationModalOpen} closeFunction={ this.closeConversationModal }>
          <div className="container">
            <h2 className="bebas margin-top-10 text-left">{ I18n.t('users.message_popup_heading', {locale: this.props.initialData.locale, user: getFirstName(this.props.initialData.profileUser.user_infos.name)}) }</h2>
          </div>

          { contactRequestForm }
        </Modal>

        { userHeaderBar }

        {/*<div style={{ maxWidth: "45rem", margin: "0 auto"}}>
          <div className="row">
            <div className="column container full">
              <SmallHeaderDisplayAd />
            </div>
          </div>
        </div>*/}

        <div className="row">
          <div className="column full container user-profile-infos">
            <div className="row">
              <div className="max-width-1260">
                <div className="column third no-margin-or-padding side-bar force-profile-bg">
                  <h1 className="bebas no-margin font-size-20 display-inline ellipsis-heading">{ this.props.initialData.profileUser.name } { premiumBadge }{ verifiedBadge }</h1>

                  <div className="column full no-margin-or-padding" style={ premiumStyle }>
                    <div className="left" style={{marginRight: "0.5rem", paddingTop: "1rem"}}>
                      <UserProfileImage modalEnabled={ true } locale={ this.props.initialData.locale } currentUser={ this.props.initialData.currentUser } micropost={fakeMicropost} width={70} height={70} />
                    </div>
                    <div className="left user-stats">
                      <div className="margin-bottom-10" style={{fontSize: "1.1rem"}}>
                        <div className="bebas gray center column left third-small no-margin-or-padding" title={ I18n.t('users.posts_explain', {locale: this.props.initialData.locale}) }>
                          <div className="dark-blue">{ this.props.initialData.profileUser.post_count }</div>
                          <div className="stats-text">{ I18n.t('users.posts', {locale: this.props.initialData.locale}) }</div>
                        </div>
                        <div className="bebas gray center column left third-small no-margin-or-padding" title={ I18n.t('users.contacts_explain', {locale: this.props.initialData.locale}) }>
                           <div className="dark-blue">{ this.props.initialData.profileUser.cr_count }</div>
                           <div className="stats-text">{ I18n.t('users.contacts', {locale: this.props.initialData.locale}) }</div>
                        </div>
                        <div className="bebas gray center column left third-small no-margin-or-padding" title={ I18n.t('users.firsts_explain', {locale: this.props.initialData.locale}) }>
                          <div className="dark-blue">{ this.props.initialData.profileUser.first_joined }</div>
                          <div className="stats-text">{ I18n.t('users.firsts', {locale: this.props.initialData.locale}) }</div>
                        </div>

                        <div className="clearfix"></div>

                        { directMessage }
                      </div>
                    </div>
                    <div className="clearfix"></div>

                    <h2 className="bebas center">
                      {/*<span className="star left-side">&#x2605;</span> { this.props.initialData.profileUser.score } <span className="star right-side">&#x2605;</span>*/}
                    </h2>
                    {/*<h3 className="bebas margin-bottom-0">{ levelInWords(this.props.initialData.profileUser.level, this.props.initialData.locale) }</h3>*/}
                    {/*<a href={getLocaleString(this.props.initialData.locale) + "/leaderboard" + "?page=" + leaderboardPage} className="bebas margin-bottom-10 dark-blue">
                      { I18n.t('users.rank', {locale: this.props.initialData.locale}) } { this.props.initialData.ranking }
                    </a>*/}
                  </div>



                  <div className="column no-margin-or-padding full center" style={{ margin: '0.5rem 0' }}>
                    <UserImagesThumbnails locale={ this.props.initialData.locale } profileUser={ this.props.initialData.profileUser } currentUser={ this.props.initialData.currentUser } micropost={fakeMicropost} locale={ this.props.initialData.locale } urls={ this.props.initialData.images.image_urls } />
                  </div>

                  <div className="clearfix"></div>
                </div>



                <div className="column two-thirds">
                  { userProfileDeactivated }
                  { userProfileNotice }<br />

                  { userAbout }<br />

                  <hr style={{ margin: "0.5rem auto" }} />

                  <div className="row">
                    <div className="column third-small left user-detail">{ userLocation }</div>
                    <div className="column third-small left user-detail bebas"><div className="profile-detail-icons"><div className="gray" style={{fontSize: "0.9rem", marginBottom: "0.4rem"}}>{ I18n.t('users.show.member_for', {locale: this.props.initialData.locale}) }</div>{ renderBuddyAvatar() }</div>{ this.props.created_at_in_words }</div>
                    <div className="column third-small left user-detail">{ userLastSeen }</div>
                    <div className="clearfix"></div>
                  </div>

                  <hr style={{ margin: "0.5rem auto" }} />

                  <h2 className="bebas margin-top-10 display-inline">{ I18n.t('users.badges', {locale: this.props.initialData.locale}) } {this.props.initialData.badges.length}/23</h2>
                  <a href="javascript: false" ref={ showMoreBadges => this.showMoreBadges = showMoreBadges } onClick={ this.toggleHiddenBadges } className="bebas" style={{marginLeft: "0.3em",overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "0.7em"}}>{ I18n.t('users.show_more_badges', {locale: this.props.initialData.locale}) }</a>
                  <a href="javascript: false" ref={ showLessBadges => this.showLessBadges = showLessBadges } onClick={ this.toggleHiddenBadges } className="bebas hidden" style={{marginLeft: "0.3em",overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "0.7em"}}>{ I18n.t('users.show_less_badges', {locale: this.props.initialData.locale}) }</a>
                  <BadgeList userBadges={ userBadges } user={ this.props.initialData.profileUser } locale={ this.props.initialData.locale } />
                </div>
              </div>
            </div>
          </div>

          <div className="clearfix"></div>

          { micropostsHeading }
          <div className="max-width-1260">
            { micropostFeed }
          </div>
        </div>
      </div>
    );
  }
}
