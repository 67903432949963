'use strict';
import PropTypes from 'prop-types';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import getLocaleString from '../../helper/GetLocaleString';
import renderInfoIcon from '../svgIcons/info';
import BaseComponent from '../../libs/components/BaseComponent';
import I18n from 'i18n-js';

if( process.env.NODE_ENV === 'production' ) {
  var FACEBOOK_APP_ID = '810394835723832';
}
else {
  var FACEBOOK_APP_ID = '911163392239059';
}

export default class FacebookLogin extends BaseComponent {
  componentDidMount = () => {
    window.fbAsyncInit = function() {
      FB.init({
        appId: FACEBOOK_APP_ID,
        version: 'v9.0',
        status: true,
        cookie: true,
        xfbml: true
      });
    }.bind(this);

    // Load the SDK asynchronously
    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "//connect.facebook.net/de_DE/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  };

  // Here we run a very simple test of the Graph API after login is
  // successful.  See statusChangeCallback() for when this call is made.
  testAPI = () => {
    console.log('Welcome!  Fetching your information.... ');
    FB.api('/me', function(response) {
    console.log('Successful login for: ' + response.name);
    document.getElementById('status').innerHTML =
      'Thanks for logging in, ' + response.name + '!';
    });
  };

  // This is called with the results from from FB.getLoginStatus().
  statusChangeCallback = (response) => {
    console.log('statusChangeCallback');
    console.log(response);
    // The response object is returned with a status field that lets the
    // app know the current login status of the person.
    // Full docs on the response object can be found in the documentation
    // for FB.getLoginStatus().
    if (response.status === 'connected') {
      // Logged into your app and Facebook.
      this.testAPI();
    } else if (response.status === 'not_authorized') {
      // The person is logged into Facebook, but not your app.
      document.getElementById('status').innerHTML = 'Please log ' +
        'into this app.';
    } else {
      // The person is not logged into Facebook, so we're not sure if
      // they are logged into this app or not.
      document.getElementById('status').innerHTML = 'Please log ' +
      'into Facebook.';
    }
  };

  signIn = (e) => {
    e.preventDefault();
    var that = this;

    if( typeof FB !== 'undefined' ) {
      if ("standalone" in navigator && navigator.standalone) {
        var permissionUrl = "https://m.facebook.com/dialog/oauth?client_id=" + FACEBOOK_APP_ID + "&response_type=code&redirect_uri=" + window.location.origin + "?" + "scope=email";
        window.location = permissionUrl;
      }
      else {
        FB.login(function(response) {
          if (response.authResponse) {
            var domain = process.env.NODE_ENV === 'production' ? 'https://buddyme.me' : 'http://localhost:3000';
            return window.location = domain + '/auth/facebook/callback';
          }
          else {
            console.log('User canceled login or did not fully authorize.');
          }
        }, {
              scope: 'email',
              return_scopes: true,
              auth_type: 'rerequest'
            }
        );
      }
    }
    else {
      alert(I18n.t('createaccount.fb_error', {locale: this.props.locale}));
    }
  };

  render() {
    if( this.props.register) {
      var buttonText = I18n.t('createaccount.facebook', {locale: this.props.locale});
    }
    else {
      var buttonText = I18n.t('loginpage.facebook', {locale: this.props.locale});
    }

    var divStyle = {
      position: 'relative',
      top: '-4px'
    };

    return (
      <span>
        <a href="javascript: false" className="position-it fblink display-inline bebas" onClick={ this.signIn } >
          <div className="fb-icon-container display-inline">
            <svg version="1.1" fill="#31497b" id="Layer_1"  x="0px" y="0px" width="18px" height="18px" viewBox="0 0 512 512">
              <path d="M288,192v-38.1c0-17.2,3.8-25.9,30.5-25.9H352V64h-55.9c-68.5,0-91.1,31.4-91.1,85.3V192h-45v64h45v192h83V256h56.4l7.6-64H288z"/>
            </svg>
          </div>
          <div className="display-inline" style={divStyle}>
            { buttonText }
          </div>
        </a>
        {/* <div className="fb-info" style={{marginTop: "10px"}}>{ renderInfoIcon() } <span style={{ top: '-2px', position: 'relative' }}>{ I18n.t('loginpage.facebook_info', {locale: this.props.locale}) }</span></div> */}
      </span>
    )
  }
}
