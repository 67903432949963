'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import HeaderBar from '../Elements/HeaderBar';
import MicropostFeed from '../Microposts/MicropostFeed';
import BaseComponent from '../../libs/components/BaseComponent';
import I18n from 'i18n-js';

export default class HashtagsShowView extends BaseComponent {
  constructor() {
    super();
  }

	render() {
    var header = "";

    if (this.props.hideHeader !== true) {
      header = (
        <span>
          <HeaderBar latitude={ this.props.initialData.lat } longitude={ this.props.initialData.lon }
                     location={ this.props.initialData.location } locale={ this.props.initialData.locale }
                     hashtag={ this.props.initialData.hashtag }
                     micropostForm={ this.props.initialData.micropostForm }
                     loginForm={ this.props.initialData.loginForm } userForm={ this.props.initialData.userForm }
                     loginPath={ this.props.initialData.loginPath } currentUser={ this.props.initialData.currentUser } />
        </span>
      );
    }

    return (
    	<div className="row">
        { header }

        <MicropostFeed microposts={ this.props.initialData.microposts } micropostForm={ this.props.initialData.micropostForm } currentUrl={ this.props.initialData.currentUrl }
                       latitude={ this.props.initialData.lat } longitude={ this.props.initialData.lon } hashtag={ this.props.initialData.hashtag }
                       location={ this.props.initialData.location } lastPage={ this.props.initialData.lastPage } filters={ true }
                       locale={ this.props.initialData.locale } currentUser={ this.props.initialData.currentUser } loginForm={ this.props.initialData.loginForm }
                       userForm={ this.props.initialData.userForm } loginPath={ this.props.initialData.loginPath }
                       contactRequestForm={ this.props.initialData.contactRequestForm } screenWidth={ this.props.screenWidth } />
    	</div>
    );
  }
}
