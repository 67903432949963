'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import BaseComponent from '../../../libs/components/BaseComponent';

export default class FriendlyAd extends BaseComponent {
  constructor() {
    super();

    this.state = {
      display: true,
    };
  };

  componentDidMount = () => {
    /* const iOS = /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());
    const standalone = "standalone" in window.navigator && window.navigator.standalone;

    if (iOS && !standalone) {
      this.setState({ display: true });
    } */
  };

  render() {
    // console.log('debug ad!!!!', this.props.ad);
    var html = (
      <article className="feed-item">
        <div className="inner">
          <div className="body friendly-ad">
            <div className="gray profile-detail-icons location" style={{ float: 'right' }}>
              <a href="#" className="friendly-ad-badge dark-blue" style={{ top: '0px' }}>friendly ad</a>
            </div>

            <div className="clearfix"></div>

            <a href={ this.props.ad.link } target="_blank" rel="nofollow">
              <div className="product-card-image">
                <img src={ this.props.ad.image_url } />
              </div>

              <div className="dark-blue" style={{ marginTop: '1.5rem', fontSize: '1.1rem' }}>
                { this.props.ad.title }
              </div>

              <div className="" style={{ fontSize: '0.9rem', color: '#a3a3a3' }}>
                { this.props.ad.description }
              </div>
            </a>
          </div>

          <div class="buy-button-container">
            <span className="seller-tag">Auf { this.props.ad.seller }</span>
            { this.props.ad.price &&
              <a href={ this.props.ad.link } target="_blank" rel="nofollow" className="card-buy-button">
                <span class="price">{ this.props.ad.price >= 0 ? `${this.props.ad.price} €` : this.props.ad.call_to_action_text } </span>
              </a>
            }
          </div>
        </div>
      </article>
    );
    var ad = this.state.display === true ? html : "";

    return ad;
  }
}
