import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';

var renderBack = function() {
	return(
		<svg xmlns="http://www.w3.org/2000/svg" width="12" height="18" viewBox="0 0 12 18">
		  <path fillRule="evenodd" stroke="#1e3646" d="M1.6892495,7.99993979 L9.7637036,1.17788845 C10.0430439,0.94188667 10.0800251,0.521216837 9.84625098,0.238548043 C9.61181643,-0.0434540796 9.19577765,-0.0807876939 8.91577696,0.155214082 L0.236415684,7.48860261 C0.0865096497,7.61527023 0,7.80260497 0,7.99993979 C0,8.19727461 0.0865096497,8.38460935 0.236415684,8.51127697 L8.91577696,15.8446655 C9.03926783,15.9486663 9.18983424,16 9.33974028,16 C9.52794829,16 9.71549593,15.9186661 9.84625098,15.7613315 C10.0800251,15.4786627 10.0430439,15.0579929 9.7637036,14.8219911 L1.6892495,7.99993979 Z" transform="translate(1 1)"/>
		</svg>
  )
}

export default renderBack;
