'use strict';
import PropTypes from 'prop-types';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import BaseComponent from '../../libs/components/BaseComponent';
import I18n from 'i18n-js';
import getLocaleString from '../../helper/GetLocaleString';


export default class FeedToggler extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
			exploreLink: getLocaleString(this.props.locale) + "/explore",
			usersLink: getLocaleString(this.props.locale) + "/users",
    };
  }

  componentDidMount = () => {
    this.handleLinks();
  };

  handleLinks = () => {
    if (this.props.isSearch === true && typeof window !== 'undefined') {
      var path = window.location.pathname + window.location.search;

      if (path.indexOf('?') !== -1) {
        this.setState({
          exploreLink: path.replace("/searchUsers?", "/search?"),
          usersLink: path.replace("/search?", "/searchUsers?"),
        });
      } else {
        this.setState({
          exploreLink: path.replace("/searchUsers", "/search"),
          usersLink: path.replace("/search", "/searchUsers"),
        });
      }
    }
  };

  buddiesOrSignup = (e) => {
    if( this.props.currentUser.guest ) {
      e.preventDefault();
      e.stopPropagation();
      var customEvent = new CustomEvent("openModal", { bubbles: true });
      this.buddiesLink.dispatchEvent( customEvent );
    }
  };

	render() {
    var stylingActivities = this.props.currentPage === "activities" ? "active" : "";
    var stylingBuddies = this.props.currentPage === "buddies" ? "active" : "";

    return (
      <div className="max-width-1260" style={{ margin: '0 auto 0.5rem auto' }}>
        <div className={"column half-small display-inline text-right " + stylingActivities}>
          <div className="toggle-feed bebas" style={{marginRight: "0.5rem"}}><a href={this.state.exploreLink}>{ I18n.t('users.toggler_activities', {locale: this.props.locale}) }</a></div>
        </div>
        <div className={"column half-small display-inline text-left " + stylingBuddies}>
          <div className="toggle-feed bebas" style={{marginLeft: "0.5rem"}}><a ref={ buddiesLink => this.buddiesLink = buddiesLink } href={this.state.usersLink} onClick={ this.buddiesOrSignup }>{ I18n.t('users.toggler_buddies', {locale: this.props.locale}) }</a></div>
        </div>

        <div className="clearfix"></div>
      </div>
    );
  }
}
