'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import BaseComponent from '../../libs/components/BaseComponent';
import $ from 'jquery';

export default class Modal extends BaseComponent {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if( nextProps.isOpen ) {
      $("body").addClass("modal-scroll-fix");
    }
    else {
      $("body").removeClass("modal-scroll-fix");
    }
  }

  render() {
    var closeClass = '';
    var modalStyle = {
      display: 'block',
    };

    if (this.props.premiumTeaser === true) {
      modalStyle = {
        display: 'block',
        zIndex: 1000,
      };
    }

    if (this.props.isCookieModal === true) {
      closeClass = ' hidden';
    }

    if(this.props.isOpen){
        return (
          <div>
            <div className="modal-overlay modal-is-opened" style={ modalStyle }></div>
            <div className="modal-wrapper modal-is-opened" style={ modalStyle }>
              <div className="modal modal-is-initialized modal-is-opened" style={{ padding: '2rem 0.5rem' }} data-modal-id="modal">
                <button className={"modal-close" + closeClass } onClick={ this.props.closeFunction }></button>
                { this.props.children }
              </div>
            </div>
          </div>
        );
    } else {
        return <div></div>;
    }
  }
}
