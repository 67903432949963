'use strict';
import PropTypes from 'prop-types';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import Conversation from './Conversation';
import BaseComponent from '../../libs/components/BaseComponent';

export default class ConversationList extends BaseComponent {
	render() {
    var _this = this;
    var conversationNodes = this.props.conversations.map(function(conversation) {
      var isCurrent = typeof _this.props.currentConversation !== 'undefined' ? _this.props.currentConversation.id == conversation.id : false;
      if( conversation.is_group == false ) {
        return (
          <Conversation isGroup={ false } micropostId={ _this.props.micropostId } locale={ _this.props.locale } isCurrent={ isCurrent } conversation={conversation} key={conversation.id} />
        );
      }
    });

		return (
      <span>
  			<ul className="" style={{position: 'relative'}}>
  				{ conversationNodes }
  			</ul>
      </span>
		);
	}
}
