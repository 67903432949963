'use strict';
import React, { Component, Fragment } from 'react';
import BaseComponent from '../../libs/components/BaseComponent';
import Dropzone from 'react-dropzone';
import I18n from 'i18n-js';
import $ from 'jquery';

export default class UserImagesUpload extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      files: props.files.image_objects || [],
      counter: 0,
      uploading: false,
    };
  }

  prepareUpload = (files) => {
    var that = this;
    files.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    }));

    this.setState({
      uploading: true,
    });

    that.doUpload(files);
  };

  doUpload = (files) => {
    var that = this;
    var file = files[this.state.counter];
    var data = new FormData();
    data.append('user[images]', file);

    $.ajax({
      url: `/users/images.js`,
      type: 'PUT',
      data: data,
      processData: false,
      contentType: false,
    })
    .done(function(data) {
      file.attachment_id = data.attachment_id;

      that.setState({
        files: that.state.files.concat(file),
        counter: that.state.counter + 1,
      }, () => {
        if (files.length >= that.state.counter + 1) {
          that.doUpload(files);
        } else {
          that.setState({
            counter: 0,
            uploading: false,
          });
        }
      })
    })
    .fail(function( xhr, status, err ) {
      that.setState({ counter: 0 });
      console.log(xhr);
      setTimeout(function() {
        that.setState({ sending: false });
      }, 300);
    });
  };

  handleRemove = (id, e) => {
    e.preventDefault();

    var that = this;
    var data = new FormData();
    var newState = this.state.files.filter((el) => el.attachment_id !== id);

    this.setState({ files: newState }, () => {
      $.ajax({
        url: `/users/images/${id}.js`,
        type: 'DELETE',
        data: data,
        processData: false,
        contentType: false
      })
      .done(function(data) {
        // that.removeFromUI(id);
      })
      .fail(function( xhr, status, err ) {
        console.log(xhr);
        setTimeout(function() {
          that.setState({ sending: false });
        }, 300);
      });
    });
  };

  removeFromUI = (id) => {
    // $(`div[data-attachment-id="${id}"]`).remove();
  };

  render() {
    var dropzoneText = (
      <div className="image-upload-zone" dangerouslySetInnerHTML={{__html: I18n.t('users.images_upload_desc', {locale: this.props.locale}) }}></div>
    );

    if (this.state.uploading) {
      dropzoneText = (
        <div className="image-upload-zone"><div className="lds-dual-ring"></div></div>
      );
    }

  	return (
      <div>
        <Dropzone onDrop={ this.prepareUpload } url="/users/images.js" accept="image/*" addRemoveLinks="true">
          {({getRootProps, getInputProps}) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                { dropzoneText }
              </div>
            </section>
          )}
        </Dropzone>

        {this.state.files.length > 0 &&
          <div>
            {this.state.files.map((file) => (
              <div key={'container-' + file.preview} className='user-images-preview-container' data-attachment-id={ file.attachment_id }>
                <div key={file.preview} style={{ background: `#fff url('${file.preview}') center no-repeat`, backgroundSize: 'cover', width: '100px', height: '100px' }}></div>
                <a key={'link-' + file.preview} href="javascript: false" onClick={ (e) => this.handleRemove(file.attachment_id, e) }>{ I18n.t('users.images_remove', {locale: this.props.locale}) }</a>
              </div>
            ))}
          </div>
        }
      </div>
    );
  }
}
