'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import getLocaleString from '../../helper/GetLocaleString';
import Modal from './Modal';
import GroupParticipantList from '../Conversations/GroupParticipantList';
import ConversationList from '../Conversations/ConversationList';
import Conversation from '../Conversations/Conversation';
import UserProfileImage from './UserProfileImage';
import BaseComponent from '../../libs/components/BaseComponent';
import renderUsersIcon from '../svgIcons/users';
import renderCaretDown from '../svgIcons/caretDown';
import renderPencilIcon from '../svgIcons/pencil';
import I18n from 'i18n-js';
import $ from 'jquery';

export default class HeaderBarConversations extends BaseComponent {
  constructor(props) {
    super(props);
    var match = props.conversationPath.match(/\d+/);

    this.state = {
      currentConversationId: match ? match[0] : "",
      isGroupModalOpen: false,
      sending: false
    };
  }

  toggleConversationList = (e) => {
    e.preventDefault();
    // console.log('toggle!!!!', this.conversationListContainer);
    $( this.conversationListContainer ).toggleClass("hidden");
  };

  componentDidMount = () => {
    var that = this;
    document.addEventListener("setConversationList", this.setConversation, false);

    if(typeof window !== 'undefined') {
      $(document).ready(function() {
        if( that.toggleConversations ) {
          that.setListPosition();

          $( window ).resize(function() {
            that.setListPosition();
          });
        }
      });
    }
  };

  setListPosition = () => {
    if( this.toggleConversations ) {
      var position = this.toggleConversations.getBoundingClientRect();
      var top = position.top;
      var left = position.left;

      $( this.conversationListContainer ).css({top: top+5, left: left+1});
    }
  };

  setConversation = (e) => {
    var openList = e.detail.openList;

    this.setState({currentConversationId: e.detail.target.match(/\d+/)[0]})

    if( !e.detail.group && openList ) {
      this.toggleConversationList();
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("setConversationList", this.setConversation, false);
  };

  handleCreateGroup = (e) => {
    e.preventDefault();

    var that = this;
    var form = this.createGroupForm;
    var data = new FormData(form);
    var addedUsers = $("input[name='conversation[users][][add]'][value='true']").length;

    if( addedUsers >= 2 ) {
      this.setState({ sending: true });
      $.ajax({
        url: '/createGroup',
        type: 'POST',
        data: data,
        processData: false,
        contentType: false
      })
      .done(function(data) {
        $( that.minMemberError ).text("");
        window.location.href = getLocaleString(that.props.locale) + "/microposts/" + that.props.micropost.seo_url + "?group=true";
        setTimeout(function() {
          that.setState({ sending: false });
        }, 1500);
      })
      .fail(function( xhr, status, err ) {
        console.log(xhr)
        setTimeout(function() {
          that.setState({ sending: false });
        }, 300);
      });
    }
    else {
      this.setState({ sending: false });
      $( this.minMemberError ).text( I18n.t('conversations.group_min', {locale: this.props.locale}) );
    }
  };

  handleUpdateGroup = (e) => {
    e.preventDefault();

    var that = this;
    var form = this.createGroupForm;
    var data = new FormData(form);
    var addedUsers = $("input[name='conversation[users][][add]'][value='true']").length;

    if( addedUsers >= 2 ) {
      this.setState({ sending: true });
      $.ajax({
        url: '/updateGroup',
        type: 'PUT',
        data: data,
        processData: false,
        contentType: false
      })
      .done(function(data) {
        $( that.minMemberError ).text("");
        window.location.href = getLocaleString(that.props.locale) + "/microposts/" + that.props.micropost.seo_url + "?group=true";
        setTimeout(function() {
          that.setState({ sending: false });
        }, 1500);
      })
      .fail(function( xhr, status, err ) {
        console.log(xhr)
        setTimeout(function() {
          that.setState({ sending: false });
        }, 300);
      });
    }
    else {
      this.setState({ sending: false });
      $( this.minMemberError ).text( I18n.t('conversations.group_min', {locale: this.props.locale}) );
    }
  };

  openGroupModal = (e) => {
    e.preventDefault();
    this.setState({ isGroupModalOpen: true });
  };

  closeGroupModal = (e) => {
    e.preventDefault();
    this.setState({ isGroupModalOpen: false });
  };

  chooseGroupConversation = (e) => {
    e.preventDefault();

    var url = getLocaleString(this.props.locale) + "/microposts/" + this.props.micropost.seo_url + "?group=true";

    window.location.href = url;
  };

  render() {
    var that = this;
    var conversationList = "";
    var initialMessage = "";
    var editGroup = "";
    var createGroup = "";
    var showEditGroup = "hidden";
    var hideCreateGroupButton = this.props.inGroup ? " hidden" : "";
    var hideUpdateGroupButton = this.props.inGroup ? "" : " hidden";
    var groupConversationId = this.props.groupConversation[0] ? this.props.groupConversation[0].id : false;
    var currentConversation = this.props.allConversations.filter(function(conversation){
      return conversation.id === parseInt(that.state.currentConversationId, 10)
    });
    currentConversation = currentConversation[0];
    if( this.props.conversationPath.indexOf("group_conversation") != -1 ) {
      showEditGroup = "";
    }
    var privateUserConversation = this.props.privateConversations.filter(function(conversation){
      return conversation.user_infos.id === parseInt(that.props.currentUser.id, 10)
    });
    privateUserConversation = privateUserConversation[0];

    if( this.props.groupConversation.length > 0 ) {
      editGroup = (
        <div className="display-inline right">{  }</div>
      );
      createGroup = I18n.t('conversations.group', {locale: this.props.locale});
    }
    else {
      createGroup = I18n.t('conversations.create_group', {locale: this.props.locale});
    }

    if( !this.props.inGroup ) {
      initialMessage = (
        <span>
          <h4 className="bebas dark-blue text-left no-margin-or-padding">{ I18n.t('conversations.group_message_to_all', {locale: this.props.locale}) }</h4>
          <textarea ref={ message => this.message = message } style={{ border: "1px solid #203546" }} rows="2" value={ I18n.t('conversations.group_message_body', {locale: this.props.locale}) } className="message-body font-size-08" name="message[body]" id="message_body"></textarea>
        </span>
      );
    }

    if( this.props.currentUser.id == this.props.micropost.user_id ) {
      if( (this.props.allConversations || {}).length > 1 && this.props.allConversations[0] ) {
        conversationList = (
          <ConversationList currentConversation={ currentConversation } micropostId={ this.props.micropost.id } conversationPath={ this.props.conversationPath } currentUser={ this.props.currentUser } conversations={ this.props.allConversations } locale={ this.props.locale } />
        );
      }

      if( currentConversation ) {
        if( !currentConversation.is_group ) {
          var toggleConversationList = (
            <a href="javascript: false" ref={ toggleConversations => this.toggleConversations = toggleConversations } onClick={ this.toggleConversationList } className="bebas white choose-conversation">
              <span className="left"><Conversation clickable={ false } isGroup={ false } micropostId={ this.props.micropost.id } locale={ this.props.locale } conversation={currentConversation} key={currentConversation.id} /></span> <span className="post-infos right caret">{ renderCaretDown() }</span>
            </a>
          );
        }
        else {
          var toggleConversationList = (
            <a href="javascript: false" ref={ toggleConversations => this.toggleConversations = toggleConversations } onClick={ this.toggleConversationList } className="bebas white choose-conversation">
              <span className="left" style={{width: "120px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{ I18n.t('conversations.list.heading', {locale: this.props.locale}) }</span> <span className="post-infos right caret">{ renderCaretDown() }</span>
            </a>
          );
        }
      }
    }
    else {
      var micropost = { user_infos: this.props.micropost.user_infos };
      var privateUserConversationId = privateUserConversation ? privateUserConversation.id : false;
      var url = getLocaleString(that.props.locale) + "/microposts/" + this.props.micropost.seo_url + "?private=" + privateUserConversationId;

      var toggleConversationList = (
        <span className="bebas white choose-conversation">
          <li>
            <a href={ url }>
              <UserProfileImage micropost={ micropost } width={25} height={25} />
              { "  " + this.props.micropost.user_infos.name }
            </a>
          </li>
        </span>
      );
    }

    if( this.props.privateConversations.length > 1 || this.props.participants.length > 1 || (this.props.inGroup && !this.props.hasPrivateConversation) || this.props.conversationPath.indexOf("group_conversation") != -1 ) {
      var groupModal = (
        <Modal isOpen={this.state.isGroupModalOpen} closeFunction={ this.closeGroupModal }>
          <div style={{marginTop:"2em"}} className="column full container">
            <h4 className="bebas text-left margin-top-10">{ I18n.t('conversations.group_convo', {locale: this.props.locale}) }</h4>
            <div className="text-left font-size-08 margin-bottom-20">{ I18n.t('conversations.mark', {locale: this.props.locale}) }</div>
            <form ref={ createGroupForm => this.createGroupForm = createGroupForm } action="createGroup" acceptCharset="UTF-8" name="groupForm" method="post">
              <GroupParticipantList participants={ this.props.participants } groupConversation={ this.props.groupConversation } />
              <div ref={ minMemberError => this.minMemberError = minMemberError } className="position-it red bebas margin-bottom-05"></div>
              { initialMessage }
              <a className={ "white no-underline bold margin-top-25" + hideCreateGroupButton } href="javascript: false">
                <button type="submit" ref={ sendMessage => this.sendMessage = sendMessage } className="margin-top-25" disabled={ this.state.sending } style={{textAlign: "center", border: "none", backgroundColor: "white"}} className="display-inline bebas" onClick={ this.handleCreateGroup } >
                  { I18n.t('conversations.create_group', {locale: this.props.locale}) }
                </button>
              </a>

              <a className={ "white no-underline bold" + hideUpdateGroupButton } href="javascript: false">
                <button type="submit" ref={ sendMessage => this.sendMessage = sendMessage } className="margin-top-25" disabled={ this.state.sending } style={{textAlign: "center", border: "none", backgroundColor: "white"}} className="display-inline bebas" onClick={ this.handleUpdateGroup } >
                  { I18n.t('conversations.update_group', {locale: this.props.locale}) }
                </button>
              </a>

              <input name="locale" type="hidden" value={ this.props.locale } />
              <input type="hidden" value={ this.props.micropost.id } name="conversation[micropost_id]" />
            </form>
          </div>
        </Modal>
      );

      if( this.props.currentUser.id == this.props.micropost.user_infos.id ) {
        if( this.props.inGroup ) {
          var updateGroupButton = (
            <div className={"red-bg group-button-container post-infos right display-inline " + hideUpdateGroupButton }>
              <a href="javascript: false" onClick={ this.chooseGroupConversation } data-conversation-id={ groupConversationId } data-user-id={ this.props.currentUser.id } className="bebas white group-button">{ renderUsersIcon() } { createGroup }</a>
              <a href="javascript: false" onClick={ this.openGroupModal } className={"bebas white group-button right " + showEditGroup} style={{marginLeft: "40px"}}>{ renderPencilIcon() }</a>
            </div>
          );
        }
        else {
          var createGroupButton = (
            <div className={"red-bg group-button-container post-infos right display-inline " + hideCreateGroupButton }>
              <a href="javascript: false" onClick={ this.openGroupModal } className="bebas white group-button">{ renderUsersIcon() } { createGroup }</a>
            </div>
          );
        }
      }
      else {
        var updateGroupButton = (
          <div className={"red-bg group-button-container post-infos right display-inline " + hideUpdateGroupButton }>
            <a href="javascript: false" onClick={ this.chooseGroupConversation } data-conversation-id={ groupConversationId } data-user-id={ this.props.currentUser.id } className="bebas white group-button">{ renderUsersIcon() } { createGroup }</a>
          </div>
        );
      }
    }

    return (
      <div className="row">
        { groupModal }
        <div className="column full container dark-blue-bg bold header-bar">
          <div className="max-width-1260">
            <h1 className="left white bebas font-size-12 header-bar-title hidden-for-small">{ this.props.micropost.title }</h1>
            { updateGroupButton }
            { createGroupButton }
            { toggleConversationList }
            <div ref={ conversationListContainer => this.conversationListContainer = conversationListContainer } className="hidden conversation-list-container">
              { conversationList }
            </div>
          </div>

          <div className="clearfix"></div>
        </div>

        <div className="clearfix"></div>
      </div>
    )
  }
}
