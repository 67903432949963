'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import Badge from './Badge';
import BaseComponent from '../../libs/components/BaseComponent';
import initTooltip from '../../helper/Tooltip';
import I18n from 'i18n-js';

export default class BadgeList extends BaseComponent {
	constructor() {
    super();
    this.state = {
			allBadges: [ "fresh_meat", "new_kid", "adventurous", "gregarious_animal",
      						 "curious", "icebreaker", "open_minded", "autobiograph",
      						 "backpacker", "adventurer", "columbus", "hitchhiker",
      						 "traveller", "marco_polo", "active_member", "established_member",
      						 "buddy", "chatterbox", "secretary", "rowling", "knave", "scout", "braveheart"]
    };
  }


  componentDidMount = () => {
    initTooltip();
  };

  render() {
    var grayClass = "";
    var that = this;

    var badgeList = (
			<div className="row center " ref={ badgeList => this.badgeList = badgeList }>
			  { this.state.allBadges.map(function( badgeName, i ) {
				  	if( that.props.userBadges.indexOf( badgeName ) == -1 ) {
				  		grayClass = "gray ";
				  	}
				  	else {
				  		grayClass = "";
				  	}

				  	switch (badgeName) {
						  case "new_kid":
						  case "adventurous":
						  case "gregarious_animal":
						    //Statements executed when the result of expression matches value1
						    var current_state = that.props.user.post_count;
						    break;
						  case "curious":
						  case "icebreaker":
						  case "open_minded":
						    //Statements executed when the result of expression matches value1
						    var current_state = that.props.user.cr_count;
						    break;
						  case "autobiograph":
						    //Statements executed when the result of expression matches value1
						    var current_state = "";

						    if( !that.props.user.about ) {
						    	current_state += I18n.t('users.about_missing', {locale: that.props.locale});
						    }

						    if( that.props.user.user_infos.avatar.indexOf( "buddyavatar.svg" ) > -1 ) {
						    	current_state += I18n.t('users.avatar_missing', {locale: that.props.locale});
						    }

						    if( !that.props.user.location ) {
						    	current_state += I18n.t('users.city_missing', {locale: that.props.locale});
						    }
						    break;
						  case "backpacker":
						  case "adventurer":
						  case "columbus":
						    //Statements executed when the result of expression matches value1
						    var current_state = that.props.user.count_cities_posted;
						    break;
						  case "hitchhiker":
						  case "traveller":
						  case "marco_polo":
						    //Statements executed when the result of expression matches value1
						    var current_state = that.props.user.count_cities_participated;
						    break;
						  case "active_member":
						  case "established_member":
						  case "buddy":
						    //Statements executed when the result of expression matches value1
						    var current_state = that.props.user.level;
						    break;
						  case "chatterbox":
						  case "secretary":
						  case "rowling":
						    //Statements executed when the result of expression matches value1
						    var current_state = that.props.user.count_messages;
						    break;
						  case "knave":
						  case "scout":
						  case "braveheart":
						    //Statements executed when the result of expression matches value1
						    var current_state = that.props.user.first_joined;
						    break;
						  default:
						    //Statements executed when none of the values match the value of the expression
						    var current_state = "";
						    break;
						}

			  		return (
				  		<Badge gray={ grayClass } name={ badgeName } currentState={ current_state } user={ that.props.user } locale={ that.props.locale } key={ i } />
				  	);
			  	})
				}
	    </div>
	  );

    return (
      <div>
      	{ badgeList }
      </div>
    );

  }
}
