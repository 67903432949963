'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';

var renderShowConversationIcon = function() {
	return(
		<svg  x="0px" y="0px" width="34.614px" height="21.803px" viewBox="0 0 34.614 21.803" enableBackground="new 0 0 34.614 21.803">
			<g>
				<path d="M12.841,17.827c-0.505,0-1.01-0.126-1.515-0.189c-0.252-0.063-0.568-0.126-0.82-0.126
						c-0.189,0-0.315,0.063-0.442,0.189c-1.893,2.398-4.481,2.966-6.122,3.092c1.704-1.388,1.893-2.903,1.957-3.408
						c0-0.694-0.189-1.199-0.568-1.452c-2.65-1.578-4.228-3.913-4.228-6.438c0-4.607,5.238-8.394,11.675-8.394
						c6.185,0,10.477,3.471,10.477,8.394C23.254,14.355,18.963,17.827,12.841,17.827z M24.201,9.496c0-5.491-4.67-9.34-11.423-9.34
						c-7.068,0-12.622,4.103-12.622,9.34c0,2.84,1.704,5.491,4.67,7.258c0.063,0.063,0.189,0.379,0.063,0.884
						c-0.126,0.568-0.568,2.02-2.84,3.092c-0.189,0.063-0.315,0.315-0.252,0.505c0,0.189,0.189,0.379,0.379,0.379
						c0,0,0.442,0.063,1.136,0.063c1.704,0,4.923-0.442,7.321-3.219c0.189,0,0.315,0.063,0.505,0.126
						c0.568,0.126,1.136,0.189,1.704,0.189C19.531,18.773,24.201,14.923,24.201,9.496z M32.974,20.792
						c-1.831-0.883-2.272-2.02-2.398-2.524c-0.126-0.441,0-0.757,0-0.757c2.587-1.515,4.039-3.787,4.039-6.311
						c0-4.544-4.796-8.078-10.918-8.078h-0.063c-0.252,0-0.441,0.189-0.441,0.505c0,0.252,0.252,0.442,0.505,0.442
						c5.491,0,9.971,3.219,9.971,7.131c0,2.146-1.325,4.166-3.597,5.491c-0.378,0.189-0.568,0.694-0.505,1.325
						c0,0.442,0.189,1.641,1.578,2.777c-1.388-0.126-3.471-0.694-4.986-2.587c-0.126-0.126-0.252-0.189-0.441-0.189
						c-0.252,0.063-0.442,0.063-0.694,0.126c-0.442,0.063-0.884,0.189-1.325,0.189c-1.199,0-2.335-0.189-3.408-0.442
						c-0.252-0.063-0.505,0.063-0.568,0.315s0.063,0.505,0.315,0.568c1.136,0.316,2.398,0.505,3.724,0.505
						c0.505,0,1.01-0.126,1.515-0.189c0.126,0,0.252-0.063,0.378-0.063c2.083,2.398,4.797,2.777,6.248,2.777
						c0.568,0,0.947-0.063,1.01-0.063c0.189-0.063,0.379-0.189,0.379-0.378C33.289,21.108,33.163,20.919,32.974,20.792z"/>
			</g>
		</svg>
	)
};

export default renderShowConversationIcon;
