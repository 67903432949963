'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import DateItem from './DateItem';
import BaseComponent from '../../libs/components/BaseComponent';
import $ from 'jquery';
import I18n from 'i18n-js';

function createInstance() {
  var func = null;
  return {
    save: function(f) {
      func = f;
    },
    restore: function(context) {
      func && func(context);
    }
  }
}

export default class DateList extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      dateNodeArrays: [],
			checkDates: props.checkDates
    };
  }

  componentDidMount() {
    this.generateDateItems(this.props);
  }

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if( nextProps.checkable !== this.props.checkable && !nextProps.justSavedDates ) {
			this.generateDateItems(nextProps);
		}

		if( nextProps.checkable === this.props.checkable && nextProps.justSavedDates ) {
			if( nextProps.checkable === false ) {
				var customProps = $.extend({}, this.props);

				customProps.checkable = false;
				this.generateDateItems(customProps);
			}
		}
	};

	generateDateItems = (props) => {
		var dateNodes = this.props.dates.map( function ( date, i ) {
			return (
				<DateItem date={ date }
								  conversationsCounter={ props.conversationsCounter }
								  justSavedDates={ props.justSavedDates }
								  key={i}
								  id={i}
								  instance={ createInstance() }
								  checkable={ props.checkable } />
			)

		}.bind(this));

		var dateNodeArrays = [], size = 8;
		while (dateNodes.length > 0) {
    	dateNodeArrays.push(dateNodes.splice(0, size));
    }

    this.setState({ dateNodeArrays: dateNodeArrays });
	};

  toggleDates = () => {
  	var node = this.dateListContainer;
  	if( node ) {
  		$( node ).toggleClass("hidden");
  	}
  	this.setState({ checkDates: !this.state.checkDates });
  };

  prevDates = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if( this.props.dateNodeArrayIndex > 0 ) {
    	this.props.prevDates(e);
    }
  };

  nextDates = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if( this.props.dateNodeArrayIndex < this.state.dateNodeArrays.length-1 ) {
    	this.props.nextDates(e);
    }
  };

	render() {
		var checkDates = this.state.checkDates;
		var hideDateArray = "hidden";
		var hideDates = checkDates;
		var hiddenClass = "";
		if( !hideDates ) {
			hiddenClass = "hidden ";
		}

		var dateListErrorClass = "";
		if( this.props.errors != "" && this.props.checkable === true && this.props.checkable && !this.props.isMicropostForm ) {
			dateListErrorClass = "date-list-error";
		}

		var checkAlways = !this.state.checkDates;
		var micropostDatesClass = this.props.checkable ? "" : "micropost-dates";
		var buttons = "";
		var _this = this;
		var showButtonsFormCondition = this.props.isMicropostForm ? this.state.checkDates : true;

    if( this.state.dateNodeArrays.length > 1 && showButtonsFormCondition ) {
    	var grayClassNext = "";
    	var grayClassPrev = "";
    	var disabledNext = false;
    	var disabledPrev = false;

    	if( this.props.dateNodeArrayIndex == this.state.dateNodeArrays.length-1 ) {
    		grayClassNext = "gray";
    		disabledNext = true;
    	}

    	if( this.props.dateNodeArrayIndex == 0 ) {
    		grayClassPrev = "gray";
    		disabledPrev = true;
    	}

    	buttons = (
    		<span>
    			<button ref={ prevButton => this.prevButton = prevButton } className={ grayClassPrev + " left bebas prev-date" } disabled={ disabledPrev } onClick={ this.prevDates }>&#8592; zurück</button>
					<button ref={ nextButton => this.nextButton = nextButton } className={ grayClassNext + " right bebas next-date" } disabled={ disabledNext } onClick={ this.nextDates }>vor &#8594;</button>
					<div className="clearfix"></div>
				</span>
    	);
    }

		if( this.props.isMicropostForm ) {
			var formText = <span>
										 	<div className="hidden column full choose-flex-date text-left-small margin-left-10">
											 	<div className="hidden column display-inline no-padding text-left margin-right-20">
											 		<input type="radio" id="no_dates" name="no_dates" value="true" checked={ checkAlways }  onClick={ this.toggleDates } />
											 		<label className="display-inline bebas" htmlFor="no_dates">{ I18n.t('new_post.time_flexible', {locale: this.props.locale}) }</label>
											 	</div>
											 	<div className="hidden column display-inline no-padding text-left">
											 		<input type="radio" id="yes_dates" name="no_dates" value="false" checked={ checkDates } onClick={ this.toggleDates } />
											 		<label className="display-inline bebas" htmlFor="yes_dates">{ I18n.t('new_post.time_choose', {locale: this.props.locale}) }</label>
											 	</div>
											 </div>
										 </span>;
			var dateList = <div ref={ dateListContainer => this.dateListContainer = dateListContainer } className={ hiddenClass + "date-list-container center animated fadeInUp" }>
                      <div style={{textAlign: "center"}}>
  										 	<ul ref={ dateList => this.dateList = dateList } style={{textAlign: "center"}} className={ dateListErrorClass } key={ "dateList" + this.props.dateNodeArrayIndex }>

  											  { this.state.dateNodeArrays.map(function( dateArray, i ) {
  											  		if( _this.props.dateNodeArrayIndex == i ){
  											  			hideDateArray = "";
  											  		}
  											  		else {
  											  			hideDateArray = "hidden";
  											  		}

  											  		return (
  												  		<span id={i} className={ "dateArraySpan " + hideDateArray } key={i}>
  												  			{ dateArray }
  												  		</span>
  												  	);
  											  	})
  												}
  										   <div className="clearfix"></div>
  									    </ul>
                      </div>
									   </div>;
		}
		else {
			var formText = "";
			var dateList = "";

			if( this.props.dates.length > 0 ){
				var dateList = 	(
          <div style={{textAlign: "center"}}>
  					<ul ref={ dateList => this.dateList = dateList } style={{textAlign: "center"}} className={ dateListErrorClass } key={ "dateList" + this.props.dateNodeArrayIndex }>

  					  { this.state.dateNodeArrays.map(function( dateArray, i ) {
  					  		if( _this.props.dateNodeArrayIndex == i ) {
  					  			hideDateArray = "";
  					  		}
  					  		else {
  					  			hideDateArray = "hidden";
  					  		}

  					  		return (
  						  		<span className={ hideDateArray } key={i}>
  						  			{ dateArray }
  						  		</span>
  						  	);
  					  	})
  						}
  				   <div className="clearfix"></div>
  			    </ul>
          </div>
			  );
			}
		}

		return (
			<div ref={ micropostDates => this.micropostDates = micropostDates } className={" " + micropostDatesClass}>
				<div className="date-list dark-blue bebas white-bg noselect">
					{ formText }
					{ dateList }
					<div style={{padding: "0 1em"}}>{ buttons }</div>
					<div className="clearfix"></div>
				</div>
			</div>
		);
	}
}
