'use strict';
import React, { Component, Fragment } from 'react';
import Message from './Message';
import getFirstName from '../../helper/GetFirstName';
import BaseComponent from '../../libs/components/BaseComponent';
import MessagesPWAInstallAd from '../Elements/Ads/MessagesPWAInstallAd';
import FriendlyAdBanner from '../Elements/Ads/FriendlyAdBanner';
import $ from 'jquery';
import I18n from 'i18n-js';

export default class MessageList extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    $('.micropost-content').linkify({
      tagName: 'a',
      target: '_blank',
      newLine: '\n',
      linkClass: 'my-links white-bg-link',
      linkAttributes: null
    });
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if( nextProps.messages !== this.props.messages ) {
      this.setState({ messages: nextProps.messages });
    }
  };

	render() {
    var that = this;

    if( this.props.messages.length > 0 ) {
      var adsShown = 0;
      var messageList = (
        <ul className="display-block messages-list">
          { this.props.messages.map(function(message, i) {
            if (typeof message === 'object' && message !== null) {
              var userFirstName = getFirstName( message.user_infos.name );
              var isOwner = that.props.currentUser.id === message.user_infos.id;
              var classRed = "";
              var installAd = "";

              if ([3, 8, 14, 19, 25, 31, 37].indexOf(i + 1) !== -1) {
                if(that.props.affiliates && that.props.affiliates[adsShown]) {
                  // console.log(that.props.affiliates)
                  installAd = <FriendlyAdBanner key={ Math.random() } ad={ that.props.affiliates[adsShown] } />;
                  adsShown++;
                  if ((adsShown + 1) > that.props.affiliates.length) adsShown = 0;
                  //
                }

                return (
                  <div key={i}>
                    {/* <MessagesPWAInstallAd key={ (i+1) * 7} /> */}
                    { installAd }
                    <Message message={ message } myKey={i}
                             currentUser={ that.props.currentUser }
                             locale={ that.props.locale }
                             isOwner={ isOwner } />
                  </div>
                );
              } else {
                return (
                  <Message message={ message } key={i} myKey={i}
                           currentUser={ that.props.currentUser }
                           locale={ that.props.locale }
                           isOwner={ isOwner } />
                );
              }
            }
          }) }
        </ul>
      );
    }
    else if ( this.props.currentUserHasPrivateConversation ) {
      var messageList = (
        <div className="column full bebas dark-blue center margin-top-10">{ I18n.t('conversations.no_messages', {locale: this.props.locale}) }</div>
      );
    }

		return (
      <div>
        { messageList }
        <div className="clearfix"></div>
      </div>
		);
	}
}
