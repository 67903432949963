'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import UserProfileImage from '../Elements/UserProfileImage';
import AutocompleteField from '../Elements/AutocompleteField';
import DateList from './DateList';
import MBP from '../../helper/MobileBoilerplate';
import BaseComponent from '../../libs/components/BaseComponent';
import $ from 'jquery';
import I18n from 'i18n-js';

export default class MicropostsEditView extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      data_uri: null, // URI for an uploaded post image
      micropostFormErrors: '',
      checkable: false,
      sending: false,
      dateIndex: 0,
      timestamp: Math.floor(Date.now() / 1000) // Set timestamp to mark an old version of the view
    };
  }

  componentDidMount = () => {
    var textarea = this.micropostFormContent;
    typeof window !== 'undefined' ? new MBP.autogrow( textarea ) : null;
    var customEvent = new CustomEvent("input", { bubbles: true });
    this.fileInput.addEventListener('change', this.handleFileSelect, false);
    textarea.dispatchEvent( customEvent );

    document.addEventListener("locationChanged", this.handleLocationChange, false);
  };

  componentWillUnmount = () => {
    this.fileInput.removeEventListener('change', this.handleFileSelect, false);
    window.removeEventListener("locationChanged", this.handleLocationChange, false);
  };

  handleLocationChange = (e) => {
    this.micropostLatitude.value = e.detail.data.latitude;
    this.micropostLongitude.value = e.detail.data.longitude;
    this.micropostCity.value = e.detail.data.cityName;
  };

  chooseImage = (e) => {
    e.preventDefault();
    $( this.fileInput ).click();
  };

  handleFileSelect = (e) => {
    if(typeof FileReader == "undefined") return true;
    var self = this;
    var chooseElement = this.choose;
    var reader = new FileReader();
    var file = e.target.files[0];
    var fileName = file.name.replace( /C:\\fakepath\\/i, '' );
    fileName = this.truncateString( fileName, 20 );

    $( chooseElement ).text(fileName);

    var addImage = $( chooseElement ).text();
    $( chooseElement ).data('text', addImage);

    reader.onload = function(file) {
      self.setState({
        data_uri: file.target.result,
      });

      $( self.previewImage ).css({'background-image': 'url(' + self.state.data_uri + ')',
                                                            'background-size': '100% auto',
                                                            'background-repeat': 'no-repeat',
                                                            'min-height': '110px' });
    };

    reader.readAsDataURL(file);
    $( this.previewImage ).after( this.choose );
    $( this.cancelImg ).removeClass('hidden');
    if( this.saveNotice ) {
      $( this.saveNotice ).toggleClass('hidden');
    }
    $( this.previewImage ).toggleClass('overlay');
  };

  truncateString = (n, len) => {
    var ext = n.substring(n.lastIndexOf(".") + 1, n.length).toLowerCase();
    var filename = n.replace('.' + ext,'');
    if(filename.length <= len) {
        return n;
    }
    filename = filename.substr(0, len) + (n.length > len ? '[...]' : '');

    return filename + '.' + ext;
  };

  // Cancel an uploaded image
  handleCancel = () => {
    $( this.previewImage ).html( this.choose );
    $( this.previewImage ).css( { 'background': '',
                                                      'background-size': '',
                                                      'background-position': '',
                                                      'min-height': 'auto' } );

    $( this.cancelImg ).toggleClass('hidden');
    if( this.saveNotice ) {
      $( this.saveNotice ).toggleClass('hidden');
    }

    $( this.choose ).text( I18n.t('microposts.change_image', {locale: this.props.initialData.locale}) );
    // Deleting this input field deletes the image from the upload queue
    // of the jquery image upload plugin
    $( this.uploadButtonContainer ).empty();

    // This clears and clones the input field. Just to be sure, probably not needed.
    // To Do: Check if it can be removed
    // $( this.fileInput ).replaceWith($( this.fileInput ).val('').clone(true));

    $( this.previewImage ).toggleClass('overlay');
    if( this.micropostTitle ) {
      //$( this.micropostTitle ).toggleClass('blue white');
    }
  };

  prevDates = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if( this.state.dateIndex > 0 ) {
      this.setState({ dateIndex: this.state.dateIndex - 1 });
    }
  };

  nextDates = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if( this.state.dateIndex < 3 ) {
      this.setState({ dateIndex: this.state.dateIndex + 1 });
    }
  };

  // Submit the micropost create form
  handleFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ sending: true });

    var data = new FormData( this.micropostForm );

    $.ajax({
      data: data,
      url: "/microposts/" + this.props.initialData.micropost.id,
      type: "PUT",
      processData: false,
      contentType: false,
      timeout: 20000
    })
    .done( function( data ) {
      this.setState({ sending: false, micropostFormErrors: '' });
      //this.resetForm(data);
    }.bind(this))
    .fail( function( xhr ) {
      console.log(xhr);
      $("html, body").animate({ scrollTop: 0 });

      this.setState({
        micropostFormErrors: $.parseJSON( xhr.responseText ).errors,
        sending: false
      });
    }.bind(this));
  };

  handleBff = (e) => {
    var now = new Date();
    now = new Date(now.getTime() + (now.getTimezoneOffset() * 60000));
    var premiumDate = new Date(this.props.initialData.currentUser.user_infos.premium);

    if (premiumDate < now) {
      e.preventDefault();
      var customEvent = new CustomEvent("triggerPremiumModal", { detail: {highlight: 'bff'}, bubbles: false });
      document.dispatchEvent( customEvent );
    }
  }

  render() {
    var title = this.props.initialData.micropost.title;
    var content = this.props.initialData.micropost.content;
    var bff = this.props.initialData.micropost.bff ? true : false;
    var fakeMicropost = { user_infos: this.props.initialData.currentUser };
    var userAvatar = <UserProfileImage micropost={fakeMicropost} />;
    var formLat = this.props.initialData.micropost.latitude;
    var formLon = this.props.initialData.micropost.longitude;
    var formLocation = this.props.initialData.micropost.location ? this.props.initialData.micropost.location : "";
    var that = this;
    var bffFeature = "";

    if( this.props.initialData.micropostForm.dates.length > 0 ) {

      var checkedDates = this.props.initialData.micropost.dates;
      var arrayLength = checkedDates.length;

      var datesToEdit = this.props.initialData.micropostForm.dates.map( function( date, i ) {
        var dateObj = new Date( date.utcDate.replace(/-/g, "/") );

        for(var i = 0; i < arrayLength; i++) {
          var checkedDatesObj = new Date( checkedDates[i].utcDate.replace(/-/g, "/") );

          if( dateObj.getTime() === checkedDatesObj.getTime() ) {
            date.currentUserIsFollowing = true;
          }
        }

        return date
      });
    }

    var checkDates = this.props.initialData.micropost.dates.length > 0 || this.props.initialData.renew;

    var imageUploadStyle = {
      height: '0px',
      width: '0px',
      overflow: 'hidden',
      visisbility: 'hidden',
      float: 'right'
    };

    var zIndex = {
      position: 'relative',
      zIndex: 999
    }

    if( this.state.micropostFormErrors.length > 0 ) {
      var errors = this.state.micropostFormErrors.join("<br />");
    }

    if (this.props.initialData.currentUser.gender === 'F') {
      bffFeature = (
        <div>
          <label className="bebas" style={{textAlign: "left", color: "#f56363"}}>
            <input ref={ bffCheckbox => this.bffCheckbox = bffCheckbox } checked={ bff } type="checkbox" name="micropost[bff]" value="true" onClick={this.handleBff} style={{marginRight: "0.5rem"}} />
            { I18n.t('microposts.bff_feature_text', {locale: this.props.initialData.locale}) }
          </label>
        </div>
      );
    }

    return (
      <div className="row">
        <div className="column full container show-view">
          <div className="margin-auto max-width">
            <div className="micropost-list">
              <div className="" style={{ margin: '1rem auto' }}>
                <div className="feed-item center margin-auto" style={ {width: "100%", maxWidth: "25em"} }>
                  <form ref={ micropostForm => this.micropostForm = micropostForm } style={ {border: "1px solid #ddd", paddingTop: "1.5em"} } className="micropostForm" encType="multipart/form-data" action={ this.props.initialData.micropostForm.action } acceptCharset="UTF-8" method="post">
                    <div  style={ {border: "none"} } className="top white-bg container">
                      <div ref={ previewImage => this.previewImage = previewImage } style={{border: "1px dashed #22a8b5", padding: "0.2em 0"}} className="">
                        <a ref={ choose => this.choose = choose } href="javascript: false" className="bebas choose-image" onClick={this.chooseImage}>{ I18n.t('microposts.choose_image', {locale: this.props.initialData.locale}) }</a>
                      </div>
                      <div ref={ cancelImg => this.cancelImg = cancelImg } className="cancel-img hidden bebas-bold red close" onClick={this.handleCancel}></div>

                      <span className="content-error red bold" dangerouslySetInnerHTML={{__html: errors }}></span>

                      <label className="bebas text-left" htmlFor="micropost_title">{ I18n.t('microposts.form_title', {locale: this.props.initialData.locale}) }</label>
                      <input id="micropost_title" ref={ micropostTitle => this.micropostTitle = micropostTitle } value={ title } title={ I18n.t('microposts.set_title', {locale: this.props.initialData.locale}) } name="micropost[title]" className="micropost-form-titel bebas" placeholder={ I18n.t('microposts.form_title_placeholder', {locale: this.props.initialData.locale})} />
                    </div>

                    <div ref={ formBody => this.formBody = formBody } style={ {border: "none"} } className="body side-shadow">
                      <div className="bebas dark-blue" title={ I18n.t('location.change', {locale: this.props.initialData.locale}) }>
                        <label className="bebas text-left" htmlFor="form_autocomplete">{ I18n.t('microposts.form_city', {locale: this.props.initialData.locale}) }</label>
                        <AutocompleteField location={ formLocation } element={"autocomplete-editform"} locale={this.props.initialData.locale} />
                      </div>

                      <label className="bebas text-left" htmlFor="micropost_content">{ I18n.t('microposts.form_desc', {locale: this.props.initialData.locale}) }</label>
                      <span className="content-error red bold">{ /*this.state.micropostFormErrors*/ }</span>
                      <textarea ref={ micropostFormContent => this.micropostFormContent = micropostFormContent } className="white" title={ I18n.t('microposts.set_content', {locale: this.props.initialData.locale}) } rows="3" name="micropost[content]" id="micropost_content">{ content }</textarea>
                      { bffFeature }
                    </div>

                    <DateList checkable={ true }
                              dateNodeArrayIndex={ this.state.dateIndex }
                              nextDates={ this.nextDates }
                              prevDates={ this.prevDates }
                              dates={ datesToEdit }
                              checkDates={ checkDates }
                              isMicropostForm={ true }
                              isEdit={ true }
                              locale={ this.props.initialData.locale } />

                    <div className="bottom bebas">
                      <button type="submit" className="commit-micropost display-inline bebas" id="commit_micropost" disabled={ this.state.sending } onClick={ this.handleFormSubmit } name="commit">
                        { I18n.t('microposts.send', {locale: this.props.initialData.locale}) }</button>
                    </div>

                    <input name="locale" type="hidden" value={ this.props.initialData.locale } />
                    <div ref={ uploadButtonContainer => this.uploadButtonContainer = uploadButtonContainer } className="hidden uploadButtonContainer"></div>
                    <input type="hidden" name="renew" id="renew" value={ this.props.initialData.renew } />
                    <input type="hidden" ref={ micropostLatitude => this.micropostLatitude = micropostLatitude } name="latitude" id="micropost_latitude" value={ this.props.initialData.micropost.latitude } />
                    <input type="hidden" ref={ micropostLongitude => this.micropostLongitude = micropostLongitude } name="longitude" id="micropost_longitude" value={ this.props.initialData.micropost.longitude } />
                    <input type="hidden" ref={ micropostCity => this.micropostCity = micropostCity } name="city" id="micropost_city" value={ formLocation } />
                    <input ref={ fileInput => this.fileInput = fileInput } className="hidden" type="file" name="micropost[post_img]" id="micropost_post_img" onChange={ this.handleFileSelect } />

                    <input type="hidden" name={ this.props.initialData.micropostForm.csrf_param } value={ this.props.initialData.micropostForm.csrf_token } />
                    <input type="hidden" name="utf8" value="✓" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
