'use strict';
import PropTypes from 'prop-types';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import MicropostForm from '../Microposts/MicropostForm';
import AutocompleteField from '../Elements/AutocompleteField';
import PremiumTeaser from '../Elements/PremiumTeaser';
import Modal from './Modal';
import SignUpAndLogin from './SignUpAndLogin';
import BaseComponent from '../../libs/components/BaseComponent';
import renderSearchIcon from '../svgIcons/search';
import renderMapIcon from '../svgIcons/map';
import I18n from 'i18n-js';
import $ from 'jquery';


export default class HeaderBar extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      latitude: props.latitude,
      longitude: props.longitude,
      location: props.location,
      showSearch: false
    };
  }

  componentDidMount = () => {
    document.addEventListener("closeModal", this.closeModal, false);

    document.addEventListener("locationChanged", this.handleLocationChange, false);
  };

  componentWillUnmount = () => {
    //window.removeEventListener("openModal", this.openModal, false);
    window.removeEventListener("closeModal", this.closeModal, false);

    window.removeEventListener("locationChanged", this.handleLocationChange, false);
  };

  componentDidUpdate = () => {
    if( this.state.showSearch ) {
      this.searchBox.focus();
    }
  };

  handleLocationChange = (e) => {
    this.setState({ latitude: e.detail.data.latitude, longitude: e.detail.data.longitude, location: e.detail.data.cityName });
  };

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    var searchClass = "hidden";
    var autocompleteClass = "";
    var autocompleteField = "";
    var defaultSearchValue = "";
    var autocompleteElement = "";
    var hiddenOnSmallClass = this.props.hiddenOnSmall ? 'hidden-on-small' : '';

    if( this.state.showSearch === true ) {
      searchClass = "";
      autocompleteClass = "hidden";
    }

    if( this.props.currentUser.guest ) {
      var micropostFormOrSignup = (
        <span>
          <SignUpAndLogin  standalone={ true }
	                         loginForm={ this.props.loginForm }
	                         userForm={ this.props.userForm }
	                         loginPath={ this.props.loginPath }
                           locale={ this.props.locale } />
        </span>
      );
    }
    else {
      var micropostFormOrSignup = (
        <span>
          <MicropostForm latitude={ this.state.latitude }
                         longitude={ this.state.longitude }
                         location={ this.state.location }
                         locale={ this.props.locale }
                         hashtag={ this.props.hashtag }
                         micropostForm={ this.props.micropostForm }
                         currentUser={ this.props.currentUser } />
        </span>
      );
    }
    // Show Autocomplete Field if not on hashtags page
    if( typeof window !== 'undefined' ){
      if( window.location.href.indexOf("/hashtags/") === -1) {
        $("#autocomplete-container").removeClass("hidden");
      }

      if(window.location.href.indexOf("/cities/") === -1 && this.props.hideLocation !== true) {
        autocompleteElement = (
          <span id="autocomplete-container" className={autocompleteClass} >
            <span className="autocomplete-map-icon">{ renderMapIcon() }</span>
            <AutocompleteField location={ this.state.location } element={"autocomplete-header"} locale={this.props.locale} />
          </span>
        );
      }
    }

    return (
      <div className="row">
        <Modal isOpen={this.state.isModalOpen} closeFunction={ this.closeModal }>
          { micropostFormOrSignup }
        </Modal>

        <div className={ "column full container dark-blue-bg bold header-bar " + hiddenOnSmallClass }>
          <div className="max-width-1260 post-infos">
            { autocompleteElement }

            <button onClick={ this.openModal } className={"add-post " + autocompleteClass } title={ I18n.t('explorepage.create_post', {locale: this.props.locale}) }>
              <span className="bebas plus-text">{ I18n.t('users.discover.create', {locale: this.props.locale}) }</span> <span className="plus">+</span>
            </button>

            <div className="clearfix"></div>
          </div>

          <div className="clearfix"></div>
        </div>

        <div className="clearfix"></div>
      </div>
    )
  }
}
