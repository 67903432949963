import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';

var renderTime = function() {
	return(
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
		    <path fill="none" stroke="#1e3646" d="M12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 Z M12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 Z M12,5 C12.5522847,5 13,5.44771525 13,6 L13,6 L13,11.5857864 L15.7071068,14.2928932 C16.0976311,14.6834175 16.0976311,15.3165825 15.7071068,15.7071068 C15.3165825,16.0976311 14.6834175,16.0976311 14.2928932,15.7071068 L14.2928932,15.7071068 L11.2928932,12.7071068 C11.1053568,12.5195704 11,12.2652165 11,12 L11,12 L11,6 C11,5.44771525 11.4477153,5 12,5 Z"/>
		  <use transform="translate(-1 -1)" />
		</svg>
  )
}

export default renderTime;
