'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';

var renderMapIcon = function() {
	return(
		<svg width="1792" height="1792" viewBox="0 0 1792 1792" >
			<path d="M1152 640q0-106-75-181t-181-75-181 75-75 181 75 181 181 75 181-75 75-181zm256 0q0 109-33 179l-364 774q-16 33-47.5 52t-67.5 19-67.5-19-46.5-52l-365-774q-33-70-33-179 0-212 150-362t362-150 362 150 150 362z"/>
		</svg>
	)
};

export default renderMapIcon;
