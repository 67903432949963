'use strict';
import PropTypes from 'prop-types';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import BaseComponent from '../../libs/components/BaseComponent';
import Modal from './Modal';
import MicropostForm from '../Microposts/MicropostForm';
import getLocaleString from '../../helper/GetLocaleString';
import HomeIcon from '../svgIcons/i/home';
import HomeFullIcon from '../svgIcons/i/homeFull';
import renderSearchIcon from '../svgIcons/i/search';
import renderSearchFullIcon from '../svgIcons/i/searchFull';
import renderProfileIcon from '../svgIcons/i/profile';
import renderProfileFullIcon from '../svgIcons/i/profileFull';
import renderCommentIcon from '../svgIcons/i/comment';
import renderCommentFullIcon from '../svgIcons/i/commentFull';

export default class MobileBottomMenu extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      currentPage: typeof window !== 'undefined' ? window.location.pathname : '',
    }
  }

  componentDidMount = () => {
    document.addEventListener("openModal", this.openModal, false);
    document.addEventListener("closeModal", this.closeModal, false);

    this.setState({
      currentPage: window.location.pathname,
    });
  };

  handleClick = () => {
    // document.body.classList.add('refreshing');
  };

  openModal = (e) => {
    e.preventDefault();

    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

	render() {
    let home = this.state.currentPage === '/explore' ? <HomeFullIcon /> : <HomeIcon />;
    let search = this.state.currentPage === '/search' ? renderSearchFullIcon() : renderSearchIcon();
    let comment = this.state.currentPage === '/conversations' || this.state.currentPage.indexOf('/private_conversations/') > -1 ? renderCommentFullIcon() : renderCommentIcon();
    let profile = this.state.currentPage === `/users/${this.props.currentUserId}` ? renderProfileFullIcon() : renderProfileIcon();
    let unreadIndicator = <div className="new-indicator"></div>;

    home = <a className="menu-icon-bottom" onClick={ this.handleClick } href={getLocaleString(this.props.locale) + "/explore"}>{ home }</a>;
    search = <a className="menu-icon-bottom" onClick={ this.handleClick } href={getLocaleString(this.props.locale) + "/search"}>{ search }</a>;
    comment = <a className="menu-icon-bottom" onClick={ this.handleClick } href={getLocaleString(this.props.locale) + "/conversations"}>{ comment }</a>;
    profile = <a className="menu-icon-bottom" onClick={ this.handleClick } href={getLocaleString(this.props.locale) + `/users/${this.props.currentUserId}`}>{ profile }</a>;

    comment = this.props.unreadCount > 0 ? <span>{ comment } { unreadIndicator }</span> : comment;

    var micropostFormOrSignup = (
      <span>
        <MicropostForm latitude={ this.props.initialData.lat }
                       longitude={ this.props.initialData.lon }
                       location={ this.props.initialData.location }
                       locale={ this.props.locale }
                       hashtag={ this.props.hashtag }
                       micropostForm={ this.props.initialData.micropostForm }
                       currentUser={ this.props.initialData.currentUser } />
      </span>
    );

    return (
      <div>
        <Modal isOpen={this.state.isModalOpen} closeFunction={ this.closeModal }>
          { micropostFormOrSignup }
        </Modal>

        <nav id="mobileNav">
          <div className="columns container">
            <div key="home" className="column fifth-small center no-margin-or-padding init-loading">{ home }</div>
            <div key="search" className="column fifth-small center no-margin-or-padding init-loading">{ search }</div>
            <div key="add" className="column fifth-small center no-margin-or-padding" style={{position: "relative", top: "-3px"}}><a href="javascript: false" onClick={ this.openModal } className="menu-icon-bottom">+</a></div>
            <div key="comment" className="column fifth-small center no-margin-or-padding init-loading">{ comment }</div>
            <div key="profile" className="column fifth-small center no-margin-or-padding init-loading">{ profile }</div>
          </div>
        </nav>
      </div>
    );
  }
}
