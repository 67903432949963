'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import BaseComponent from '../../libs/components/BaseComponent';
import CookieHelpers from '../../helper/CookieHelpers';
import $ from 'jquery';


export default class AutocompleteField extends BaseComponent {
  constructor() {
    super();

    this.state = {
      lat: '',
      lon: '',
      query: '',
    };
  }

  componentDidMount = () => {
    let that = this;
    this.autocomplete.value = this.props.location;

    if (navigator.cookieEnabled === true) {
      let geolocationCookie = JSON.parse(CookieHelpers.getCookie('geolocation'));

      if (typeof geolocationCookie.latitude !== 'undefined' && typeof geolocationCookie.longitude !== 'undefined') {
        this.state.lat = geolocationCookie.latitude;
        this.state.lon = geolocationCookie.longitude;
        this.state.query = geolocationCookie.cityName;
      }
    }


    if (typeof window !== 'undefined') {
      $(this.autocomplete).on('input', function(event) {
        that.handleAutocomplete();
      });

      this.handleAutocomplete();

      $(this.autocomplete).on('focus', function() {
        that.resultList.style.display = 'block';
      }).on('blur', function() {
        that.resultList.style.display = 'none';
      });

      $(this.resultList).on('mousedown', function(event) {
        event.preventDefault();
      }).on('click', 'li', function(e) {
        e.preventDefault();
        if ($(this).attr("data-text") !== 'false') {
          $(that.autocomplete).val($(this).attr("data-text")).blur();
          that.setLocation({
            latitude: $(this).attr("data-lat"),
            longitude: $(this).attr("data-lon"),
            cityName: $(this).attr("data-text"),
          });
        }
      });

      $(this.autocomplete).on('keyup', function(e) {
        if(e.which == 13) {
          e.preventDefault();
          $("#autocomplete-results-list :first-child").trigger('click');
        }
      });
    }
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if( nextProps.location !== this.props.location ) {
      this.autocomplete.value = nextProps.location;
    }
  };

  handleAutocomplete = async() => {
    if (typeof window !== 'undefined') {
      let token = 'pk.eyJ1IjoidGhlYnVkZHkiLCJhIjoiY2p5N2h2b2pkMDEyczNvbnhxZWpmaWpvZSJ9.kl2-D2BS3llvvD5_Qlsfzg';
      let that = this;

      const source = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${that.autocomplete.value}.json?proximity=${that.state.lon},${that.state.lat}&access_token=${token}&types=place&language=${that.props.locale}&limit=8`);
      const data = await source.json();
      this.setAutocompletePosition();

      $(this.resultList).empty();

      if (typeof data.features !== 'undefined') {
        data.features.forEach((item) => {
          $(that.resultList).append(`<li class="autocomplete-result" data-text="${item.text}" data-lon="${item.geometry.coordinates[0]}" data-lat="${item.geometry.coordinates[1]}">${item.place_name}</li>`);
        });
      } else {
        $(that.resultList).append(`<li class="autocomplete-result" data-text="false">No Results...</li>`);
      }
    }
  };

  setAutocompletePosition = () => {
    let that = this;

    $(document).ready(function() {
      let position = $(that.autocomplete).position();
      let height = $(that.autocomplete).outerHeight(true);
      let width = $(that.autocomplete).outerWidth(true);

      $(that.resultList).css({
        top: position.top + height + 3,
        left: position.left,
        width: width,
      });
    });
  };

  setLocation = ( data ) => {
    // set cookie
    // if (CookieHelpers.getCookie('cookieConsent') == true) {
      var JSONString = JSON.stringify( data );
      CookieHelpers.setCookie('geolocation', JSONString, 365);
      CookieHelpers.setCookie('locationChanged', window.location.pathname, 365);
      var customEvent = new CustomEvent("locationChanged", { detail: { data }, bubbles: false });
      document.dispatchEvent( customEvent );
    // }
  };

  select = (e) => {
    e.preventDefault();
    var input = this.autocomplete;
    input.focus();
    setTimeout(function(){
      input.selectionStart = 0;
      input.selectionEnd = 999;
    }, 10);

  };

  render() {
    return (
      <span>
        <input ref={ autocomplete => this.autocomplete = autocomplete } autoComplete="off" id='autocomplete' className={ "bebas left " + this.props.element } onFocus={ this.select } name="input" type="text" value={ this.props.location } />
        <ul ref={ resultList => this.resultList = resultList } id="autocomplete-results-list"></ul>
      </span>
    )
  }
}
