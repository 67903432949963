'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import BaseComponent from '../../libs/components/BaseComponent';
import InfoIcon from './InfoIcon';

export default class PremiumTeaser extends BaseComponent {
  render() {
    var emoji = "";

    if (this.props.hideEmoji !== true) {
      emoji = (
        <div className="center font-size-46">🎉</div>
      );
    }

    return (
      <div className="" style={{padding: "1rem", zIndex: "10000"}}>
        { emoji }
        <p className="center" style={{maxWidth: "42rem", marginBottom: "1em", fontSize: "1rem", position: "relative"}}>
          { I18n.t('pricing.teaser_desc', {locale: this.props.locale}) }
        </p>

        <ul className="signup-check dark-blue" style={{maxWidth: "27rem", margin: "1rem auto 0 auto", display: "block", position: "relative"}}>
          <li className={ this.props.highlight === 'unlimited' ? "bold" : ""}>{ I18n.t('pricing.unlimited', {locale: this.props.locale}) }<InfoIcon text={ I18n.t('pricing.unlimited_desc', {locale: this.props.locale}) } /></li>
          <li className={ this.props.highlight === 'membership' ? "bold" : ""}>{ I18n.t('pricing.no_shit', {locale: this.props.locale}) }</li>
          <li className={ this.props.highlight === 'bff' ? "bold" : ""}>{ I18n.t('pricing.bff', {locale: this.props.locale}) }<InfoIcon text={ I18n.t('pricing.bff_desc', {locale: this.props.locale}) } /></li>
          <li className={ this.props.highlight === 'verified' ? "bold" : ""}>{ I18n.t('pricing.verified', {locale: this.props.locale}) }<InfoIcon text={ I18n.t('pricing.verified_desc', {locale: this.props.locale}) } /></li>
          <li className={ this.props.highlight === 'visitors' ? "bold" : ""}>{ I18n.t('pricing.profile_visitors', {locale: this.props.locale}) }</li>
          {/*<li className={ this.props.highlight === 'picSize' ? "bold" : ""}>{ I18n.t('pricing.pic_size', {locale: this.props.locale}) }</li> */}
          <li className={ this.props.highlight === 'user_search' ? "bold" : ""}>{ I18n.t('pricing.user_search', {locale: this.props.locale}) }</li>
          <li className={ this.props.highlight === 'contact_infos' ? "bold" : ""}>{ I18n.t('pricing.contact_infos', {locale: this.props.locale}) }<InfoIcon text={ I18n.t('pricing.contact_infos_desc', {locale: this.props.locale}) } /></li>
        </ul>

        <a href="/premium" style={{fontSize: "1.2rem", margin: "20px auto 100px auto"}} className="join-button position-it white display-block bebas">{ I18n.t('pricing.teaser_button', {locale: this.props.locale}) }</a>
      </div>
    )
  }
}
