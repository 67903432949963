'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import { getParams } from '../../helper/GetParams';
import BaseComponent from '../../libs/components/BaseComponent';
import $ from 'jquery';

export default class NextButton extends BaseComponent {
	constructor(props) {
		super(props);
		var page = 1;
		if( typeof window !== 'undefined' ) {
			if( window.location.href.indexOf("page=") !== -1 ) {
				var params = getParams( window.location.href );
				page = parseInt(params.page, 10);
			}
		}

		this.state = {
			page: page
		};
	}

	componentDidMount = () => {
	};

	handleNext = (e) => {
    e.preventDefault();
		var pushState = true;
		var url = this.props.url;

		if( this.props.pushState == false) {
			pushState = false;
		}

		if( this.props.url.indexOf("page=") === -1 ) {
			var newPage = this.state.page + 1;
			if( this.props.url.indexOf("?") !== -1 ) {
				url = url + "&page=" + newPage;
			}
			else {
				url = url + "?page=" + newPage;
			}
		}
		else {
			var newPage = this.state.page + 1;
			url = url.replace("page=" + this.state.page, "page=" + newPage);
		}

		this.setState({page: newPage});

    this.props.loadFromServer( url, false, false );
    $( this.nextLink ).addClass("fadeOutDown");
		$( this.spinner ).removeClass("hidden").addClass("fadeInUp");
  };

  render() {
		if( this.props.lastPage ) {
			return(<span></span>);
		}
		else {
	    return (
				<span>
		      <div className="full center next-link-container">
						<div ref={ spinner => this.spinner = spinner } className="spinner hidden"></div>
		        <a ref={ nextLink => this.nextLink = nextLink } className="next-link bebas my-links dark-blue animated" href="javascript: false" onClick={ this.handleNext }>
		          { this.props.children }
		        </a>
		      </div>
				</span>
	    );
		}
  }
}
