'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import getLocaleString from '../../helper/GetLocaleString';
import Micropost from './Micropost';
import Modal from '../Elements/Modal';
import UserProfileImage from '../Elements/UserProfileImage';
import ConversationsShowView from '../Conversations/ConversationsShowView';
import ConversationList from '../Conversations/ConversationList';
import DateList from './DateList';
import HeaderBarConversations from '../Elements/HeaderBarConversations';
// import SmallHeaderDisplayAd from '../Elements/Ads/SmallHeaderDisplayAd';
// import ResponsiveHorizontalDisplayAd from '../Elements/Ads/ResponsiveHorizontalDisplayAd';
import BaseComponent from '../../libs/components/BaseComponent';
import renderLogo from '../svgIcons/logo';
import { getParams } from '../../helper/GetParams';
import getFirstName from '../../helper/GetFirstName';
import linkHashtags from '../../helper/LinkHashtags';
import GroupParticipantList from '../Conversations/GroupParticipantList';
import renderBuddyAvatar from '../svgIcons/buddyAvatar';
import renderMapIcon from '../svgIcons/map';
import renderUsersIcon from '../svgIcons/users';
import renderEyeIcon from '../svgIcons/eye';
import renderEditIcon from '../svgIcons/edit';
import renderDeleteIcon from '../svgIcons/delete';
import renderFacebookIcon from '../svgIcons/facebook';
import renderEnvelopeIcon from '../svgIcons/envelope';
import renderAngleUp from '../svgIcons/angleUp';
import renderAngleDown from '../svgIcons/angleDown';
import renderCalendarIcon from '../svgIcons/calendar';
import $ from 'jquery';
import I18n from 'i18n-js';

export default class MicropostsShowView extends BaseComponent {
  constructor(props) {
    super(props);
    var params = { group: false };
    if ( typeof window !== 'undefined' ) {
			      params = getParams( window.location.href );
		    }
    this.state = {
      isGroupModalOpen: false,
      isParticipantsModalOpen: false,
      conversationPath: props.initialData.path ? props.initialData.path : '',
      deleting: false,
      params: params,
      initialDates: props.initialData.micropost.dates,
      checkable: false,
      justSavedDates: false,
      dateIndex: 0
    };
  }

  componentDidMount = () => {
    document.addEventListener('openModal', this.openModal, false);

    $( window ).resize(function () {
      var width = $( window ).width();

      if ( width > 624 ) {
        $('#primary, #secondary, #tertiary').css({ display: "block" });
      }
    });

    if ( this.state.params ) {
      if ( typeof this.state.params.private !== 'undefined' || typeof this.state.params.group !== 'undefined') {
        var $primary = $('#primary');
        $primary.addClass('hide-for-small');
        $('#tertiary').addClass('hide-for-small');
        $("a[data-container='tertiary']").children('.angle-down').removeClass('hidden');
        $("a[data-container='primary']").children('.angle-down').removeClass('hidden');
        $("a[data-container='secondary']").children('.angle-up').removeClass('hidden');
      }
      else {
        var $secondary = $('#secondary');
        // $secondary.addClass('hide-for-small');
        $('#tertiary').addClass('hide-for-small');
        $("a[data-container='tertiary']").children('.angle-down').removeClass('hidden');
        $("a[data-container='secondary']").children('.angle-up').removeClass('hidden');
        $("a[data-container='primary']").children('.angle-up').removeClass('hidden');
      }
    }

    $( this.micropostContent ).linkify({
		        tagName: 'a',
		        target: '_blank',
		        newLine: '\n',
		        linkClass: 'my-links',
		        linkAttributes: null
		    });
  };

  componentWillUnmount = () => {
    window.removeEventListener('openModal', this.openModal, false);
  };

  openParticipantsModal = (e) => {
    e.preventDefault();
    this.setState({ isParticipantsModalOpen: true });
  };

  closeParticipantsModal = (e) => {
    e.preventDefault();
    this.setState({ isParticipantsModalOpen: false });
  };

  handleDeletePost = (e) => {
    e.preventDefault();
    var confirmed = confirm( I18n.t('microposts.confirm_delete', { locale: this.props.initialData.locale }) );

    if ( confirmed ) {
      var that = this;
      this.setState({ deleting: true });

      $.ajax({
        url: '/microposts/' + that.props.initialData.micropost.seo_url,
        type: 'DELETE',
        processData: false,
        contentType: false
      })
      .done(function (data) {
        console.log('DELETED!');
        window.location.href = "/";
      })
      .fail(function ( xhr, status, err ) {
        console.log(xhr);
      });
    }
  };

  slideContainer = (e) => {
    e.preventDefault();
    var container = $(e.currentTarget).data('container');

    $('#' + container).slideToggle();
    $(e.currentTarget).children('.angle-down').toggleClass('hidden');
    $(e.currentTarget).children('.angle-up').toggleClass('hidden');
  };

  prevDates = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ dateIndex: this.state.dateIndex - 1 });
  };

  nextDates = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ dateIndex: this.state.dateIndex + 1 });
  };

  toggleCheckable = (e) => {
    this.setState({ checkable: !this.state.checkable });
  };

  updateAppointments = (e) => {
    e.preventDefault();
    e.stopPropagation();

    var that = this;
    var form = this.appointmentForm;
    var data = new FormData( form );

    $.ajax({
      url: form.getAttribute('action'),
      type: 'PUT',
      data: data,
      processData: false,
      contentType: false
    })
    .done(function () {
      that.setState({ checkable: false, justSavedDates: true });
    })
    .fail(function ( xhr, status, err ) {
      console.log( xhr.responseText );
    });
  };

	render() {
  var postMargin = 'margin-bottom-0';
  var interested = '';
  var userLocation = '';
  var about = '';
  var micropostEditUrl = getLocaleString(this.props.initialData.locale) + '/microposts/' + this.props.initialData.micropost.seo_url + '/edit';
  var editIcons = '';
  var participantsModal = '';
  var headerBar = '';
  var initialMessage = '';
  var voteButton = '';
  var saveDatesButton = '';
  var dateForm = '';
  var dateIntro = '';
  var showDates = 'hidden';

  var groupConversation = this.props.initialData.all_conversations.filter(function (conversation) {
      return conversation.is_group === true;
    });
  groupConversation = [].concat.apply([], groupConversation);

  var privateConversations = this.props.initialData.all_conversations.filter(function (conversation) {
      return conversation.is_group === false;
    });

  if ( [1, 2].indexOf(this.props.initialData.currentUser.id) > -1) {
      var hasModeratorPrivileges = true;
    }
    else {
      var hasModeratorPrivileges = false;
    }

  if ( (this.props.initialData.currentUser.id === this.props.initialData.micropost.user_id) || this.props.initialData.currentUserInGroupConversation || this.props.initialData.currentUserHasPrivateConversation ) {
      if ( this.props.initialData.micropost.dates.length > 0 ) {
        if ( this.state.checkable === false ) {
          saveDatesButton = '';
          voteButton = (
            <button className="big-submit-button position-it white display-inline bebas margin-top-10" style={{ fontSize: '1.2em', lineHeight: '1.3em', display: 'block', margin: '0 auto', backgroundColor: "#1e3646" }} onClick={this.toggleCheckable}>{I18n.t('microposts.vote_dates', { locale: this.props.initialData.locale })}</button>
          );
        }
        else if ( this.state.checkable === true) {
          voteButton = '';
          saveDatesButton = (
            <button className="big-submit-button position-it white display-inline bebas margin-top-10" style={{ fontSize: '1.2em', lineHeight: '1.3em', display: 'block', margin: '0 auto', backgroundColor: "#1e3646" }} onClick={this.updateAppointments}>{I18n.t('microposts.save_dates', { locale: this.props.initialData.locale })}</button>
          );
        }

        showDates = '';
      }
      else {
        showDates = 'hidden';
      }
    }

  if ( !this.props.initialData.currentUserInGroupConversation ) {
      initialMessage = (
        <span>
          <h4 className="bebas dark-blue text-left no-margin-or-padding">{I18n.t('conversations.group_message_to_all', { locale: this.props.initialData.locale })}</h4>
          <textarea ref={ message => this.message = message } style={{ border: '1px solid #203546' }} rows="2" className="message-body font-size-08" name="message[body]" id="message_body">{I18n.t('conversations.group_message_body', { locale: this.props.initialData.locale })}</textarea>
        </span>
      );
    }

  if ( this.props.initialData.participants.length > 0 ) {
      interested = (
        <div className="bebas">
          <span className="font-size-12">{this.props.initialData.participants.length} {I18n.t('microposts.interested_heading', { locale: this.props.initialData.locale })}</span>
          <div>
            <a href="javascript: false" onClick={this.openParticipantsModal} className="font-size-09">{I18n.t('microposts.show_all_participants', { locale: this.props.initialData.locale })}</a>
          </div>
        </div>
      );

      participantsModal = (
        <Modal isOpen={this.state.isParticipantsModalOpen} closeFunction={this.closeParticipantsModal}>
          <div style={{ marginTop:"2em" }} className="column full container">
            <h4 className="bebas text-left margin-top-10">{this.props.initialData.participants.length} {I18n.t('microposts.interested', { locale: this.props.initialData.locale })}</h4>
            <div className="text-left font-size-08 margin-bottom-20">{I18n.t('microposts.interested_desc', { locale: this.props.initialData.locale })}</div>
              <GroupParticipantList participants={this.props.initialData.participants} groupConversation={[]} selectable={false} locale={this.props.initialData.locale} />
          </div>
        </Modal>
      );
    }
    //}

  if ( this.props.initialData.micropost.title && this.props.initialData.micropost.title !== '') {
      var title = this.props.initialData.micropost.title;
    }
    else {
      var title = I18n.t('microposts.no_title', { locale: this.props.initialData.locale });
    }

  if ( this.props.initialData.profileUser.location ) {
      userLocation = (
        <span>
          <span className="max-width-icon location">{renderMapIcon()}</span>
          {this.props.initialData.profileUser.location}
        </span>
      );
    }

  if ( this.props.initialData.currentUser.id === this.props.initialData.micropost.user_id ) {
      if ( (this.props.initialData.all_conversations || {}).length > 1 && this.props.initialData.all_conversations[0] ) {
        headerBar = (
          <HeaderBarConversations participants={this.props.initialData.participants}
                                  groupConversation={groupConversation} privateConversations={privateConversations}
                                  hasPrivateConversation={this.props.initialData.currentUserHasPrivateConversation}
                                  conversationPath={this.state.conversationPath} currentUser={this.props.initialData.currentUser}
                                  allConversations={this.props.initialData.all_conversations}
                                  micropost={this.props.initialData.micropost} locale={this.props.initialData.locale}
                                  inGroup={this.props.initialData.currentUserInGroupConversation} />
        );
      }
      else {
        headerBar = (
          <div className="row">
            <div className="column full container dark-blue-bg bold header-bar" style={{ padding: "0 1em" }}>
              <div className="max-width-1260">
                <h1 className="left white bebas font-size-12 header-bar-title hidden-for-small">{this.props.initialData.micropost.title}</h1>
              </div>
            </div>
          </div>
        );
      }

      var editIcons = (
        <div className="right">
          <a href={micropostEditUrl} className="post-infos edit-post init-loading" style={{}}>{renderEditIcon()}</a>
          <button ref={ deletePost => this.deletePost = deletePost } type="button" onClick={this.handleDeletePost} disabled={this.state.deleting} className="post-infos delete-post" style={{ border: 'none', background: "transparent" }}>
            <span className="post-infos">{renderDeleteIcon()}</span>
          </button>
        </div>
      );
    }
    else {
      if ( hasModeratorPrivileges ) {
        var editIcons = (
          <div className="right">
            <a href={micropostEditUrl} className="post-infos edit-post init-loading" style={{}}>{renderEditIcon()}</a>
          </div>
        );
      }
      if ( !this.props.initialData.currentUserHasPrivateConversation ) {
        dateIntro = (
          <p className="font-size-08 margin-bottom-20">{I18n.t('conversations.date_intro', { locale: this.props.initialData.locale, user: this.props.initialData.micropost.user_infos.name })}</p>
        );
      }

      if ( this.props.initialData.currentUserInGroupConversation || this.props.initialData.currentUserHasPrivateConversation ) {
        headerBar = (
          <HeaderBarConversations participants={this.props.initialData.participants} groupConversation={groupConversation} privateConversations={privateConversations} hasPrivateConversation={this.props.initialData.currentUserHasPrivateConversation} conversationPath={this.state.conversationPath} currentUser={this.props.initialData.currentUser} allConversations={this.props.initialData.all_conversations} micropost={this.props.initialData.micropost} locale={this.props.initialData.locale} inGroup={this.props.initialData.currentUserInGroupConversation} />
        );
      }
      else {
        headerBar = (
          <div className="row">
            <div className="column full container dark-blue-bg bold header-bar" style={{ padding: "0 1em" }}>
              <div className="max-width-1260">
                <h1 className="left white bebas font-size-12 header-bar-title hidden-for-small">{this.props.initialData.micropost.title}</h1>
              </div>
            </div>
          </div>
        );
      }
    }

  function truncate(string, length) {
      if (string.length > length) {
        return string.substring(0, length) + '...';
      }
      else {
        return string;
      }
    }

  if ( this.props.initialData.micropost.user_infos.about ) {
      about = (
        <div className="font-size-08">{truncate(this.props.initialData.micropost.user_infos.about, 150)}</div>
      );
    }

  if ( this.props.initialData.micropost.dates.length > 0 ) {
      dateForm = (
        <span>
          <h2 className="bebas font-size-12 ellipsis-heading">{I18n.t('conversations.date_heading', { locale: this.props.initialData.locale })}</h2>
          {dateIntro}
          <form ref={ appointmentForm => this.appointmentForm = appointmentForm } className="updateAppointmentFollowersForm side-shadow" id="update_appointmentfollowers" action="/appointments" acceptCharset="UTF-8" method="post">
            <DateList checkable={this.state.checkable}
                      dateNodeArrayIndex={this.state.dateIndex}
                      nextDates={this.nextDates}
                      prevDates={this.prevDates}
                      errors={""}
                      isMicropostForm={false}
                      justSavedDates={this.state.justSavedDates}
                      conversationsCounter={this.props.conversationsCounter}
                      dates={this.state.initialDates}
                      locale={this.props.initialData.locale} />

            <input type="hidden" name="_method" value="put" />
            <input name="locale" type="hidden" value={ this.props.initialData.locale } />
            <input value={this.props.initialData.micropost.id} type="hidden" name="micropost[id]" id="micropost_id" />
            <input value={this.props.initialData.micropost.conversation_infos.id} type="hidden" name="conversation[id]" id="conversation_id" />

            {voteButton}
            {saveDatesButton}
          </form>
        </span>
      );
    }

  if ( this.props.initialData.micropost.dates.length === 0 ) {
			      var calendar = (
				<div className="post-infos calendar-icon bebas" style={{ position: 'relative', left: '2px', fontSize: "0.9em" }}><span style={{ marginRight: "0.3em" }}>{renderCalendarIcon()}</span> {I18n.t('microposts.no_fixed_date', { locale: this.props.initialData.locale })}</div>
			);
		    }
		else if ( this.props.initialData.micropost.dates.length === 1 ) {
			  var calendar = (
				<div className="post-infos calendar-icon bebas" style={{ position: 'relative', left: '2px', fontSize: "0.9em" }}><span style={{ marginRight: "0.3em" }}>{renderCalendarIcon()}</span> {this.props.initialData.micropost.dates[0].dayInWords}, {this.props.initialData.micropost.dates[0].dayInNumbers}</div>
			);
		}
		else if ( this.props.initialData.micropost.dates.length > 1 ) {
			  var calendar = (
				<div className="post-infos calendar-icon bebas" style={{ position: 'relative', left: '2px', fontSize: "0.9em" }}><span style={{ marginRight: "0.3em" }}>{renderCalendarIcon()}</span> {this.props.initialData.micropost.dates.length} {I18n.t('microposts.multiple_dates', { locale: this.props.initialData.locale })}</div>
			);
		}

  return (
      <div>
        {headerBar}

        {/*<SmallHeaderDisplayAd />*/}

        <div className="clearfix"></div>
        <div className="row max-width-1260" style={{ marginTop: '1rem' }}>
          <div className="micropost-show-table" style={{ marginBottom: '3rem' }}>
            {participantsModal}
            <div className="bebas blue section-slider"><a href="javascript: false" data-container="primary" style={{ display: "block" }} className="post-infos" onClick={this.slideContainer}>{I18n.t('microposts.primary_slider', { locale: this.props.initialData.locale })}<div className="right angle-up display-inline hidden">{renderAngleUp()}</div><div className="right angle-down display-inline hidden">{renderAngleDown()}</div></a></div>

            <section id="primary" className="column fourth-big">
              <h2 className="bebas no-margin-or-padding font-size-12 ellipsis-heading">{I18n.t('microposts.venue', { locale: this.props.initialData.locale })}</h2>
              <h4 className="bebas"><span className="max-width-icon">{renderMapIcon()}</span> in {this.props.initialData.micropost.location}</h4>
              {calendar}

              <h2 className="bebas margin-top-10 display-inline margin-bottom-0 font-size-12 ellipsis-heading">{this.props.initialData.micropost.title}</h2>

              <section ref={ micropostContent => this.micropostContent = micropostContent } className="micropost-content" dangerouslySetInnerHTML={{ __html: linkHashtags(this.props.initialData.localee, this.props.initialData.micropost.content) }}></section>
              {editIcons}
              <section className="show-view-author margin-top-25-big">
                <h2 className="bebas font-size-12 ellipsis-heading">{I18n.t('microposts.author', { locale: this.props.initialData.locale })}</h2>
                <UserProfileImage micropost={this.props.initialData.micropost} width={35} height={35} />
                <div style={{ marginLeft: "0.4em" }} className="bebas display-inline"><a href={ '/users/' + this.props.initialData.micropost.user_infos.id} className="init-loading"><div className="dark-blue">{getFirstName(this.props.initialData.micropost.user_infos.name)}</div></a></div>
                {about}
              </section>

              <section className="margin-top-25-big">
                {interested}
              </section>
            </section>

            <div className={'bebas blue section-slider ' + showDates}><a href="javascript: false" data-container="tertiary" style={{ display: "block" }} className="post-infos" onClick={this.slideContainer}>{I18n.t('microposts.tertiary_slider', { locale: this.props.initialData.locale })}<div className="right angle-up display-inline hidden">{renderAngleUp()}</div><div className="right angle-down display-inline hidden">{renderAngleDown()}</div></a></div>
            <section id="tertiary" className={'column fourth-big right-big ' + showDates}>
              {dateForm}
            </section>

            <div className="bebas blue section-slider"><a href="javascript: false" data-container="secondary" style={{ display: "block" }} className="post-infos" onClick={this.slideContainer}>{I18n.t('microposts.secondary_slider', { locale: this.props.initialData.locale })}<div className="right angle-up display-inline hidden">{renderAngleUp()}</div><div className="right angle-down display-inline hidden">{renderAngleDown()}</div></a></div>
            <section id="secondary" className={'column three-fourth-small show-view ' + postMargin}>
              <ConversationsShowView initialData={this.props.initialData} loadGroupInitially={this.state.params.group} loadPrivateInitially={this.state.params.private} groupConversation={groupConversation} privateConversations={privateConversations} />
            </section>

            <div className="clearfix"></div>
          </div>

          <div className="clearfix"></div>
      	</div>

        {/* <div className="margin-top-15" style={{ maxWidth: "45rem", margin: "0 auto" }}>
          <div className="column container full">
            <ResponsiveHorizontalDisplayAd />
          </div>
        </div> */}
      </div>
    );
}
}
