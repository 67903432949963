var CookieHelpers = {
    setCookie: function (c_name, value, expire) {
      var exdate = new Date();
      exdate.setDate(exdate.getDate()+expire);
      document.cookie = c_name+ "=" +escape(value) + ";path=/" + ((expire==null) ? "" : ";expires="+exdate.toGMTString());
    },

    getCookie: function (c_name) {
      if (document.cookie.length > 0 ) {
        var c_start = document.cookie.indexOf(c_name + "=");

        if(c_start !== -1) {
          c_start = c_start + c_name.length + 1;
          var c_end = document.cookie.indexOf(";",c_start);
          if( c_end == -1 ) {
            c_end = document.cookie.length;
          }
          return unescape( document.cookie.substring( c_start, c_end ) );
        } else {
          return undefined;
        }
      }

      return undefined;
    }
};

export default CookieHelpers;
