'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import BaseComponent from '../../libs/components/BaseComponent';
import I18n from 'i18n-js';

export default class Badge extends BaseComponent {
  constructor() {
    super();
  }

  render() {
  	var badge_title = 'users.badge_titles.' + this.props.name;
  	var badge_name = 'users.badge_names.' + this.props.name;
  	var grayImage = "";
  	var lock = "";
  	var tooltipTitle = I18n.t(badge_title, {locale: this.props.locale, current: this.props.currentState });
    var hideBadge = "";

  	// grayscale script from https://github.com/karlhorky/gray
  	if( this.props.gray == "gray " ) {
  		grayImage = "grayscale";
  		lock = (
  			<img src={require("../../../../assets/images/badges/lock.png")} rel="tooltip" title={ I18n.t(badge_title, {locale: this.props.locale, current: this.props.currentState }) } className={ "locked" } />
  		);

      hideBadge = "hidden not-achieved";
  	}
  	else {
    	tooltipTitle = tooltipTitle.replace("class='progress'", "class='progress hidden'");
    	tooltipTitle = tooltipTitle.replace("class='completed hidden'", "class='completed'")
  	}

  	return (
      <span ref={ badge => this.badge = badge } className={"badge " + hideBadge}>
      	{ lock }

      	<div className={ "sprite " + this.props.name + " " + this.props.gray } rel="tooltip" title={ tooltipTitle }></div>
      	<div title={ I18n.t(badge_title, {locale: this.props.locale, current: this.props.currentState }) } className={ this.props.gray + " bebas font-size-09 badgeName" }>{ I18n.t(badge_name, {locale: this.props.locale, current: this.props.currentState }) }</div>
    	</span>
    );
  }
}
