'use strict';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import Micropost from './Micropost';
import MicropostForm from './MicropostForm';
import DummyPost from './DummyPost';
import DummyPostAnimated from './DummyPostAnimated';
import Modal from '../Elements/Modal'
import renderLogo from '../svgIcons/logo';
import renderUndoIcon from '../svgIcons/undo';
import renderSearchIcon from '../svgIcons/search';
import BaseComponent from '../../libs/components/BaseComponent';
import SignUpAndLogin from '../Elements/SignUpAndLogin';
import FeedToggler from '../Elements/FeedToggler';
// import NativeFeedAd from '../Elements/Ads/NativeFeedAd';
import PWAInstallAd from '../Elements/Ads/PWAInstallAd';
import $ from 'jquery';
import I18n from 'i18n-js';
import Masonry from 'react-masonry-css';
import { getParams } from '../../helper/GetParams';

let breakpointColumnsObj;
let timeoutID = undefined;

export default class MicropostFeed extends BaseComponent {
	constructor(props) {
    super(props);
    let defaultCols = 1;
    const width = typeof props.screenWidth !== 'undefined' ? parseInt(props.screenWidth, 10) : -1;

    if (width >= 0 && width < 545) {
      defaultCols = 1;
    } else if (width >= 545 && width < 850) {
      defaultCols = 2
    } else if (width >= 850 && width < 1150) {
      defaultCols = 3;
    } else if (width >= 1150) {
      defaultCols = 4;
    }

		breakpointColumnsObj = {
      default: defaultCols,
			10000: 4,
      1150: 3,
      850: 2,
      545: 1
    };

    var q = "";
		if( typeof window !== 'undefined' ) {
			if( window.location.href.indexOf("q=") !== -1 ) {
				var params = getParams( window.location.href );
				q = params.q;
			}
		}

    /* if (props.overwriteBreakpoints) {
      breakpointColumnsObj = {
        default: 3,
        1100: 3,
        700: 2,
        500: 1
      };
    } */

    this.state = {
			microposts: props.microposts ? props.microposts : [],
			url: props.currentUrl ? props.currentUrl : "",
			isModalOpen: false,
			layoutComplete: props.microposts.length > 0 ? true : false,
      searchQuery: q,
    };
  }

	componentDidMount = () => {
		document.addEventListener("addToFeed", this.addToFeed, false);
		document.addEventListener("setConversationId", this.setConversationId, false);

		document.addEventListener("locationChanged", this.handleLocationChange, false);

		document.addEventListener("openModal", this.openModal, false);
    document.addEventListener("closeModal", this.closeModal, false);

    // this.setState({ layoutComplete: true });
	};

	componentWillUnmount = () => {
		window.removeEventListener("addToFeed", this.addToFeed, false);
		window.removeEventListener("setConversationId", this.setConversationId, false);

		window.removeEventListener("locationChanged", this.handleLocationChange, false);

	  window.removeEventListener("openModal", this.openModal, false);
	  window.removeEventListener("closeModal", this.closeModal, false);

		// Clear setIntervall, otherwise present page polls forever when using turbolinks
		window.clearInterval(timeoutID);
	};

	setConversationId = (e) => {
		var data = e.detail.data;
		var microposts = this.state.microposts;

		microposts = microposts.map( function( micropost ) {
			if( micropost.id == data.micropost_id ) {
				micropost.conversation_infos.id = data.id;
			}

      return micropost
		});

		this.setState({ microposts: microposts });
	};

	handleLocationChange = (e) => {
    var url = this.state.url.split("?")[0];

    var pushStatePath = url.replace(window.location.origin, '');
    window.history.pushState({}, null, pushStatePath);

    // window.location.reload(false);
	};

	addToFeed = (e) => {
		var newMicropost = e.detail.data.micropost;
		var microposts = this.state.microposts;
		newMicropost["dates"] = newMicropost.dates.map( function( date ) {
      date.currentUserIsFollowing = true;
      return date
		});

		newMicropost.type = "Micropost";

		var newMicroposts = [ newMicropost ].concat( microposts );
		this.setState({ microposts: newMicroposts });
	};

  removeFromFeed = (nodeId) => {
    var oldState = this.state.microposts;
    var newState = oldState.filter(function(micropost){
      return micropost.id != nodeId
    });

    this.setState({ microposts: newState });
  };

	openModal = (e) => {
    e.preventDefault();
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

	render() {
		var hashtag = "";
		var signupOrDefault = "";
		var showLoader = "";
		var showFeed = " hidden";
		var todaysUrl = "#";
		var tomorrowsUrl = "#";
		var weekendsUrl = "#";
		var todayActive = "";
		var tomorrowActive = "";
		var weekendActive = "";
    var toggler = "";
		var structuredData = {
			'@context': 'http://schema.org',
			'@type': 'ItemList',
			itemListElement: [],
		};
		// var filters = "";

    if (this.props.hideToggler !== true) {
      toggler = (
        <FeedToggler currentPage="activities" locale={this.props.locale} isSearch={ this.props.isSearch } currentUser={ this.props.currentUser } />
      );
    }
		//if( typeof window !== 'undefined' ) {
		var currentUrl = this.state.url;
		var separator = (currentUrl.indexOf("?") === -1) ? "?" : "&";
		if(currentUrl.indexOf("?d=") !== -1) {
			separator = "?";
		}

		if( currentUrl.indexOf("today") > -1 ) {
			todayActive = "bold";
			currentUrl = currentUrl.split(separator + "d=")[0];
			todaysUrl = currentUrl;
			tomorrowsUrl = currentUrl + separator + "d=tomorrow";
			weekendsUrl = currentUrl + separator + "d=weekend";
		}
		else if ( currentUrl.indexOf("tomorrow") > -1 ) {
			tomorrowActive = "bold";
			currentUrl = currentUrl.split(separator + "d=")[0];
			todaysUrl = currentUrl + separator + "d=today";
			tomorrowsUrl = currentUrl;
			weekendsUrl = currentUrl + separator + "d=weekend";
		}
		else if ( currentUrl.indexOf("weekend") > -1 ) {
			weekendActive = "bold";
			currentUrl = currentUrl.split(separator + "d=")[0];
			todaysUrl = currentUrl + separator + "d=today";
			tomorrowsUrl = currentUrl + separator + "d=tomorrow";
			weekendsUrl = currentUrl;
		}
		else {
			currentUrl = currentUrl.split(separator + "d=")[0];
			todaysUrl = currentUrl + separator + "d=today";
			tomorrowsUrl = currentUrl + separator + "d=tomorrow";
			weekendsUrl = currentUrl + separator + "d=weekend";
		}
		//}

		/* if( this.props.filters ) {
			filters = (
				<div className="full margin-auto filter text-right max-width-1260" style={{position: "relative", top: "-15px", padding: "0 0.5em"}}>
					<div className="display-inline bebas" style={{marginRight: "6px"}}>Filter:</div>
					<div className="display-inline bebas"><a href={todaysUrl} className={ todayActive }>{ I18n.t('microposts.filters.today', {locale: this.props.locale}) }</a> | </div>
					<div className="display-inline bebas"><a href={tomorrowsUrl} className={ tomorrowActive }>{ I18n.t('microposts.filters.tomorrow', {locale: this.props.locale}) }</a> | </div>
					<div className="display-inline bebas"><a href={weekendsUrl} className={ weekendActive }>{ I18n.t('microposts.filters.weekend', {locale: this.props.locale}) }</a></div>
				</div>
			);
		} */

		// Replace "no title" with default title
		if( this.state.microposts ) {
	  var adIndex = 1;
	  var adsShown = 0;
			var structuredDataPosition = 1;

			var micropostNodes = this.state.microposts.map( function ( micropost ) {
				if(!micropost.title) {
					micropost.title = I18n.t('microposts.no_title', {locale: this.props.locale});
				}

        // if ([3, 8, 20].indexOf(adIndex + 1) !== -1) {
		if ((adIndex + 1) % 7 === 0) {
          adIndex++;
					var installAd = '';

					if(this.props.affiliates && this.props.affiliates[adsShown]) {
						// console.log(this.props.affiliates)
						iinstallAd = <FriendlyAd key={ Math.random() } ad={ this.state.affiliates[adsShown] } />;
						adsShown++;
						if ((adsShown + 1) > this.state.affiliates.length) adsShown = 0;
					  }

					structuredData.itemListElement.push({
						'@type': 'ListItem',
						position: structuredDataPosition,
						url: `https://buddyme.me/microposts/${micropost.seo_url}`,
						name: micropost.title,
					});
					structuredDataPosition++;

          return (
						<span>
							{ installAd }
							<Micropost micropost={ micropost }
	  		  								 locale={ this.props.locale }
	  		                   key={micropost.id}
	  											 standalone={ this.props.standalone }
	  		                   currentUser={ this.props.currentUser }
	  		                   contactRequestForm={ this.props.contactRequestForm }
	  		                   removeFromFeed={ this.removeFromFeed } />
					  </span>
          );
        } else {
          adIndex++;

					structuredData.itemListElement.push({
						'@type': 'ListItem',
						position: structuredDataPosition,
						url: `https://buddyme.me/microposts/${micropost.seo_url}`,
						name: micropost.title,
					});
					structuredDataPosition++;

          return <Micropost micropost={ micropost }
  			  									locale={ this.props.locale }
  			                    key={micropost.id}
  													standalone={ this.props.standalone }
  			                    currentUser={ this.props.currentUser }
  			                    contactRequestForm={ this.props.contactRequestForm }
  			                    removeFromFeed={ this.removeFromFeed } />
        }
			}.bind(this));
		}

		if( this.props.currentUser.guest ) {
      var micropostFormOrSignup = (
        <span>
          <SignUpAndLogin  standalone={ true }
	                         loginForm={ this.props.loginForm }
	                         userForm={ this.props.userForm }
	                         loginPath={ this.props.loginPath }
													 locale={ this.props.locale } />
        </span>
      );
    }
    else {
      var micropostFormOrSignup = (
        <span>
          <MicropostForm latitude={ this.props.latitude }
                         longitude={ this.props.longitude }
                         location={ this.props.location }
                         locale={ this.props.locale }
                         hashtag={ this.props.hashtag }
                         micropostForm={ this.props.micropostForm }
                         currentUser={ this.props.currentUser } />
        </span>
      );
    }

		if( this.props.hashtag ) {
			var hashtag = "#" + this.props.hashtag;
		}

		if( this.state.layoutComplete ) {
			showFeed = "";
			showLoader = " hidden";
		}

		// There are microposts to show, render them
		if( micropostNodes.length > 0 ){
      var search = '';

      if (this.props.hideSearch !== true && false) {
        search = (
          <div>
            <div className="column full no-margin-or-padding">
              <div id="search-container">
                <form action="/search" acceptCharset="UTF-8" method="get">
                  <h5 style={{marginLeft: "4px"}}>{ I18n.t('users.search_for_activities', {locale: this.props.locale}) }</h5>
                  <span className="icon" dangerouslySetInnerHTML={ renderSearchIcon() }></span>
                  <input ref={ searchBox => this.searchBox = searchBox } type="text" name="q" id="search" style={{fontWeight: "normal"}} placeholder={ I18n.t('explorepage.search_placeholder', {locale: this.props.locale}) } value={this.state.searchQuery} />
                  <input type="submit" className="bebas submit-search" value={ I18n.t('explorepage.search', {locale: this.props.locale}) } />
                  <input name="locale" type="hidden" value={ this.props.locale } />
                  <input name="utf8" type="hidden" value="✓" />
                </form>
              </div>
            </div>

            <div className="clearfix"></div>
          </div>
        );
      }

			var actualFeed = '';
			if (this.props.disableMasonry !== true) {
				actualFeed = (
					<div>
						<div ref={ loader => this.loader = loader } className={ "no-margin-or-padding container" + showLoader}>
							{/* <DummyPostAnimated locale={ this.props.locale } />
							<DummyPostAnimated locale={ this.props.locale } />
							<DummyPostAnimated locale={ this.props.locale } />
							<DummyPostAnimated locale={ this.props.locale } /> */}
							<div className="clearfix"></div>
						</div>

						<Masonry
							breakpointCols={breakpointColumnsObj}
							className={ "micropost-list" + showFeed }
							columnClassName="">

							{ micropostNodes }
						</Masonry>
					</div>
				);
			} else {
				actualFeed = (
					<div className="micropost-list">
						{ micropostNodes }
					</div>
				);
			}

		  return (
		  	<div>
					<script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(structuredData)}}></script>
					<Modal isOpen={this.state.isModalOpen} closeFunction={ this.closeModal }>
		        { micropostFormOrSignup }
		      </Modal>

          { toggler }
					{/* filters */}
          { search }

					{ actualFeed }
			  </div>
		  );
		}
		// No posts here, render dummy posts and a nice notice
		else {
			return (
				<div className="center">
          <div>
  					<Modal isOpen={this.state.isModalOpen} closeFunction={ this.closeModal }>
  		        { micropostFormOrSignup }
  		      </Modal>

  					{/* filters */}

  					<p className="bebas dark-blue font-size-14 margin-top-25">{ I18n.t('microposts.all_beginnings', {locale: this.props.locale}) }</p>
  					<p className="margin-bottom-20">{ I18n.t('microposts.click_plus', {locale: this.props.locale}) } <a href="javascript: false"><span className="bebas bold white red-bg" onClick={ this.openModal } style={ {position: "relative", top: "4px", display: "inline-block", width: "30px", height: "30px", fontSize: "34px", lineHeight: "1em"} }>+</span></a> { I18n.t('microposts.and_create', {locale: this.props.locale}) } <br/>{ I18n.t('microposts.looking_forward', {locale: this.props.locale}) } <span className="display-inline bebas blue">{ hashtag }</span></p>
  					<div ref={ feedContainer => this.feedContainer = feedContainer } className="max-width-1260" key={ 0 }>
  						<div ref={ loader => this.loader = loader } className={ "container" + showLoader}>
  							{/* <DummyPostAnimated locale={ this.props.locale } />
  							<DummyPostAnimated locale={ this.props.locale } />
  							<DummyPostAnimated locale={ this.props.locale } />
							<DummyPostAnimated locale={ this.props.locale } /> */}
                <div className="clearfix"></div>
  						</div>

  						<Masonry
  						  breakpointCols={breakpointColumnsObj}
  						  className={ "micropost-list" + showFeed }
  						  columnClassName="feed-item">
  							{/* <DummyPost locale={ this.props.locale } />
  							<DummyPost locale={ this.props.locale } />
  							<DummyPost locale={ this.props.locale } />
  							<DummyPost locale={ this.props.locale } />
							<DummyPost locale={ this.props.locale } /> */}
  						</Masonry>

  			    </div>
  					<div className="row">
  		        <div className="column full container">
  							<div className="margin-top-25 gray margin-bottom-20 center">{ renderLogo() }</div>
  						</div>
  					</div>
          </div>
			  </div>
		  );
		}
	}
}
