var getParams = function( url ) {
  // getting parameters makes no sense on the server (pre-rendering)
  if( typeof window !== 'undefined' ) {
    // facebook login adds '_=_' to url params. Remove it
    // if present.
    if (window.location.hash == '#_=_'){
      History.replaceState
          ? History.replaceState(null, null, window.location.href.split('#')[0])
          : window.location.hash = '';
    }

    var vars = {};
    if( !url ) {
      var toParse = window.location.href;
    }
    else {
      var toParse = url;
    }
    var parts = toParse.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
      vars[key] = value;
    });

    if( !vars.distance ) {
      vars.distance = 6000;
    }

    return vars;
  }
  else {
    return []
  }
};

var getHashtagFromUrl = function() {
	if( typeof window !== 'undefined' ) {
	  var hashtag_path_regexp = /^(\/hashtags\/)([A-Za-z0-9-_]+)/i;
	  var hashtag_path_parts = window.location.pathname.match(hashtag_path_regexp);

	  if( hashtag_path_parts) {
	    return hashtag_path_parts[2];
	  }
	  else {
	    return ''
	  }
	}
};

export { getParams, getHashtagFromUrl };
