import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';

var renderCaretDown = function() {
	return(
    <svg width="1792" height="1792" viewBox="0 0 1792 1792" ><path d="M1408 704q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45z" fill="#fff"/></svg>
	)
}

export default renderCaretDown;
