'use strict';
import React from 'react';
import BaseComponent from '../../libs/components/BaseComponent';
import render from 'preact-render-to-string';
import { h } from 'preact';
import HeaderBar from '../Elements/HeaderBar';
import UserFeed from './UserFeed';
import Modal from '../Elements/Modal';
import PremiumTeaser from '../Elements/PremiumTeaser';
import getFirstName from '../../helper/GetFirstName';
import renderLogo from '../svgIcons/logo';
import I18n from 'i18n-js';

export default class UserVisitorsView extends BaseComponent {
  constructor(props) {
    super(props);
  }

  render() {
    var headerBar = '';
    var content = "";
    var now = new Date();
    now = new Date(now.getTime() + (now.getTimezoneOffset() * 60000));
    var premiumDate = new Date(this.props.initialData.currentUser.user_infos.premium);

    if (this.props.hideHeader !== true) {
      /* headerBar = <HeaderBar latitude={ this.props.initialData.lat } longitude={ this.props.initialData.lon }
                 location={ this.props.initialData.location } locale={ this.props.initialData.locale }
                 hashtag={ this.props.initialData.hashtag } hideLocation={ true }
                 micropostForm={ this.props.initialData.micropostForm }
                 loginForm={ this.props.initialData.loginForm } userForm={ this.props.initialData.userForm }
                 loginPath={ this.props.initialData.loginPath } currentUser={ this.props.initialData.currentUser } /> */
    }

    if (premiumDate >= now) {
      if (this.props.total > 0) {
        // user is premium and has visitors
        content = (
          <div>
            <h2 className="bebas text-left margin-bottom-10">{ I18n.t('users.visitors.heading', {locale: this.props.initialData.locale}) }</h2>
            <UserFeed users={ this.props.initialData.users } micropostForm={ this.props.initialData.micropostForm }
                           latitude={ this.props.initialData.lat } longitude={ this.props.initialData.lon } currentUrl={ this.props.initialData.currentUrl }
                           location={ this.props.initialData.location } lastPage={ this.props.initialData.lastPage } filters={ false }
                           locale={ this.props.initialData.locale } currentUser={ this.props.initialData.currentUser } loginForm={ this.props.initialData.loginForm }
                           hideSearch={ true } hideToggler={ true } hideNotice={ true } userForm={ this.props.initialData.userForm } loginPath={ this.props.initialData.loginPath }
                           contactRequestForm={ this.props.initialData.contactRequestForm } />
          </div>
        );
      } else {
        // empty view
        content = (
          <div>
            <h2 className="bebas text-left margin-bottom-10">{ I18n.t('users.visitors.heading', {locale: this.props.initialData.locale}) }</h2>
            <div className="center">
              { I18n.t('users.visitors.empty_desc', {locale: this.props.initialData.locale}) }
            </div>

            <div className="row">
  		        <div className="column full container">
  							<div className="margin-top-25 gray margin-bottom-20 center">{ renderLogo() }</div>
  						</div>
  					</div>
          </div>
        );
      }
    } else {
      // user is not premium
      var heading = "";

      if (this.props.total > 1) {
        heading = (
          <h2 className="bebas text-left margin-bottom-10">{ I18n.t('users.visitors.not_premium_heading', {locale: this.props.initialData.locale, counter: this.props.total}) }</h2>
        );
      }

      content = (
        <div>
          { heading }
          <PremiumTeaser locale={ this.props.initialData.locale } highlight="visitors" />
        </div>
      );
    }

    return (
      <div>
        { headerBar }

        <div className="row">
          <div className="column full container margin-top-15">
            { content }
          </div>
        </div>

      </div>
    );
  }
}
