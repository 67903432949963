import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';

var renderProfileIcon = function() {
	return(
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21">
		  <path fill="none" stroke="#1e3646" strokeWidth="1.2" d="M9.04761905 10.8571429C6.04990476 10.8571429 3.61904762 8.42628571 3.61904762 5.42857143 3.61904762 2.43085714 6.04990476 0 9.04761905 0 12.0453333 0 14.4761905 2.43085714 14.4761905 5.42857143 14.4761905 8.42628571 12.0453333 10.8571429 9.04761905 10.8571429zM18.0952381 19L18.0952381 16.723299C18.0952381 14.9897702 16.6005874 13.5714286 14.773792 13.5714286L3.32144605 13.5714286C1.49465072 13.5714286 0 14.9897702 0 16.723299L0 19 18.0952381 19z" transform="translate(1 1)"/>
		</svg>
  )
}

export default renderProfileIcon;
