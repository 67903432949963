'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import BaseComponent from '../../libs/components/BaseComponent';
import renderHeartIcon from '../svgIcons/heart';
import renderFullHeartIcon from '../svgIcons/heartFull';
import $ from 'jquery';

export default class UpvoteButton extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      upvotes: typeof props.votes !== 'undefined' ? props.votes.count : 0,
      voted: typeof props.votes !== 'undefined' ? props.votes.voted : false,
    };
  }

  handleUpvote = (e) => {
    e.preventDefault();

    if( this.state.upvotes === 0 ) {
      this.vote();
      this.increaseVotes();
    }
    else {
      if (this.state.voted === true) {
        this.removeVote();
        this.decreaseVotes();
      } else {
        this.vote();
        this.increaseVotes();
      }

    }
  };

  increaseVotes = () => {
    var currentVotes = this.state.upvotes;
    this.setState({upvotes: currentVotes+1, voted: true});
    $(this.upvoteButton).addClass('liked');
  };

  decreaseVotes = () => {
    var currentVotes = this.state.upvotes;
    this.setState({upvotes: currentVotes-1, voted: true});
    $(this.upvoteButton).removeClass('liked');
    $(this.upvoteButton).removeClass('liked0');
  };

  vote = () => {
    var that = this;

    $.ajax({
      url: '/microposts/' + that.props.micropostId + '/upvote',
      type: 'POST',
      processData: false,
      contentType: false
    })
    .done(function(data, status, request) {
      // hacky way to make sure the request is not redirected to /login
      if (data === '' && status === 'success') {
        that.state.voted = true;
      } else {
        that.state.voted = false;
        that.removeVote();
        that.decreaseVotes();
      }
    })
    .fail(function( xhr, status, err ) {
      // console.log("vote FAILED!")
      console.log(xhr)
    });
  };

  removeVote = () => {
    var that = this;

    $.ajax({
      url: '/microposts/' + that.props.micropostId + '/removeVote',
      type: 'POST',
      processData: false,
      contentType: false
    })
    .done(function(data) {
      that.state.voted = false;
      // console.log("Vote DONE!")
    })
    .fail(function( xhr, status, err ) {
      // console.log("vote FAILED!")
      console.log(xhr)
    });
  };

  render() {
    var liked = this.state.voted ? " liked0 " : "";

    return (
      <div className="heart-container">
        <a ref={ upvoteButton => this.upvoteButton = upvoteButton } href="javascript: false" onClick={ this.handleUpvote } className={ 'like-button' + liked }>
          <span className='like-icon'>
            <div className='heart-animation-1'></div>
            <div className='heart-animation-2'></div>
          </span>
          {/* <span style={{ fontSize: '1.1rem', marginLeft: '0.1rem' }} ref={ upvoteCounter => this.upvoteCounter = upvoteCounter }>{ this.state.upvotes }</span> */}
        </a>
      </div>
    )
  }
}
