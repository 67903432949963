import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';

var renderAngleUp = function() {
	return(
    <svg width="1792" height="1792" viewBox="0 0 1792 1792" ><path d="M1395 1184q0 13-10 23l-50 50q-10 10-23 10t-23-10l-393-393-393 393q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l466-466q10-10 23-10t23 10l466 466q10 10 10 23z"/></svg>
  )
}

export default renderAngleUp;
