var getFirstName = function( username ) {
    var nameArray = username ? username.split(' ') : [];
    var firstName= "";

    if(username.toLowerCase().indexOf("gelöscht") === -1 && username.toLowerCase().indexOf("deleted") === -1) {
      for (var i = 0; i < nameArray.length; i++) {
        if( nameArray[i].length > 0 && nameArray[i].indexOf(" ") == -1 ) {
          firstName = nameArray[i];
          break;
        }
      };
    } else {
      firstName = username;
    }

    return firstName
};

export default getFirstName;
