'use strict';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import MicropostFeedItem from './MicropostFeedItem';
import UserFeedItem from './UserFeedItem';
import MicropostForm from '../Microposts/MicropostForm';
import DummyPost from '../Microposts/DummyPost';
import DummyPostAnimated from '../Microposts/DummyPostAnimated';
import Modal from '../Elements/Modal'
import renderLogo from '../svgIcons/logo';
import renderUndoIcon from '../svgIcons/undo';
import renderSearchIcon from '../svgIcons/search';
import BaseComponent from '../../libs/components/BaseComponent';
import SignUpAndLogin from '../Elements/SignUpAndLogin';
import FeedToggler from '../Elements/FeedToggler';
// import NativeFeedAd from '../Elements/Ads/NativeFeedAd';
import FriendlyAd from '../Elements/Ads/FriendlyAd';
import $ from 'jquery';
import I18n from 'i18n-js';
import Masonry from 'react-masonry-css';
import { getParams } from '../../helper/GetParams';
const queryString = require('query-string');

let breakpointColumnsObj;
let timeoutID = undefined;

export default class Feed extends BaseComponent {
	constructor(props) {
    super(props);
    let defaultCols = 1;
    const width = typeof props.screenWidth !== 'undefined' ? parseInt(props.screenWidth, 10) : -1;

    if (width >= 0 && width < 545) {
      defaultCols = 1;
    } else if (width >= 545 && width < 850) {
      defaultCols = 2
    } else if (width >= 850 && width < 1150) {
      defaultCols = 3;
    } else if (width >= 1150) {
      defaultCols = 4;
    }

    breakpointColumnsObj = {
      default: defaultCols,
			10000: 4,
      1150: 3,
      850: 2,
      545: 1
    };

    var q = "";
		if( typeof window !== 'undefined' ) {
			if( window.location.href.indexOf("q=") !== -1 ) {
				var params = getParams( window.location.href );
				q = params.q;
			}
		}

    this.state = {
			microposts: props.microposts ? props.microposts : [],
			affiliates: props.affiliates ? props.affiliates : [],
			url: props.currentUrl ? props.currentUrl : "",
			isModalOpen: false,
			layoutComplete: props.microposts.length > 0 ? true : false,
      searchQuery: q,
			currentPage: props.currentPage,
			totalPages: props.totalPages,
			endNotice: false,
			queryString: typeof window !== 'undefined' ? queryString.parse(location.search) : false,
    };
  }

	componentDidMount = () => {

		document.addEventListener("addToFeed", this.addToFeed, false);
		document.addEventListener("setConversationId", this.setConversationId, false);

		document.addEventListener("locationChanged", this.handleLocationChange, false);

		document.addEventListener("openModal", this.openModal, false);
    document.addEventListener("closeModal", this.closeModal, false);

		this.initInfiniteScrolling();
	};

	initInfiniteScrolling = () => {
		if (typeof window !== 'undefined' && this.state.currentPage && this.state.totalPages) {
			var that = this;

			document.body.classList.add('refreshing');

			if ($('.pagination').length === 0) {
				$('#footer').before('<div class="column full pagination"></div>');
			}

			$(document.body).addClass('enable-infinite-scrolling');

			var snapbackCache = this.snapbackCache({
			 	bodySelector: "body.enable-infinite-scrolling"
			});

			snapbackCache.loadFromCache();

			$(document).on("click", ".micropost-list a", function (e) {
				// console.log('check if pages gets cached!!!!!!!');
				snapbackCache.cachePage();
		 });

			$(window).scroll(function() {
				if (window.pagination_loading) {
					return;
				}

				if ($(window).scrollTop() > $(document).height() - $(window).height() - 200) {
					if (that.state.endNotice === true) {
						return;
					} else if (that.state.currentPage === that.state.totalPages) {
						if (document.body.classList.contains('guest')) return;
						$('.pagination').empty();
						$('.pagination').prepend(I18n.t('explorepage.no_more_loading', {locale: that.props.locale}));
						that.setState({ endNotice: true });

						return;
					}

					var baseUrl = window.location.origin + window.location.pathname;
					var newQueryString = queryString.parse(location.search);

					if (typeof that.state.currentPage === 'undefined') {
						newQueryString.page = 2;
					} else {
						newQueryString.page = parseInt(that.state.currentPage, 10) + 1;
					}

					var newQueryStringified = queryString.stringify(newQueryString);
					var joinChar = newQueryStringified.indexOf('?') === -1 && baseUrl.indexOf('?') === -1 ? '?' : '';

					var url = baseUrl + joinChar + newQueryStringified;

					window.pagination_loading = true;

					$('.pagination').text('');
					$('.pagination').empty();
					$('.pagination').append('<div class="lds-heart"><div></div></div>');
					$('.pagination').append(`<div class="dark-blue center">${ I18n.t('explorepage.more_loading', {locale: that.props.locale}) }</div>`);

					// track page view
					// console.log('trigger');
					if (window.location.href.indexOf('buddyme.me') > -1) {
						_paq.push(['setCustomUrl', url]);
						// _paq.push(['setDocumentTitle', 'My New Title']);
						_paq.push(['trackPageView']);
					}

					return $.getScript(url).always(function(script, textStatus) {
						var parsed = JSON.parse(script);
						// window.history.replaceState({}, null, url);
						// window.location.hash = parsed.currentPage;

						if (parsed.currentPage === parsed.totalPages) {
							window.pagination_loading = false;
							$('.pagination').text('');
							$('.pagination').empty();
							if (document.body.classList.contains('guest')) return;
							$('.pagination').prepend(I18n.t('explorepage.no_more_loading', {locale: that.props.locale}));
							that.setState({ endNotice: true });
						}

						var mixedResults = parsed.mixedResults;


						$('.pagination').text('');
						// console.log('set new state!!!!!!!', that.state.microposts);

						that.setState({
							currentPage: parsed.currentPage,
							microposts: that.state.microposts.concat(mixedResults),
							affiliates: parsed.affiliates,
						});

						return window.pagination_loading = false;
					});
				}
			});
		}
	}

	snapbackCache = function(options) {
		// https://github.com/highrisehq/snapback_cache
		var options = options || {}
		var that = this;

	  if (typeof window === 'undefined') {
	    return;
	  }

	  var SessionStorageHash = (function() {
	    var set = function(namespace, key, item){
	      var storageHash = sessionStorage.getItem(namespace);
	      if (!storageHash) {
	        storageHash = {}
	      } else {
	        storageHash = JSON.parse(storageHash)
	      }

	      if (item) {
	        storageHash[key] = JSON.stringify(item)
	      } else {
	        delete storageHash[key]
	      }

	      sessionStorage.setItem(namespace, JSON.stringify(storageHash))
	    }

	    var get = function(namespace, key, item){
	      var storageHash = sessionStorage.getItem(namespace)

	      if(storageHash){
	        storageHash = JSON.parse(storageHash)
	        if(storageHash[key]){
	          return JSON.parse(storageHash[key])
	        }
	      }

	      return null
	    }

	    return {
	      set: set,
	      get: get
	    }
	  })()

	  var enabled = true

	  var disable = function() {
	    enabled = false
	  }

	  var enable = function () {
	    enabled = true
	  }

	  var supported = function(){
	    return !!(sessionStorage && history && enabled)
	  }

	  var setItem = function(url, value){
	    if(value){
	      // only keep 10 things cached
	      trimStorage()
	    }
	    SessionStorageHash.set("pageCache", url, value)
	  }

	  var getItem = function(url){
	    return SessionStorageHash.get("pageCache", url)
	  }

	  var removeItem = function(url){
	    setItem(url, null)
	  }

	  var disableAutofocusIfReplacingCachedPage = function(){
	    if(typeof options.removeAutofocus === "function"){
	      if(willUseCacheOnThisPage()){
	        options.removeAutofocus()
	      }
	    }
	  }

	  var cachePage = function(filterOut, callbackFunction){
	    if (typeof filterOut === 'function') {
	      callbackFunction = filterOut
	      filterOut = null
	    }

	    if (!supported()){
	      if(callbackFunction){
	        callbackFunction()
	      }
	      return;
	    }

	    // get jQuery animations to finish
	    $(document).finish()
	    if (typeof options.wait === "function")
	      options.finish()

			// THIS setTimeout doesn't get called. Don't know why
	    // Give transitions/animations a chance to finish
	    // setTimeout(() => {
				// console.log('here2!!!!!!!!!!!!!!');
	      if (typeof options.removeAutofocus === "function")
	        options.removeAutofocus()

				// Do not cache HTML. Just cache state
	      /* var $cachedBody = $(options.bodySelector)
	      if (filterOut) {
	        $cachedBody = $cachedBody.clone().find(filterOut).replaceWith("").end()
	      } */

	      var cachedPage = {
	        body: '', // $cachedBody.html(),
	        title: document.title,
	        positionY: window.pageYOffset,
	        positionX: window.pageXOffset,
	        cachedAt: new Date().getTime(),
					microposts: that.state.microposts,
					currentPage: that.state.currentPage,
					totalPages: that.state.totalPages,
					endNotice: that.state.endNotice,
	      }

	      // help to setup the next page of infinite scrolling
	      if (typeof options.nextPageOffset === "function")
	        cachedPage.nextPageOffset = options.nextPageOffset()

	      setItem(document.location.href, cachedPage)

	      $(options.bodySelector).trigger("snapback-cache:cached", cachedPage)

	      if(callbackFunction){
	        callbackFunction()
	      }
	    // }, 500)
	  }

	  var loadFromCache = function(noCacheCallback){
	    // Check if there is a cache and if its less than 15 minutes old
	    if(willUseCacheOnThisPage()){
	      var cachedPage = getItem(document.location.href)

	      // replace the content and scroll
	      // $(options.bodySelector).html(cachedPage.body)

				that.setState({
					microposts: cachedPage.microposts,
					currentPage: cachedPage.currentPage,
					totalPages: cachedPage.totalPages,
					endNotice: cachedPage.endNotice,
				});

	      // try to make sure autofocus events don't run.
	      if (typeof options.removeAutofocus === "function")
	        options.removeAutofocus()

	      // IE 10+ needs a delay to stop the autofocus during dom load
	      setTimeout(() => {
	        window.scrollTo(cachedPage.positionX, cachedPage.positionY);
	      }, 1);

	      // pop the cache
	      removeItem(document.location.href)

	      $(options.bodySelector).trigger("snapback-cache:loaded", cachedPage)

	      // refresh any obsolete recordings in the activity feed
	      var dirties = getDirties()
	      if(dirties){
	        if (typeof options.refreshItems === "function")
	          options.refreshItems(dirties)

	        clearDirty()
	      }

	      return false;
	    }
	    else{
	      if(noCacheCallback){
	        noCacheCallback()
	      }
	      else{
	        return
	      }
	    }
	  }

	  var clearDirty = function() {
	    sessionStorage.removeItem("pageCache-dirty")
	  }

	  var getDirties = function() {
	    var raw = sessionStorage.getItem("pageCache-dirty")
	    if (raw) {
	      var json = JSON.parse(raw)
	      return jQuery.map(json, function(value, key){
	        return key
	      })
	    } else {
	      return null
	    }
	  }

	  var markDirty = function(item) {
	    SessionStorageHash.set("pageCache-dirty", item, true)
	  }

	  var trimStorage = function(){
	    var storageHash = sessionStorage.getItem("pageCache");
	    if(storageHash){
	      storageHash = JSON.parse(storageHash);

	      var tuples = [];

	      for (var key in storageHash) {
	        tuples.push([key, storageHash[key]])
	      }
	      // if storage is bigger than size, sort them, and remove oldest
	      if(tuples.length >= 10){
	        tuples.sort(function(a, b) {
	            a = a[1].cachedAt;
	            b = b[1].cachedAt;
	            return b < a ? -1 : (b > a ? 1 : 0);
	        });

	        for (var i = 0; i < (tuples.length + 1 - 10); i++) {
	          var key = tuples[i][0];
	          delete storageHash[key];
	        }

	        sessionStorage.setItem(namespace, JSON.stringify(storageHash));
	      }
	    }
	  }

	  var willUseCacheOnThisPage = function(){
	    if (!supported()){
	      return false;
	    }

	    var cachedPage = getItem(document.location.href)

	    // Check if there is a cache and if its less than 15 minutes old
	    if(cachedPage && cachedPage.cachedAt > (new Date().getTime()-900000)){
	      return true;
	    }
	    else{
	      return false;
	    }
	  }

	  $(document).ready(function(){
	    disableAutofocusIfReplacingCachedPage()
	  });

		// Better call loadFromCache() from componentDidMount
	  /* window.onload = function(e) {
	    loadFromCache()
	  }; */

	  return {
	    enable: enable,
	    disable: disable,
	    remove: removeItem,
	    loadFromCache: loadFromCache,
	    cachePage: cachePage,
	    markDirty: markDirty,
	    willUseCacheOnThisPage: willUseCacheOnThisPage
	  }
	};

	componentWillUnmount = () => {
		window.removeEventListener("addToFeed", this.addToFeed, false);
		window.removeEventListener("setConversationId", this.setConversationId, false);

		window.removeEventListener("locationChanged", this.handleLocationChange, false);

	  window.removeEventListener("openModal", this.openModal, false);
	  window.removeEventListener("closeModal", this.closeModal, false);

		// Clear setIntervall, otherwise present page polls forever when using turbolinks
		window.clearInterval(timeoutID);
	};

	setConversationId = (e) => {
		var data = e.detail.data;
		var microposts = this.state.microposts;

		microposts = microposts.map( function( micropost ) {
			if( micropost.id == data.micropost_id ) {
				micropost.conversation_infos.id = data.id;
			}

      return micropost
		});

		this.setState({ microposts: microposts });
	};

	handleLocationChange = (e) => {
    var url = this.state.url.split("?")[0];

    var pushStatePath = url.replace(window.location.origin, '');
    window.history.pushState({}, null, pushStatePath);

    // window.location.reload(false);
	};

	addToFeed = (e) => {
		// console.log('hello from addToFeed', JSON.parse(e.detail.data).micropost);
		var newMicropost = JSON.parse(e.detail.data).micropost;
		var microposts = this.state.microposts;
		newMicropost["dates"] = newMicropost.dates.map( function( date ) {
      date.currentUserIsFollowing = true;
      return date
		});

		newMicropost.type = "Micropost";

		var newMicroposts = [ newMicropost ].concat( microposts );
		this.setState({ microposts: newMicroposts });
	};

  removeFromFeed = (nodeId) => {
    var oldState = this.state.microposts;
    var newState = oldState.filter(function(micropost){
      return micropost.id != nodeId
    });

    this.setState({ microposts: newState });
  };

	openModal = (e) => {
    e.preventDefault();
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

	render() {
		var hashtag = "";
		var signupOrDefault = "";
		var showLoader = "";
		var showFeed = " hidden";
		var todaysUrl = "#";
		var tomorrowsUrl = "#";
		var weekendsUrl = "#";
		var todayActive = "";
		var tomorrowActive = "";
		var weekendActive = "";
    var toggler = "";
		var structuredData = {
			'@context': 'http://schema.org',
			'@type': 'ItemList',
			itemListElement: [],
		};
		// var filters = "";

    if (this.props.hideToggler !== true) {
      toggler = (
        <FeedToggler currentPage="activities" locale={this.props.locale} isSearch={ this.props.isSearch } currentUser={ this.props.currentUser } />
      );
    }
		//if( typeof window !== 'undefined' ) {
		var currentUrl = this.state.url;
		var separator = (currentUrl.indexOf("?") === -1) ? "?" : "&";
		if(currentUrl.indexOf("?d=") !== -1) {
			separator = "?";
		}

		if( currentUrl.indexOf("today") > -1 ) {
			todayActive = "bold";
			currentUrl = currentUrl.split(separator + "d=")[0];
			todaysUrl = currentUrl;
			tomorrowsUrl = currentUrl + separator + "d=tomorrow";
			weekendsUrl = currentUrl + separator + "d=weekend";
		}
		else if ( currentUrl.indexOf("tomorrow") > -1 ) {
			tomorrowActive = "bold";
			currentUrl = currentUrl.split(separator + "d=")[0];
			todaysUrl = currentUrl + separator + "d=today";
			tomorrowsUrl = currentUrl;
			weekendsUrl = currentUrl + separator + "d=weekend";
		}
		else if ( currentUrl.indexOf("weekend") > -1 ) {
			weekendActive = "bold";
			currentUrl = currentUrl.split(separator + "d=")[0];
			todaysUrl = currentUrl + separator + "d=today";
			tomorrowsUrl = currentUrl + separator + "d=tomorrow";
			weekendsUrl = currentUrl;
		}
		else {
			currentUrl = currentUrl.split(separator + "d=")[0];
			todaysUrl = currentUrl + separator + "d=today";
			tomorrowsUrl = currentUrl + separator + "d=tomorrow";
			weekendsUrl = currentUrl + separator + "d=weekend";
		}
		//}

		/* if( this.props.filters ) {
			filters = (
				<div className="full margin-auto filter text-right max-width-1260" style={{position: "relative", top: "-15px", padding: "0 0.5em"}}>
					<div className="display-inline bebas" style={{marginRight: "6px"}}>Filter:</div>
					<div className="display-inline bebas"><a href={todaysUrl} className={ todayActive }>{ I18n.t('microposts.filters.today', {locale: this.props.locale}) }</a> | </div>
					<div className="display-inline bebas"><a href={tomorrowsUrl} className={ tomorrowActive }>{ I18n.t('microposts.filters.tomorrow', {locale: this.props.locale}) }</a> | </div>
					<div className="display-inline bebas"><a href={weekendsUrl} className={ weekendActive }>{ I18n.t('microposts.filters.weekend', {locale: this.props.locale}) }</a></div>
				</div>
			);
		} */

		// Replace "no title" with default title
		if( this.state.microposts ) {
      var adIndex = 1;
	  var adsShown = 0;
	  const adSpacing = 7;
	  const pageSize = 20;
			var structuredDataPosition = 1;
			var micropostNodes = this.state.microposts.map( function ( micropost ) {
				if(!micropost.title) {
					micropost.title = I18n.t('microposts.no_title', {locale: this.props.locale});
				}

        // if ([3, 8, 13, 18].indexOf(adIndex + 1) !== -1) {

		if ((adIndex + 1) % adSpacing === 0) {
          adIndex++;
					var installAd = '';

					if(this.state.affiliates && this.state.affiliates[adsShown]) {
					  // console.log(this.state.affiliates)
					  installAd = <FriendlyAd key={ Math.random() } ad={ this.state.affiliates[adsShown] } />;
					  adsShown++;
					  if ((adsShown + 1) > this.state.affiliates.length) adsShown = 0;
					  //
					}



					if (micropost.type === "Micropost") {
						structuredData.itemListElement.push({
							'@type': 'ListItem',
							position: structuredDataPosition,
							url: `https://buddyme.me/microposts/${micropost.seo_url}`,
							name: micropost.title,
						});
						structuredDataPosition++;

						return (
							<>
								{ installAd }
								<MicropostFeedItem micropost={ micropost }
		  		  								 locale={ this.props.locale }
		  		                   key={micropost.id}
		  											 standalone={ this.props.standalone }
		  		                   currentUser={ this.props.currentUser }
		  		                   contactRequestForm={ this.props.contactRequestForm }
		  		                   removeFromFeed={ this.removeFromFeed } />
						  </>
	          );
					} else if (micropost.type === "User") {
						structuredData.itemListElement.push({
							'@type': 'ListItem',
							position: structuredDataPosition,
							url: `https://buddyme.me/users/${micropost.id}`,
							name: micropost.user_infos.name,
						});
						structuredDataPosition++;

						return <span>
							{ installAd }
							<UserFeedItem user={ micropost }
															locale={ this.props.locale }
															key={micropost.id}
															standalone={ this.props.standalone }
															currentUser={ this.props.currentUser }
															contactRequestForm={ this.props.contactRequestForm } />
						</span>
					}
        } else {
          adIndex++;

					if (micropost.type === "Micropost") {
						structuredData.itemListElement.push({
							'@type': 'ListItem',
							position: structuredDataPosition,
							url: `https://buddyme.me/microposts/${micropost.seo_url}`,
							name: micropost.title,
						});
						structuredDataPosition++;

						return <MicropostFeedItem micropost={ micropost }
															locale={ this.props.locale }
															key={micropost.id}
															standalone={ this.props.standalone }
															currentUser={ this.props.currentUser }
															contactRequestForm={ this.props.contactRequestForm }
															removeFromFeed={ this.removeFromFeed } />
					} else if (micropost.type === "User") {
						structuredData.itemListElement.push({
							'@type': 'ListItem',
							position: structuredDataPosition,
							url: `https://buddyme.me/users/${micropost.id}`,
							name: micropost.user_infos.name,
						});
						structuredDataPosition++;

						return <UserFeedItem user={ micropost }
														locale={ this.props.locale }
														key={micropost.id}
														standalone={ this.props.standalone }
														currentUser={ this.props.currentUser }
														contactRequestForm={ this.props.contactRequestForm } />
					}
        }
			}.bind(this));
		}

		if( this.props.currentUser.guest ) {
      var micropostFormOrSignup = (
        <span>
          <SignUpAndLogin  standalone={ true }
	                         loginForm={ this.props.loginForm }
	                         userForm={ this.props.userForm }
	                         loginPath={ this.props.loginPath }
													 locale={ this.props.locale } />
        </span>
      );
    }
    else {
      var micropostFormOrSignup = (
        <span>
          <MicropostForm latitude={ this.props.latitude }
                         longitude={ this.props.longitude }
                         location={ this.props.location }
                         locale={ this.props.locale }
                         hashtag={ this.props.hashtag }
                         micropostForm={ this.props.micropostForm }
                         currentUser={ this.props.currentUser } />
        </span>
      );
    }

		if( this.props.hashtag ) {
			var hashtag = "#" + this.props.hashtag;
		}

		if( this.state.layoutComplete ) {
			showFeed = "";
			showLoader = " hidden";
		}

		// There are microposts to show, render them
		if( micropostNodes.length > 0 ){
      var search = '';

      if (this.props.hideSearch !== true && false) {
        search = (
          <div>
            <div className="column full no-margin-or-padding">
              <div id="search-container">
                <form action="/search" acceptCharset="UTF-8" method="get">
                  <h5 style={{marginLeft: "4px"}}>{ I18n.t('users.search_for_activities', {locale: this.props.locale}) }</h5>
                  <span className="icon" dangerouslySetInnerHTML={ renderSearchIcon() }></span>
                  <input ref={ searchBox => this.searchBox = searchBox } type="text" name="q" id="search" style={{fontWeight: "normal"}} placeholder={ I18n.t('explorepage.search_placeholder', {locale: this.props.locale}) } value={this.state.searchQuery} />
                  <input type="submit" className="bebas submit-search" value={ I18n.t('explorepage.search', {locale: this.props.locale}) } />
                  <input name="locale" type="hidden" value={ this.props.locale } />
                  <input name="utf8" type="hidden" value="✓" />
                </form>
              </div>
            </div>

            <div className="clearfix"></div>
          </div>
        );
      }

		  return (
		  	<div>
					<script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(structuredData)}}></script>

					<Modal isOpen={this.state.isModalOpen} closeFunction={ this.closeModal }>
		        { micropostFormOrSignup }
		      </Modal>

          { toggler }
					{/* filters */}
          { search }


					<div ref={ feedContainer => this.feedContainer = feedContainer } className="max-width-1260" style={{marginBottom: "2em"}} key={ 0 }>
						<div ref={ loader => this.loader = loader } className={ "no-margin-or-padding container" + showLoader}>
							{/* <DummyPostAnimated locale={ this.props.locale } />
							<DummyPostAnimated locale={ this.props.locale } />
							<DummyPostAnimated locale={ this.props.locale } />
		  					<DummyPostAnimated locale={ this.props.locale } /> */}
              <div className="clearfix"></div>
						</div>

						<Masonry
						  breakpointCols={breakpointColumnsObj}
						  className={ "micropost-list gray-feed-bg" + showFeed }
						  columnClassName="">

					    { micropostNodes }

						</Masonry>
			    </div>
			  </div>
		  );
		}
		// No posts here, render dummy posts and a nice notice
		else {
			return (
				<div className="center">
          <div>
  					<Modal isOpen={this.state.isModalOpen} closeFunction={ this.closeModal }>
  		        { micropostFormOrSignup }
  		      </Modal>

  					{/* filters */}

  					<p className="bebas dark-blue font-size-14 margin-top-25">{ I18n.t('microposts.all_beginnings', {locale: this.props.locale}) }</p>
  					<p className="margin-bottom-20">{ I18n.t('microposts.click_plus', {locale: this.props.locale}) } <a href="javascript: false"><span className="bebas bold white red-bg" onClick={ this.openModal } style={ {position: "relative", top: "4px", display: "inline-block", width: "30px", height: "30px", fontSize: "34px", lineHeight: "1em"} }>+</span></a> { I18n.t('microposts.and_create', {locale: this.props.locale}) } <br/>{ I18n.t('microposts.looking_forward', {locale: this.props.locale}) } <span className="display-inline bebas blue">{ hashtag }</span></p>
  					<div ref={ feedContainer => this.feedContainer = feedContainer } className="max-width-1260" key={ 0 }>
  						<div ref={ loader => this.loader = loader } className={ "container" + showLoader}>
  							{/* <DummyPostAnimated locale={ this.props.locale } />
  							<DummyPostAnimated locale={ this.props.locale } />
  							<DummyPostAnimated locale={ this.props.locale } />
							<DummyPostAnimated locale={ this.props.locale } /> */}
                <div className="clearfix"></div>
  						</div>

  						<Masonry
  						  breakpointCols={breakpointColumnsObj}
  						  className={ "micropost-list gray-feed-bg" + showFeed }
  						  columnClassName="feed-item">
  							{/* <DummyPost locale={ this.props.locale } />
  							<DummyPost locale={ this.props.locale } />
  							<DummyPost locale={ this.props.locale } />
  							<DummyPost locale={ this.props.locale } />
							<DummyPost locale={ this.props.locale } /> */}
  						</Masonry>

  			    </div>
  					<div className="row">
  		        <div className="column full container">
  							<div className="margin-top-25 gray margin-bottom-20 center gray-feed-bg">{ renderLogo() }</div>
  						</div>

							<div className="clearfix"></div>
  					</div>
          </div>
			  </div>
		  );
		}
	}
}
