'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import getLocaleString from '../../helper/GetLocaleString';
import UserProfileImage from '../Elements/UserProfileImage';
import BaseComponent from '../../libs/components/BaseComponent';
import $ from 'jquery';

export default class Conversation extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      clickable: typeof props.clickable != 'undefined' ? props.clickable : true
    };
  }

  choosePrivateConversation = (e) => {
    e.preventDefault();

    if( this.state.clickable) {
      var id = $(e.currentTarget).data("conversation-id");
      var url = getLocaleString(this.props.locale) + "/microposts/" + this.props.micropostId + "?" + "private=" + id;

      window.location.href = url;
    }
  };

  chooseGroupConversation = (e) => {
    e.preventDefault();

    var url = getLocaleString(this.props.locale) + "/microposts/" + this.props.micropostId + "?" + "group=true";

    window.location.href = url;
  };

	render() {
    var userId = this.props.conversation.get_participants[0] ? this.props.conversation.get_participants[0].id : "false";
    var userName = this.props.conversation.get_participants[0] ? this.props.conversation.get_participants[0].name : "";
    var micropost = { user_infos: this.props.conversation.user_infos };
    var currentClass = this.props.isCurrent ? "current" : "";

    if( this.props.isGroup === false ) {
      return (
        <li href="javascript: false" data-conversation-id={ this.props.conversation.id } data-user-id={ userId } className={"bebas " + currentClass} style={{fontSize: "0.9em"}} onClick={ this.choosePrivateConversation }>
          <UserProfileImage micropost={ micropost } width={25} height={25} />
          { "  " + userName }
        </li>
      );
    }
    else {
      return (
        <a href="javascript: false" data-conversation-id={ this.props.conversation.id } data-user-id={ userId } className="bebas white group-button" style={{fontSize: "0.9em"}} onClick={ this.chooseGroupConversation }>
          { this.props.children }
        </a>
      );
    }
	}
}
