'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import BaseComponent from '../../libs/components/BaseComponent';
import initTooltip from '../../helper/Tooltip';
import $ from 'jquery';

export default class InfoIcon extends BaseComponent {
  componentDidMount = () => {
    initTooltip();
  };

  render() {
    return (
      <span rel="tooltip" className="question-mark" title={ this.props.text }>?</span>
    )
  }
}
