'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import BaseComponent from '../../libs/components/BaseComponent';
import PremiumTeaser from '../Elements/PremiumTeaser';
import Modal from './Modal';
import $ from 'jquery';
import CookieHelpers from '../../helper/CookieHelpers';

export default class CookieConsentModal extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isCookieConsentModalOpen: false,
      highlight: '',
      techInfosHidden: true,
      adInfosHidden: true,
    };
  }

  componentDidMount = () => {
    if (false) {
    // TEST
    // if (this.props.currentUser.guest == true && typeof window.adsbygoogle !== 'undefined' && window.adsbygoogle.loaded === true) {
      var rawCookieConsentValue = CookieHelpers.getCookie('cookieConsent');

      if (typeof rawCookieConsentValue === 'undefined') {
        this.openCookieConsentModal();
        /* let consentCookie = JSON.parse(rawCookieConsentValue);

        if (consentCookie === false && window.location.href.indexOf('terms') === -1) {
          this.openCookieConsentModal();
        } */
      }
    }
  };

  openCookieConsentModal = () => {
    this.setState({ isCookieConsentModalOpen: true });
  };

  closeCookieConsentModal = () => {
    this.setState({ isCookieConsentModalOpen: false });
  };

  allowCookies = (e) => {
    e.preventDefault();
    CookieHelpers.setCookie('cookieConsent', true, 60);
    // window.location.reload(false);

    this.closeCookieConsentModal();
    this.triggerAds();
  };

  denyCookies = (e) => {
    e.preventDefault();

    if (typeof window.adsbygoogle !== 'undefined' && window.adsbygoogle.loaded === true) {
      (adsbygoogle=window.adsbygoogle||[]).requestNonPersonalizedAds=1
    }

    CookieHelpers.setCookie('cookieConsent', false, 60);
    // document.cookie = 'geolocation' + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    // document.cookie = 'locationChanged' + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    // window.history.back();

    this.closeCookieConsentModal();
    this.triggerAds();
  };

  triggerAds = () => {
    if (typeof window.adsbygoogle !== 'undefined' && window.adsbygoogle.loaded === true) {
      [].forEach.call(document.querySelectorAll('.adsbygoogle'), function(){
          (adsbygoogle = window.adsbygoogle || []).push({});
      });
    }
  };

  toggleTechInfos = (e) => {
    e.preventDefault();

    this.setState({
      techInfosHidden: !this.state.techInfosHidden,
    });
  };

  toggleAdInfos = (e) => {
    e.preventDefault();

    this.setState({
      adInfosHidden: !this.state.adInfosHidden,
    });
  };

  render() {
    var techInfoText = this.state.techInfosHidden ? "Kurzinfo..." : "Weniger";
    var adInfoText = this.state.adInfosHidden ? "Kurzinfo..." : "Weniger";
    var techElementClass = this.state.techInfosHidden ? " hidden" : "";
    var adElementClass = this.state.adInfosHidden ? " hidden" : "";

    return (
      <Modal isOpen={this.state.isCookieConsentModalOpen} closeFunction={ this.closeCookieConsentModal } isCookieModal={ true }>
        <img src={require("../../../../assets/images/errors/raccoon.png")} alt="Cookiiiiies" style={{ maxWidth: '130px', marginTop: '2rem' }} />
        <h2 className="dark-blue" style={{ marginTop: '0.5rem'}}>Cookiiiiiiiies...</h2>
        <p className="dark-blue" style={{ marginBottom: '4rem' }}>Wir verwenden 2 Arten von Cookies...</p>

        <p className="dark-blue" style={{ textAlign: 'left', padding: '0 1.2rem' }}>
          1) Technisch notwendige Cookies <a className="" ref={ techLink => this.techLink = techLink } href="javascript: false" onClick={ this.toggleTechInfos }>{ techInfoText }</a>

          <ul ref={ techInfos => this.techInfos = techInfos } className="font-size-08" style={{ display: 'block', margin: '1rem auto 0.7rem auto', maxWidth: '80%' }}>
            <li className={ techElementClass } style={{ listStyle: 'disc', marginBottom: '0.3rem' }}>
              Um dir das surfen hier überhaupt technisch zu ermöglichen (Deine Session und diese Cookie-Zustimmung hier merken).
            </li>
            <li className={ techElementClass } style={{ listStyle: 'disc', marginBottom: '0.3rem' }}>
              Um dir Städte vorzuschlagen und nicht nach jedem Seitenaufruf wieder
              nach deinem Standort fragen zu müssen.
            </li>
            <li className={ techElementClass } style={{ listStyle: 'disc', marginBottom: '0.3rem' }}>
              Um anonymisierte Daten über das Surfverhalten zu bekommen.
              <span style={{ fontWeight: 'bold' }}>
                Die Daten werden aber bei uns gespeichert (KEIN Google Analytics!).
              </span>
            </li>
            <li className={ techElementClass } style={{ listStyle: 'disc', marginBottom: '0.3rem' }}>
              Facebook-Cookies, um dir die Möglichkeit zu
              geben, dich mit einem Klick bei uns anzumelden.
            </li>
          </ul>
        </p>

        <p className="dark-blue" style={{ textAlign: 'left', padding: '0 1.2rem' }}>
          2) Werbe-Cookies <a className="" href="javascript: false" onClick={ this.toggleAdInfos }>{ adInfoText }</a>

          <ul ref={ adInfos => this.adInfos = adInfos } className="font-size-08" style={{ display: 'block', margin: '1rem auto 0.7rem auto', maxWidth: '80%' }}>
            <li className={ adElementClass } style={{ listStyle: 'disc', marginBottom: '0.3rem' }}>
              Wir nutzen Google AdSense um Werbung auszuspielen. Standardmäßig ist diese
              Werbung personalisiert. Das heißt, Google versucht möglichst Werbung einzublenden
              die für dich relevant ist. Wenn du unten nicht zustimmst, wird trotzdem Werbung
              angezeigt, nur nicht personalisiert.
            </li>

            <li className={ adElementClass } style={{ listStyle: 'disc', marginBottom: '0.3rem' }}>
              Leider haben wir zu wenige Premium-Mitglieder um BuddyMe ausschließlich
              durch Mitgliedschaften finanzieren zu können. Das macht uns sehr traurig,
              <span style={{ fontWeight: 'bold' }}> weil wir Werbung aus Gründen der Privatsphäre ablehnen</span>.
              Hilf uns bitte mit deiner Mitgliedschaft davon wieder los zu kommen.
            </li>
          </ul>
        </p>

        <div className="column full display-inline" style={{ marginTop: '1.5rem', marginBottom: '2rem', padding: '0 1rem', textAlign: 'center' }}>
          <a href="javascript: false" className="big-submit-button white display-inline bebas" style={{ minWidth: '200px', maxWidth: '250px' }}onClick={ this.allowCookies }>Zustimmen</a>
        </div>

        <div className="clearfix"></div>

        <p className="dark-blue font-size-09" style={{ marginTop: '4rem', textAlign: 'center' }}>
          Mehr Infos findest du in unserer <a href="/terms#privacy" target="_blank">Datenschutzerklärung</a>
        </p>

        <div className="clearfix"></div>

        <br /><br />
        <p className="gray" style={{ textAlign: 'left', padding: '0 1.2rem' }}>
          <a href="javascript: false" className="" style={{ color: '#ccc', borderBottom: '1px solid #ccc' }} onClick={ this.denyCookies }>
            Ohne personalisierte Werbung weiter
          </a>
        </p>
        <br />
      </Modal>
    )
  }
}
