import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import BaseComponent from '../../../libs/components/BaseComponent';

export default class HomeIcon extends BaseComponent {
	render() {
		return(
	    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="23" viewBox="0 0 21 23">
	      <polygon fill="none" stroke="#1e3646" strokeWidth="1.2" points=".883 9.274 .883 21 8.097 21 8.097 13.787 12.218 13.787 12.218 21 19.432 21 19.432 9.274 10.158 0" transform="translate(0 1)"/>
	    </svg>
	  )
	}
}
