import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';

var renderBuddyAvatar = function() {
	return(
    <svg className="buddyavatar-svg"  x="0px" y="0px" viewBox="-3.983 0 47.98 47.977">
      <path d="M26.98,20.802c-0.693-0.262-1.417-0.451-2.492-0.792c0.861-0.683,1.398-1.099,1.925-1.527
    	c4.748-3.87,5.279-10.781,1.173-15.175C23.427-1.14,16.181-1.097,12.096,3.404c-4.048,4.461-3.421,11.35,1.36,15.17
    	c0.512,0.406,0.921,0.942,1.377,1.421c-0.577,0.235-1.146,0.488-1.731,0.708c-6.584,2.449-11.168,6.804-12.84,13.714
    	c-0.687,2.838-0.101,5.442,2.64,7.188c0.511,0.32,1.068,0.83,1.238,1.367c0.646,2.018,2.229,2.791,4.055,3.323
    	c7.158,2.091,14.36,2.225,21.598,0.445c2.479-0.609,5.049-1.13,5.976-3.979c0.248,0.092,2.064-1.126,2.064-1.126
    	s2.27-1.902,2.181-5.664C39.744,24.597,30.155,21.999,26.98,20.802z"/>
    </svg>
	)
}

export default renderBuddyAvatar;
