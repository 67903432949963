'use strict';
import React, { Component, Fragment } from 'react';
import BaseComponent from '../../libs/components/BaseComponent';
import I18n from 'i18n-js';
import FsLightbox from 'fslightbox-react';
import PremiumTeaser from '../Elements/PremiumTeaser';
import UserImagesUpload from './UserImagesUpload';
import $ from 'jquery';
if (typeof window !== 'undefined') {
  require('slick-carousel');
}

var thumbnails = "";
var editLink = "";

export default class UserImagesThumbnailItems extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    /* window.addEventListener('hashchange', function() {
      var currentUrl = location.href;
      var title = document.title + ' - Image ' + location.href.split('#image-')[1];
      _paq.push(['setReferrerUrl', currentUrl]);
       currentUrl = location.pathname + '/' + window.location.hash.substr(1);
      _paq.push(['setCustomUrl', currentUrl]);
      _paq.push(['setDocumentTitle', title]);

      // remove all previously assigned custom variables, requires Matomo (formerly Piwik) 3.0.2
      _paq.push(['deleteCustomVariables', 'page']);
      _paq.push(['setGenerationTimeMs', 0]);
      _paq.push(['trackPageView']);

      _paq.push(['enableLinkTracking']);
    }); */


    this.initializeSlick();
    $('.user-images-g-container').removeClass('hidden');
  }

  initializeSlick() {
    $(this.thumbs).slick({
      arrows: false,
      dots: true,
      infinite: false,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      }]
    });
  }

  handleClick = (index, e) => {
    e.preventDefault();

    var customEvent = new CustomEvent("openUserImage", { detail: {index: index}, bubbles: true });
    e.target.dispatchEvent( customEvent );
  };

  render() {
    var elements = "";
    var markPremium = "";

    if (this.props.currentUser.id === this.props.profileUser.id) {
      editLink = (
        <div className="add-user-image">
          <a href="/users/images/edit" className="bebas init-loading">{ I18n.t('users.images_change', {locale: this.props.locale}) }</a>
        </div>
      );
    }

    if (this.props.urls.thumbs.length > 0) {
      thumbnails = (
        <div ref={ thumbs => this.thumbs = thumbs } className="hidden user-images-g-container">
          {this.props.urls.thumbs.map((url, index) => (
            <img className="add-user-image" key={index} src={url} onClick={ (e) => this.handleClick(index, e) } />
          ))}

          { editLink }
        </div>
      );
    } else if (this.props.currentUser.id === this.props.profileUser.id) {
      thumbnails = (
        <div style={{border: '1px dashed', padding: '1rem 0.5rem'}} dangerouslySetInnerHTML={{__html: I18n.t('users.images_upload_link_desc', {locale: this.props.locale}) }}>
        </div>
      );
    }

  	return (
      <div>
        { thumbnails }
      </div>
    );
  }
}
