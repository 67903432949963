'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import UserFeed from '../Users/UserFeed';
import Filter from '../Feed/Filter';
import Feed from '../Feed/Feed';
import BaseComponent from '../../libs/components/BaseComponent';
import HeaderBar from '../Elements/HeaderBar';
import FeedToggler from '../Elements/FeedToggler';
import PremiumTeaser from '../Elements/PremiumTeaser';
import renderSearchIcon from '../svgIcons/i/search';
import I18n from 'i18n-js';
import $ from 'jquery';

export default class SearchUsersIndexView extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      searchQuery: '',
      currentFilter: false,
    }
  }

  filter = (category, e) => {
    e.preventDefault();

    $('.filter-container a').removeClass('active');
    this.setState({ currentFilter: category });
    $(e.target).addClass('active');
  }

  formSubmit = () => {
    $( "#searchForm" ).submit();
  }

  render() {
    var defaultSearchValue = "";
    // var searchQuery = this.props.q.replace(/['"]+/g, '');
    var searchQuery = decodeURIComponent(JSON.parse(this.props.q));
    var noPostsHeading = "";
    var content = "";
    var feed = "";
    var search ="";
    var toggler = "";
    var filter = "";
    var filterOptions = "";
    var suggestionsHeading = "";
    var now = new Date();
    now = new Date(now.getTime() + (now.getTimezoneOffset() * 60000));
    var premiumDate = new Date(this.props.initialData.currentUser.user_infos.premium);

    if( this.props.q != 'null' ) {
      // defaultSearchValue = this.props.q.replace(/['"]+/g, '');
      defaultSearchValue = decodeURIComponent(JSON.parse(this.props.q))
    }

    if( this.props.initialData.mixedResults.length == 0 ) {
      var noPostsHeading = (
        <p className="bebas center">{ I18n.t('explorepage.no_search_results', {locale: this.props.initialData.locale}) }</p>
      )
    }

    search = '';

    if (this.props.hideToggler !== true && this.state.searchQuery) {
      toggler = (
        <FeedToggler currentPage="buddies" locale={this.props.initialData.locale} isSearch={ true } currentUser={ this.props.initialData.currentUser } />
      );
    }

    var headerBar = '';
    /* if (this.props.hideHeader !== true && false) {
      headerBar = <HeaderBar latitude={ this.props.initialData.lat } longitude={ this.props.initialData.lon }
                 location={ this.props.initialData.location } locale={ this.props.initialData.locale }
                 hashtag={ this.props.initialData.hashtag }
                 micropostForm={ this.props.initialData.micropostForm }
                 loginForm={ this.props.initialData.loginForm } userForm={ this.props.initialData.userForm }
                 loginPath={ this.props.initialData.loginPath } currentUser={ this.props.initialData.currentUser } />
    } */

    // if (premiumDate < now) {
    // not a premium feature

    if (this.props.initialData.mixedResults.length > 0 && searchQuery !== '') {
      feed = (
        <Feed microposts={ this.props.initialData.mixedResults } micropostForm={ this.props.initialData.micropostForm } screenWidth={ this.props.screenWidth }
                        latitude={ this.props.initialData.lat } longitude={ this.props.initialData.lon } currentUrl={ this.props.initialData.currentUrl }
                        location={ this.props.initialData.location } lastPage={ this.props.initialData.lastPage } isSearch={ true } affiliates={ this.props.initialData.affiliates }
                        locale={ this.props.initialData.locale } currentUser={ this.props.initialData.currentUser } loginForm={ this.props.initialData.loginForm }
                        userForm={ this.props.initialData.userForm } loginPath={ this.props.initialData.loginPath } hideToggler={ true }
                        contactRequestForm={ this.props.initialData.contactRequestForm } totalPages={ this.props.initialData.totalPages }
                        currentPage={ this.props.initialData.currentPage } />
      );
    }

    if( this.props.initialData.mixedResults.length == 0 && (searchQuery === '' || searchQuery === 'null')) {
      var noPostsHeading = (
        <p className="bebas center"></p>
      )
    }

    if(searchQuery === '' || searchQuery === 'null') {
      suggestionsHeading = (
        <h4 className="dark-blue" style={{ padding: '0 0.5rem' }}>{ I18n.t('explorepage.personal_suggestions', {locale: this.props.initialData.locale}) }</h4>
      )
    }

    return (
      <div>
        { headerBar }
        { search }
        <div style={{ maxWidth: '40rem', margin: '0 auto 0.7rem auto', paddingTop: '0.2rem' }}>
          { filter }
          { this.props.initialData.currentUser.guest === false &&
            <Filter page={ 'search' } q={ searchQuery } currentUser={ this.props.initialData.currentUser }  locale={ this.props.initialData.locale } />
          }
        </div>
        { toggler }

        <div className="row">
          <div className="container max-width-1260 gray-feed-bg" >
            { noPostsHeading }
            { suggestionsHeading }
            { feed }
          </div>

          <div className="clearfix"></div>
        </div>
      </div>
    );
  }
}
