'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import BaseComponent from '../../libs/components/BaseComponent';
import PremiumTeaser from '../Elements/PremiumTeaser';
import Modal from './Modal';

export default class PremiumTeaserModal extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isPremiumModalOpen: false,
      highlight: '',
    };
  }

  componentDidMount = () => {
    document.addEventListener("triggerPremiumModal", this.handleBff, false);
  };

  openPremiumModal = () => {
    this.setState({ isPremiumModalOpen: true });
  };

  closePremiumModal = () => {
    this.setState({ isPremiumModalOpen: false });
  };

  handleBff = (e) => {
    this.setState({ highlight: e.detail.highlight });
    var now = new Date();
    now = new Date(now.getTime() + (now.getTimezoneOffset() * 60000));
    var premiumDate = new Date(this.props.current_user.premium);

    if (premiumDate < now) {
      e.preventDefault();
      this.openPremiumModal();
    }
  }

  render() {
    return (
      <Modal isOpen={this.state.isPremiumModalOpen} closeFunction={ this.closePremiumModal } premiumTeaser={ true }>
        <PremiumTeaser locale={ this.props.locale } highlight={ this.state.highlight } />
      </Modal>
    )
  }
}
