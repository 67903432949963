'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import getLocaleString from '../../helper/GetLocaleString';
import UserProfileImage from '../Elements/UserProfileImage';
import Modal from '../Elements/Modal'
import MessageList from './MessageList';
import NextButton from '../Elements/NextButton';
import SignUpAndLogin from '../Elements/SignUpAndLogin';
import GroupParticipantList from './GroupParticipantList';
import BaseComponent from '../../libs/components/BaseComponent';
import getFirstName from '../../helper/GetFirstName';
import renderFacebookIcon from '../svgIcons/facebook';
import MBP from '../../helper/MobileBoilerplate';
import I18n from 'i18n-js';
import $ from 'jquery';
var timeoutID = undefined;

export default class ConversationsShowView extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      showSignupModal: false,
      messages: props.initialData.messages.reverse(),
      conversationId: props.initialData.newMessageForm.conversation_id,
      sending: false,
      path: props.initialData.path
    };
  }

  componentDidMount = () => {
    document.addEventListener("chooseConversation", this.handleConversationChange, false);

    if( this.props.loadGroupInitially && !this.props.loadPrivateInitially ){
      var groupSettings = {target: this.props.groupConversation[0].id + "?user_id=" + this.props.initialData.currentUser.id, group: true};
      this.handleConversationChange({ detail: groupSettings });
    }
    else if( !this.props.loadGroupInitially && this.props.loadPrivateInitially && this.isInteger(this.props.loadPrivateInitially) ){
      var groupSettings = {target: this.props.loadPrivateInitially + "?user_id=" + this.props.initialData.currentUser.id, group: false};
      this.handleConversationChange({ detail: groupSettings });
    }
    else {
      this.setUserName(this.state.conversationId);
    }

    var messageField = this.message ? this.message : false;

    if( messageField ) {
      new MBP.autogrow(messageField, 16); // 16 -- line height
      var customEvent = new CustomEvent("input", { bubbles: true });
      messageField.dispatchEvent( customEvent );
    }

    $('.micropost-content').linkify({
      tagName: 'a',
      target: '_blank',
      newLine: '\n',
      linkClass: 'my-links white-bg-link',
      linkAttributes: null
    });

    var _this = this;
    if( this.state.path != "" ) {
      timeoutID = setInterval(function() {
        _this.loadFromServer(_this.state.path, false, true);
      }, 120000);
    }
  };

  isInteger = (value) => {
    return !isNaN(value) &&
           parseInt(Number(value)) == value &&
           !isNaN(parseInt(value, 10));
  };

  closeSignupModal = () => {
    this.setState({ showSignupModal: false });
  };

  componentWillUnmount = () => {
    window.removeEventListener("chooseConversation", this.handleConversationChange, false);
    // Clear setIntervall, otherwise it polls forever when using turbolinks
		window.clearInterval(timeoutID);
  };

  handleConversationChange = (e) => {
    var newConversationId = e.detail.target.substr(0, e.detail.target.indexOf('?'));

    if (parseInt(newConversationId, 10) !== parseInt(this.state.conversationId, 10)) {
      this.setUserName(newConversationId);

      if( e.detail.group ) {
        var urlBase = "/group_conversation/";
        var newState = getLocaleString(this.props.initialData.locale) + "/microposts/" + this.props.initialData.micropost.seo_url + "?" + "group=true";
      }
      else {
        var urlBase = "/private_conversations/";
        var newState = getLocaleString(this.props.initialData.locale) + "/microposts/" + this.props.initialData.micropost.seo_url + "?" + "private=" + newConversationId;
        var customEvent = new CustomEvent("setConversationList", { detail: { target: e.detail.target, group: false, openList: false }, bubbles: false });
        document.dispatchEvent( customEvent );
      }

      var url = urlBase + e.detail.target;
      this.setState({ path: url });
  		this.loadFromServer(url, true, false);
    } else {
      this.setUserName(newConversationId);
    }
	};

  setUserName = (newConversationId) => {
    var newName = "";
    this.setState({ conversationId: newConversationId });

    if( this.conversationUserName ) {
      if ((this.props.initialData.currentUser.id !== this.props.initialData.micropost.user_infos.id) || (typeof this.props.initialData.participants[0] !== 'undefined' && this.props.initialData.participants.length > 1)) {
        var convo = this.props.initialData.all_conversations.filter(conversation => parseInt(conversation.id, 10) === parseInt(newConversationId, 10));

        newName = convo[0].user_infos.name;
      } else if (typeof this.props.initialData.participants[0] !== 'undefined' && this.props.initialData.participants.length === 1) {
        newName = this.props.initialData.participants[0].name;
      }

      $( this.conversationUserName ).text( newName );
      $( this.conversationUserNameHeading ).text( newName );
    }
  };

  getUserName = () => {
    var self = this;
    var userName = "";

    if (this.props.initialData.micropost.id != null) {
      if ((this.props.initialData.currentUser.id !== this.props.initialData.micropost.user_infos.id) || (typeof this.props.initialData.participants[0] !== 'undefined' && this.props.initialData.participants.length > 1)) {
        var convo = this.props.initialData.all_conversations.filter(conversation => parseInt(conversation.id, 10) === parseInt(self.state.conversationId, 10));

        userName = convo[0].user_infos.name;
      } else if (typeof this.props.initialData.participants[0] !== 'undefined' && this.props.initialData.participants.length === 1) {
        userName = this.props.initialData.participants[0].name;
      }
    } else {
      if (typeof this.props.initialData.participants[0] !== 'undefined' && this.props.initialData.participants.length === 1) {
        userName = this.props.initialData.participants[0].name;
      } else {
        userName = this.props.initialData.micropost.user_infos.name;
      }
    }

    return userName;
  };

  getUserId = () => {
    var self = this;
    var userId = -1;

    if (this.props.initialData.micropost.id != null) {
      if ((this.props.initialData.currentUser.id !== this.props.initialData.micropost.user_infos.id) || (typeof this.props.initialData.participants[0] !== 'undefined' && this.props.initialData.participants.length > 1)) {
        var convo = this.props.initialData.all_conversations.filter(conversation => parseInt(conversation.id, 10) === parseInt(self.state.conversationId, 10));

        userId = convo[0].user_infos.id;
      } else if (typeof this.props.initialData.participants[0] !== 'undefined' && this.props.initialData.participants.length === 1) {
        userId = this.props.initialData.participants[0].id;
      }
    } else {
      if (typeof this.props.initialData.participants[0] !== 'undefined' && this.props.initialData.participants.length === 1) {
        userId = this.props.initialData.participants[0].id;
      } else {
        userId = this.props.initialData.micropost.user_infos.id;
      }
    }

    return userId;
  };

  blockUser = (e) => {
    e.preventDefault();
    var userId = this.getUserId();

    if(userId > -1) {
      // console.log("block user with id " + userId);
      var data = {id: this.state.conversationId,
                  userId: userId,
                  micropostId: this.props.initialData.micropost.id,
                  currentUserId: this.props.initialData.currentUser.id}
      $.ajax({
        url: '/conversations/blockUser.js',
        type: 'POST',
        data: data
      })
      .done(function(data) {
        // console.log(data);
        /*var newMessage = that.state.messages.concat( data );
        $( that.error_messages ).text("");
        that.setState({ sending: false, messages: newMessage });
        messageField.value = "";
        that.message.style.height = "auto";
        $('.micropost-content').linkify({
          tagName: 'a',
          target: '_blank',
          newLine: '\n',
          linkClass: 'my-links white-bg-link',
          linkAttributes: null
        });*/
      })
      .fail(function( xhr, status, err ) {
        console.log(xhr);
        /*setTimeout(function() {
          that.setState({ sending: false });
          messageField.value = bufferContent;
        }, 300);*/
      });
    } else {
      return false;
    }
  };

  handleSendMessage = (e) => {
    e.preventDefault();

    if( !this.props.initialData.currentUser.guest ) {
      var that = this;
      var form = this.messageForm;
      var messageField = this.message;
      var data = new FormData( form );
      var bufferContent = messageField.value;
      this.setState({ sending: true });

      if( messageField.value.length > 0 ) {
        $.ajax({
          url: '/messages.js',
          type: 'POST',
          data: data,
          processData: false,
          contentType: false
        })
        .done(function(data) {
          var newMessage = that.state.messages.concat( data );
          $( that.error_messages ).text("");

          if (data.body.indexOf('[email address removed]') !== -1 || data.body.indexOf('[phone number removed]') !== -1) {
            var customEvent = new CustomEvent("triggerPremiumModal", { detail: {highlight: 'contact_infos'}, bubbles: false });
            document.dispatchEvent( customEvent );
          }

          that.setState({ sending: false, messages: newMessage });
          messageField.value = "";
          that.message.style.height = "auto";

          $('.micropost-content').linkify({
            tagName: 'a',
            target: '_blank',
            newLine: '\n',
            linkClass: 'my-links white-bg-link',
            linkAttributes: null
          });
        })
        .fail(function( xhr, status, err ) {
          console.log(xhr);

          setTimeout(function() {
            that.setState({ sending: false });
            messageField.value = bufferContent;
          }, 300);

          if (xhr.responseJSON.errors === 'premium_feature_unlimited') {
            var customEvent = new CustomEvent("triggerPremiumModal", { detail: {highlight: 'unlimited'}, bubbles: false });
            document.dispatchEvent( customEvent );
          }
        });
      }
      else {
        $( this.error_messages ).text(I18n.t('conversations.empty_message', {locale: this.props.initialData.locale}));
        this.setState({ sending: false });
      }
    }
    else {
      this.setState({ showSignupModal: true });
    }
  };

  loadFromServer = (nextUrl, replaceMessages, polling) => {
    if( polling === true ) {
      var message = this.state.messages[this.state.messages.length - 1];
      if( message ) {
        var messageId = message.id;

  			if( nextUrl.indexOf("?") === -1 ) {
  				nextUrl = nextUrl + "?lastMessage=" + messageId;
  			}
  			else {
  				nextUrl = nextUrl + "&lastMessage=" + messageId;
  			}
      }
		}
    else {
      $( this.conversationShowView ).addClass("hidden");
      $( this.spinner ).removeClass("hidden");
    }

    $.ajax({
      url: nextUrl,
      dataType: 'json',
      cache: false,
      timeout: 20000
    })
    .done( function( data ) {

      if( replaceMessages === true ) {
        var newMessages = data.messages.reverse()
    	}
      else if( replaceMessages === false && polling === false ){
        var newMessages = data.messages.reverse().concat( this.state.messages );
      }
      else if( replaceMessages === false && polling === true ){
        var newMessages = this.state.messages.concat( data.messages.reverse() );
      }

      $(".spinner").addClass("hidden");
      $( this.conversationShowView ).removeClass("hidden");

      this.setState({
        messages: newMessages,
        loaded: true
      });
    }.bind(this) )
    .fail( function( xhr, status, err ) {
      this.setState({ loaded: true });
      $(".spinner").addClass("hidden");
      $( this.conversationShowView ).removeClass("hidden");
      console.error(this.props.url, status, err.toString());
      console.error( xhr.responseText );
    }.bind(this) );
  };

  createConversation = (e,node) => {
    e.preventDefault();
    e.stopPropagation();

    if( !this.props.initialData.currentUser.guest ) {
      var that = this;
      var form = this.messageForm;
      var body = this.message;
      var data = new FormData( form );
      var bufferContent = body.value;

      if( body.value.length > 0 ) {
        $.ajax({
          url: form.getAttribute('action'),
          type: 'POST',
          data: data,
          processData: false,
          contentType: false
        })
        .done(function(data) {
          // console.log(data)
          window.location.reload(false);
        })
        .fail(function( xhr, status, err ) {
          console.log(xhr);

          setTimeout(function() {
            that.setState({ sending: false });
            body.value = bufferContent;
          }, 300);
        });
      }
      else {
        $( this.error_messages ).text(I18n.t('conversations.empty_message', {locale: this.props.initialData.locale}));
        this.setState({ sending: false });
      }
    }
    else {
      this.setState({ showSignupModal: true });
    }
  };


  render() {
    // console.log('debug this!!!', this.props);
    var fakeMicropost = { user_infos: this.props.initialData.currentUser.user_infos };
    var userAvatar = <UserProfileImage micropost={ fakeMicropost } currentUser={ this.props.initialData.currentUser } />;
    var self = this;
    var sendText = this.state.sending ? "Loading..." : I18n.t('conversations.send', {locale: this.props.initialData.locale});
    var that = this;
    var convoHeading = "";
    var convoHeadingH3 = "";
    var hideMore = "";
    var messageNotice = "";
    var signupOrDefault = "";
    var blockButton = "";
    var messageLimitNotice = "";
    var unlockUnlimitedMessages = "";
    var now = new Date();
    now = new Date(now.getTime() + (now.getTimezoneOffset() * 60000));
    var premiumDate = new Date(this.props.initialData.currentUser.user_infos.premium);
    var placeholder = I18n.t('conversations.placeholder_hello', {locale: this.props.initialData.locale}) + getFirstName(this.props.initialData.micropost.user_infos.name) + I18n.t('conversations.placeholder_text', {locale: this.props.initialData.locale});
    var messageBodyPlaceholder = I18n.t('conversations.message_body_placeholder', {locale: this.props.initialData.locale});
    var groupConversation = this.props.groupConversation;
    var privateConversations = this.props.privateConversations;
    var additionalRecipientId = typeof this.props.initialData.participants[0] !== 'undefined' ? this.props.initialData.participants[0].id : -1;
    var shareFbUrl = "https://www.facebook.com/sharer/sharer.php?u=https%3A//buddyme.me" + getLocaleString(this.props.initialData.locale) + "/microposts/" + this.props.initialData.micropost.seo_url;
    var sendMessageNotice = (
      <div className="column full bebas dark-blue center margin-top-05">
        <div className="">{ I18n.t('conversations.send_message_1', {locale: this.props.initialData.locale}) } { getFirstName(this.props.initialData.micropost.user_infos.name) } { I18n.t('conversations.send_message_2', {locale: this.props.initialData.locale}) }</div>
      </div>
    );

    if (premiumDate < now) {
      messageLimitNotice = I18n.t('conversations.message_limit_notice', {
        locale: this.props.initialData.locale, 
        week_count: this.props.initialData.currentUser.user_infos.weekly_message_count,
        week_limit: this.props.initialData.currentUser.user_infos.weekly_message_limit,
        month_count: this.props.initialData.currentUser.user_infos.monthly_message_count,
        month_limit: this.props.initialData.currentUser.user_infos.monthly_message_limit,
      });

      unlockUnlimitedMessages = <a style={{ fontWeight: 700 }} href="/premium">{ I18n.t('conversations.unlock_unlimited_messages', {locale: this.props.initialData.locale}) }</a>;
    }

    if (additionalRecipientId === this.props.initialData.currentUser.id && typeof this.props.initialData.micropost !== 'undefined') {
      additionalRecipientId = this.props.initialData.micropost.user_infos.id;
    }

    var hideIfNoRecipent = additionalRecipientId === -1 ? 'hidden' : '';

    var messageForm = (
      <div className="column full" style={{ padding: "1.2em 1em 0 1em" }}>
        <span ref={error_messages => this.error_messages = error_messages} className="red bebas"></span>
        <form ref={messageForm => this.messageForm = messageForm} className="new_message" id="new_message" action={ this.props.initialData.newMessageForm.action } acceptCharset="UTF-8" method="post">

          <div className="">
            <div className="message-limit-notice">{ messageLimitNotice }</div>
            <div className="">{ unlockUnlimitedMessages }</div>
            <textarea ref={message => this.message = message} style={{ border: "1px solid #1e3646", borderRadius: '3px', fontSize: "1rem", lineHeight: "1.4rem" }} className="message-body" placeholder={ messageBodyPlaceholder } rows="2" name="message[body]" id="message_body"></textarea>
          </div>

          <div className="bottom bebas font-size-11">
            <a className="white no-underline bold" href="javascript: false">
              <button type="submit" disabled={ this.state.sending } style={{width: "100%", textAlign: "center", border: "none", fontSize: "1.3rem", padding: "0.7rem 0", backgroundColor: "white"}} className="display-inline bebas" onClick={ this.handleSendMessage } >
                { sendText }
              </button>
            </a>
          </div>

          <input name="locale" type="hidden" value={ this.props.initialData.locale } />
          <input type="hidden" value={ this.state.conversationId || '' } name="message[conversation_id]" id="message_conversation_id"/>
          <input type="hidden" value={ this.props.initialData.micropost.id || '' } name="message[micropost_id]" id="message_micropost_id"/>
          <input value={ this.props.initialData.currentUser.id || '' } type="hidden" name="conversation[sender_id]" />
          <input value={ additionalRecipientId || '' } type="hidden" name="conversation[recipient_id]" />
          <input type="hidden" value="no" name="message[system_message]" id="message_system_message"/>
          <input type="hidden" name="utf8" value="✓"/>
          <input type="hidden" name={ this.props.initialData.newMessageForm.csrf_param } value={ this.props.initialData.newMessageForm.csrf_token || '' }/>
        </form>
      </div>
    );

    if( this.props.initialData.currentUser.guest ) {
      signupOrDefault = (
        <Modal isOpen={this.state.showSignupModal} closeFunction={ this.closeSignupModal }>
          <SignUpAndLogin  standalone={ true }
                           loginForm={ this.props.initialData.loginForm }
                           userForm={ this.props.initialData.userForm }
                           loginPath={ this.props.initialData.loginPath }
                           locale={ this.props.initialData.locale } />
        </Modal>
      );

      sendMessageNotice = (
        <div className="column full bebas dark-blue center margin-top-05">
          <div className="red font-size-14">{I18n.t('conversations.become_member_and_contact',{locale: this.props.initialData.locale})} { this.props.initialData.micropost.user_infos.name }</div><br />
          <div className="column full text-center" style={{ padding: '0'}}>
            <ul className="signup-check" style={{listStyle: '', margin: '0 auto 2rem auto'}}>
              <li>{ I18n.t('createaccount.free', {locale: this.props.initialData.locale}) }</li>
              <li>{ I18n.t('createaccount.real_people', {locale: this.props.initialData.locale}) }</li>
              <li>{ I18n.t('createaccount.explore', {locale: this.props.initialData.locale}) }</li>
              <li>{ I18n.t('createaccount.no_ads', {locale: this.props.initialData.locale}) }</li>
              <li>{ I18n.t('createaccount.privacy', {locale: this.props.initialData.locale}) }</li>
            </ul>
          </div>

          <a href={getLocaleString(this.props.initialData.locale) + "/signup"} style={{fontSize: '1.2rem'}} className="join-button position-it white display-inline bebas">
            { I18n.t('welcome.signup_free', {locale: this.props.initialData.locale}) }
          </a>

          <div className="center">
            <div className="margin-top-10 display-inline font-size-09 bebas">{I18n.t('createaccount.already',{locale: this.props.initialData.locale})} </div>
            <a className="white-bg-link font-size-09 bebas" href={getLocaleString(this.props.initialData.locale) + "/login"} > {I18n.t('loginpage.log_in_here',{locale: this.props.initialData.locale})}</a>
          </div>
        </div>
      );
    }

    if( typeof groupConversation === "undefined" ) {
      groupConversation = this.props.initialData.all_conversations.filter(function(conversation){
        return conversation.is_group === true
      });
    }

    if( typeof privateConversations === "undefined" ) {
      privateConversations = this.props.initialData.all_conversations.filter(function(conversation){
        return conversation.is_group === false
      });
    }

    if( this.props.initialData.lastPage ) {
      hideMore = " hidden";
    }

    if( this.props.initialData.currentUser.id == this.props.initialData.micropost.user_infos.id ) {
      if( this.state.path.indexOf("group_conversation") != -1 ) {
        convoHeading = (
          <div style={{padding: "0 0.5em"}} className="column full">
            <h3 className="bebas no-margin-or-padding ellipsis-heading">{ I18n.t('conversations.group_convo', {locale: this.props.initialData.locale}) } { I18n.t('conversations.with', {locale: this.props.initialData.locale}) } { groupConversation[0].get_participants.length } Buddies</h3>
          </div>
        );
      }

      if( !this.props.initialData.currentUserInGroupConversation && !this.props.initialData.currentUserHasPrivateConversation && this.props.initialData.micropost.id != null ) {
        convoHeading = (
          <div style={{padding: "0 0.5em"}} className="column full">
            { messageNotice }
          </div>
        );
      }
      else if ( this.props.initialData.micropost.id == null ) {
        // console.log('debug convo!!!!!!', this.props.initialData);
        if (this.getUserId() !== this.props.initialData.currentUser.id) {
          blockButton = (
            <a href="javascript: false" onClick={this.blockUser} className={"red bebas margin-bottom-10 " + hideIfNoRecipent} style={{border: '1px solid #f56363', padding: '0 4px', position: 'relative', top: '-4px', float: 'right', fontSize: '0.9rem'}}>{ I18n.t('conversations.block_user', {locale: this.props.initialData.locale, user: this.getUserName()}) }</a>
          );
        }

        messageNotice = (
          <div>
            <div className="column full bebas dark-blue margin-top-10">
              <h2 className="" style={{fontWeight: "normal", display: 'inline-block'}}>{ /*I18n.t('conversations.private_heading_show', {locale: this.props.initialData.locale})*/ } { getFirstName(this.props.initialData.participants[0].name) }</h2>
              {/* blockButton */}
            </div>
          </div>
        );

        convoHeading = (
          <div style={{padding: "0 0.5em"}} className="column full">
            { messageNotice }
          </div>
        );
      }
      else {
        if( this.state.path.indexOf("private_conversations") != -1 ) {
          messageNotice = (
            <div className="font-size-08 gray" style={{position: 'relative', top: '-7px'}}>
              { I18n.t('conversations.private_notice_1', {locale: this.props.initialData.locale}) }<span ref={conversationUserName => this.conversationUserName = conversationUserName}></span>{ I18n.t('conversations.private_notice_2', {locale: this.props.initialData.locale}) }
            </div>
          );

          if (this.props.initialData.all_conversations.length === 1 && this.props.initialData.all_conversations[0].get_participants[0].left !== true) {
            blockButton = (
              <a href="javascript: false" onClick={this.blockUser} className={"red bebas margin-bottom-10 " + hideIfNoRecipent} style={{border: '1px solid #f56363', padding: '0 4px', position: 'relative', top: '-4px', float: 'right', fontSize: '0.9rem'}}>{ I18n.t('conversations.block_user', {locale: this.props.initialData.locale, user: this.getUserName()}) }</a>
            );
          }

          convoHeading = (
            <div style={{padding: "0 0.5em"}} className="column full">
              <h3 className="bebas dark-blue no-margin-or-padding ellipsis-heading" style={{display: "inline"}}>{ /*I18n.t('conversations.private', {locale: this.props.initialData.locale})*/ }<span ref={conversationUserNameHeading => this.conversationUserNameHeading = conversationUserNameHeading}></span></h3>
              { messageNotice }
              {/* blockButton */}
            </div>
          );
        }
      }

      if( privateConversations.length > 1 || (this.props.initialData.currentUserInGroupConversation && !this.props.initialData.currentUserHasPrivateConversation) || this.state.path.indexOf("group_conversation") != -1 ) {
        if( this.props.initialData.currentUserInGroupConversation && !this.props.initialData.currentUserHasPrivateConversation ) {
          //var actualConversation = groupConversation;
        }
        else {
          //var actualConversation = privateConversations;
        }
      }
    }
    else {
      if( this.props.initialData.currentUserInGroupConversation && !this.props.initialData.currentUserHasPrivateConversation ) {
        /*messageNotice = (
          <span>und { this.props.initialData.micropost.conversation_infos.participants.length - 1 } Buddies</span>
        );*/
      }
      else if ( this.props.initialData.currentUserHasPrivateConversation && !this.props.initialData.currentUserInGroupConversation) {
        messageNotice = (
          <div className="font-size-08 gray margin-bottom-10">
            { I18n.t('conversations.private_notice_1', {locale: this.props.initialData.locale}) }{ this.props.initialData.micropost.user_infos.name }{ I18n.t('conversations.private_notice_2', {locale: this.props.initialData.locale}) }
          </div>
        );
      }
      else if ( this.props.initialData.currentUserHasPrivateConversation && this.props.initialData.currentUserInGroupConversation) {
        var _this = this;

        var ownPrivateConversation = privateConversations.filter(function(conversation){
          return conversation.user_infos.id === _this.props.initialData.currentUser.id
        });
      }
      else if (this.props.initialData.micropost.id != null) {
        messageNotice = (
          <div>
            <div className="column full bebas font-size-16 gray center margin-top-10">
              <div className="no-margin-or-padding">{ I18n.t('conversations.interested_in_short', {locale: this.props.initialData.locale}) }</div>
            </div>
            { sendMessageNotice }
          </div>
        );
      }
      else if (this.props.initialData.micropost.id == null) {
        messageNotice = (
          <div>
            <div className="column full bebas dark-blue margin-top-10">
              <h2 className="" style={{fontWeight: "normal", display: 'inline-block'}}>{ /*I18n.t('conversations.private_heading_show', {locale: this.props.initialData.locale})*/ } { getFirstName(this.props.initialData.micropost.user_infos.name) }</h2>
              {/* <a href="javascript: false" onClick={this.blockUser} className={"red bebas margin-bottom-10" + hideIfNoRecipent} style={{border: '1px solid #f56363', padding: '0 4px', position: 'relative', top: '-4px', float: 'right', fontSize: '0.9rem'}}>{ I18n.t('conversations.block_user', {locale: this.props.initialData.locale, user: getFirstName(this.props.initialData.micropost.user_infos.name) }) }</a> */}
            </div>
          </div>
        );
      }

      if( this.state.path.indexOf("group_conversation") != -1 ) {
        convoHeadingH3 = (
          <div style={{padding: "0 0.5em"}} className="column full">
            <h3 className="bebas no-margin-or-padding ellipsis-heading">{ I18n.t('conversations.group_convo', {locale: this.props.initialData.locale}) } { I18n.t('conversations.with', {locale: this.props.initialData.locale}) } { groupConversation[0].get_participants.length } Buddies</h3>
          </div>
        );
      }
      else {
        if( this.props.initialData.currentUserHasPrivateConversation ) {
          convoHeadingH3 = (
            <h3 className="bebas no-margin-or-padding ellipsis-heading">{ /*I18n.t('conversations.private', {locale: this.props.initialData.locale})*/ }{ this.props.initialData.micropost.user_infos.name }</h3>
          );
        }
      }

      convoHeading = (
        <div style={{padding: "0 0.5em"}} className="column full">
          { convoHeadingH3 }
          { messageNotice }
        </div>
      );
    }

    if ( !this.props.initialData.currentUserInGroupConversation && !this.props.initialData.currentUserHasPrivateConversation && this.props.initialData.micropost.id != null ) {
      if( this.props.initialData.currentUser.id != this.props.initialData.micropost.user_infos.id ) {
        messageForm = (
          <div className="column full" style={{ padding: "1.2em 1em 0 1em" }}>
            <span ref={error_messages => this.error_messages = error_messages} className="red bebas"></span>
            <form ref={messageForm => this.messageForm = messageForm} className="new_message" action={ this.props.initialData.contactRequestForm.action } acceptCharset="UTF-8" method="post">
              <div className="">
                <div>
                  <div className="avatarLeft">
                    { userAvatar }
                  </div>
                  <div className="messageRight">
                    <div className="message-limit-notice">{ messageLimitNotice }</div>
                    <div className="">{ unlockUnlimitedMessages }</div>
                    <textarea ref={message => this.message = message} rows="2" style={{ border: "1px solid #1e3646", borderRadius: '3px', fontSize: "1.1rem", lineHeight: "1.4rem" }} placeholder={ placeholder } className="message-body" name="message[body]" id="message_body"></textarea>
                  </div>
                </div>
              </div>
              <div className="bottom bebas font-size-11">
                <a className="white no-underline bold" href="javascript: false">
                  <button type="submit" disabled={ this.state.sending } style={{width: "100%", textAlign: "center", border: "none", fontSize: "1.3rem", padding: "0.7rem 0", backgroundColor: "white"}} className="display-inline bebas" onClick={ this.createConversation } >
                    { sendText }
                  </button>
                </a>
              </div>

              <input name="locale" type="hidden" value={ this.props.initialData.locale } />
              <input type="hidden" name={ this.props.initialData.contactRequestForm.csrf_param } value={ this.props.initialData.contactRequestForm.csrf_token || '' } />
              <input value={ this.props.initialData.currentUser.id || '' } type="hidden" name="conversation[sender_id]" />
              <input value={ this.props.initialData.micropost.user_id || '' } type="hidden" name="conversation[recipient_id]" />
              <input value={ this.props.initialData.micropost.id || '' } type="hidden" name="conversation[micropost_id]" />
            </form>
          </div>
        );
      }
        else {
          messageForm = "";
        }
    }

    /* if (additionalRecipientId === -1 && this.props.initialData.currentUser.id === this.props.initialData.micropost.user_infos.id) {
      messageForm = (
        <div>
          <div className="clearfix"></div>
          <h3 className="center margin-top-25 bebas bold red">{ I18n.t('conversations.user_missing', {locale: this.props.initialData.locale}) }</h3>
        </div>
      );
    } */

    return (
      <div>
        <div ref={spinner => this.spinner = spinner} className="spinner hidden"></div>
        <div ref={conversationShowView => this.conversationShowView = conversationShowView} className="max-width-read">
          { signupOrDefault }
          <div className="">{ convoHeading }</div>
          <div className="column full" style={{paddingTop: "0px"}}>
            <div className={ hideMore + "load-more-messages" } style={{padding: "0px"}}>
              <NextButton url={ this.state.path } pushState={ false } lastPage={ this.props.initialData.lastPage } locale={ this.props.initialData.locale } loadFromServer={ this.loadFromServer }>
                { I18n.t('conversations.load_more', {locale: this.props.initialData.locale}) }
              </NextButton>
            </div>

            <MessageList messages={ this.state.messages } currentUser={ this.props.initialData.currentUser } currentUserHasPrivateConversation={ this.props.initialData.currentUserHasPrivateConversation }
                         locale={ this.props.initialData.locale } path={ this.state.path } affiliates={ this.props.initialData.affiliates } />
          </div>

          {/* <hr style={{ margin: "0.5rem auto" }} /> */}

          { messageForm }
        </div>
      </div>
    );
  }
}
