'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import MicropostForm from './MicropostForm';
import BaseComponent from '../../libs/components/BaseComponent';

export default class MicropostsNewView extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      data_uri: null, // URI for an uploaded post image
      micropostFormErrors: '',
      checkable: false,
      sending: false,
      dateIndex: 0,
      timestamp: Math.floor(Date.now() / 1000) // Set timestamp to mark an old version of the view
    };
  }

  render() {
    return (
      <div className="row">
        <div className="column full container show-view ">
          <div className="margin-auto max-width">
            <div className="micropost-list">
              <div className="margin-top-10">
                <div className="feed-item center margin-auto">
                  <MicropostForm latitude={ this.props.initialData.latitude }
                                 longitude={ this.props.initialData.longitude }
                                 locale={ this.props.initialData.locale }
                                 hashtag={ this.props.initialData.hashtag }
                                 micropostForm={ this.props.initialData.micropostForm }
                                 currentUser={ this.props.initialData.currentUser }
                                 location={ this.props.initialData.location } />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
