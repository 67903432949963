'use strict';
import React, { Component, Fragment } from 'react';
import BaseComponent from '../../libs/components/BaseComponent';
import I18n from 'i18n-js';
import FsLightbox from 'fslightbox-react';
import PremiumTeaser from '../Elements/PremiumTeaser';
import UserImagesUpload from './UserImagesUpload';
import UserImagesThumbnailItems from './UserImagesThumbnailItems';
import $ from 'jquery';
if (typeof window !== 'undefined') {
  require('slick-carousel');
}

var thumbnails = "";
var editLink = "";

export default class UserImagesThumbnails extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLightboxVisible: false,
      slide: 1,
    };
  }

  componentDidMount = () => {
    document.addEventListener("openUserImage", this.handleClick, false);

    window.addEventListener('hashchange', function() {
      // this.setState({initialRender: 0});
      var currentUrl = location.href;
      var title = document.title + ' - Image ' + location.href.split('#image-')[1];

      if (window.location.href.indexOf('buddyme.me') > -1) {
        _paq.push(['setReferrerUrl', currentUrl]);
        currentUrl = location.pathname + '/' + window.location.hash.substr(1);
        _paq.push(['setCustomUrl', currentUrl]);
        _paq.push(['setDocumentTitle', title]);

        // remove all previously assigned custom variables, requires Matomo (formerly Piwik) 3.0.2
        _paq.push(['deleteCustomVariables', 'page']);
        _paq.push(['setGenerationTimeMs', 0]);
        _paq.push(['trackPageView']);

        _paq.push(['enableLinkTracking']);
      }
    });


    // this.initializeSlick();
    // $('.user-images-g-container').removeClass('hidden');
  }

  /* initializeSlick() {
    $(this.thumbs).slick({
      arrows: false,
      dots: true,
      infinite: false,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      }]
    });
  } */

  handleClick = (e) => {
    e.preventDefault();

    this.setState({
      isLightboxVisible: !this.state.isLightboxVisible,
      slide: e.detail.index + 1,
    });
  };

  handleSlideChange = (fsLightbox) => {
    var currentSlide = fsLightbox.stageIndexes.current;
    // location.hash = 'image-' + currentSlide;
    var myHash = '#image-' + currentSlide;
    history.replaceState(undefined, undefined, myHash);
  };

  handleClose = () => {
    // remove hash
    history.replaceState("", document.title, window.location.pathname + window.location.search);
  };

  render() {
    /* var now = new Date();
    now = new Date(now.getTime() + (now.getTimezoneOffset() * 60000));
    var premiumDate = this.props.micropost ? new Date(this.props.micropost.user_infos.premium) : new Date(0);
    var premiumDateCurrentUser = this.props.currentUser ? new Date(this.props.currentUser.user_infos.premium) : new Date(0);
    var premiumNotice = "";
    var premiumStyle = {};
    var elements = ""; */
    var lightbox = "";
    var markPremium = "";


    /* if (premiumDateCurrentUser < now && this.props.currentUser.id !== this.props.profileUser.id) {
      markPremium = 'not-premium';
      premiumNotice = (
        <PremiumTeaser hideEmoji={ true } locale={ this.props.locale || "de" } highlight="picSize" />
      );

      elements = this.props.urls.previews.map((e) => {
        return (
          <div style={{ backgroundColor: '#ffffff' }}>
            <img src={e} style={{ margin: '1rem 0' }} />

            {premiumNotice}
          </div>
        );
      });

      lightbox = (
        <FsLightbox
          toggler={ this.state.isLightboxVisible }
          slide={ this.state.slide }
          customSources={ elements }
          disableThumbs={ true }
          onOpen={ this.handleSlideChange }
          onClose={ this.handleClose }
          onSlideChange={ this.handleSlideChange }
        />
      );
    } else { */
      lightbox = (
        <FsLightbox
          toggler={ this.state.isLightboxVisible }
          slide={ this.state.slide }
          sources={ this.props.urls.originals }
          onOpen={ this.handleSlideChange }
          onClose={ this.handleClose }
          onSlideChange={ this.handleSlideChange }
        />
      );
    // }

    /* if (this.props.currentUser.id === this.props.profileUser.id) {
      editLink = (
        <div className="add-user-image">
          <a href="/users/images/edit" className="bebas">{ I18n.t('users.images_change', {locale: this.props.locale}) }</a>
        </div>
      );
    }

    if (this.props.urls.thumbs.length > 0) {
      thumbnails = (
        <div ref={ thumbs => this.thumbs = thumbs } className="hidden user-images-g-container">
          {this.props.urls.thumbs.map((url, index) => (
            <img className="add-user-image" key={index} src={url} onClick={ (e) => this.handleClick(index, e) } />
          ))}

          { editLink }
        </div>
      );
    } else {
      thumbnails = (
        <div style={{border: '1px dashed', padding: '1rem 0.5rem'}} dangerouslySetInnerHTML={{__html: I18n.t('users.images_upload_link_desc', {locale: this.props.locale}) }}>
        </div>
      );
    } */

  	return (
      <div>
        <div className={ markPremium }>
          { lightbox }
        </div>

        {/* thumbnails */}
        <UserImagesThumbnailItems currentUser={ this.props.currentUser } profileUser={ this.props.profileUser } locale={ this.props.locale } urls={ this.props.urls } />
      </div>
    );
  }
}
