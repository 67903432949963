import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';

var renderProfileFullIcon = function() {
	return(
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21">
		  <path fillRule="evenodd" stroke="#1e3646" strokeWidth="1.2" d="M9.5 11C6.46284211 11 4 8.53715789 4 5.5 4 2.46284211 6.46284211 0 9.5 0 12.5371579 0 15 2.46284211 15 5.5 15 8.53715789 12.5371579 11 9.5 11zM18 19L18 16.9030385C18 15.3063673 16.5132159 14 14.6960352 14L3.30396476 14C1.48678414 14 0 15.3063673 0 16.9030385L0 19 18 19z" transform="translate(1 1)"/>
		</svg>
  )
}

export default renderProfileFullIcon;
