'use strict';
import PropTypes from 'prop-types';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import BaseComponent from '../../libs/components/BaseComponent';
import renderBack from '../svgIcons/i/back';

export default class BackButton extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      url: '',
    };
  }

  componentDidMount = () => {
    if (typeof window !== 'undefined') {
      let path = window.location.pathname;
      let queryString = window.location.search;
      let href = window.location.href;
      let backTarget = '';
      let newUrl = '';

      if (path.indexOf('/private_conversations/') > -1) {
        backTarget = window.location.origin + '/conversations';

        if (document.referrer !== '' && document.referrer.indexOf(backTarget) > -1) {
          newUrl = 'javascript:history.back()';
        } else {
          newUrl = '/conversations';
        }

        this.setState({
          show: true,
          url: newUrl,
        });
      } else if (path.indexOf('/microposts/') > -1 && (queryString.indexOf('private=') > -1 || queryString.indexOf('group=true') > -1)) {
        backTarget = window.location.origin + '/conversations';

        if (document.referrer !== '' && document.referrer.indexOf(backTarget) > -1) {
          newUrl = 'javascript:history.back()';
        } else {
          newUrl = '/conversations';
        }

        this.setState({
          show: true,
          url: newUrl,
        });
      } else if (path.indexOf('/microposts/') > -1 && queryString.indexOf('private=') === -1 && queryString.indexOf('group=true') === -1) {
        this.setState({
          show: true,
          url: 'javascript:history.back()',
        });
      } else if (path.indexOf('/users/preferences/') > -1) {
        backTarget = window.location.origin + `/users/${this.props.currentUser.id}`;

        if (document.referrer !== '' && document.referrer === backTarget) {
          newUrl = 'javascript:history.back()';
        } else {
          newUrl = `/users/${this.props.currentUser.id}`;
        }

        this.setState({
          show: true,
          url: newUrl,
        });
      }  else if (path.match(/\/users\/\d+\/visitors/)) {
        backTarget = window.location.origin + `/users/${this.props.currentUser.id}`;

        if (document.referrer !== '' && document.referrer === backTarget) {
          newUrl = 'javascript:history.back()';
        } else {
          newUrl = `/users/${this.props.currentUser.id}`;
        }

        this.setState({
          show: true,
          url: newUrl,
        });
      } else if (path.match(/\/users\/\d+\/edit/)) {
        backTarget = window.location.origin + `/users/${this.props.currentUser.id}`;

        if (document.referrer !== '' && document.referrer === backTarget) {
          newUrl = 'javascript:history.back()';
        } else {
          newUrl = `/users/${this.props.currentUser.id}`;
        }

        this.setState({
          show: true,
          url: newUrl,
        });
      } else if (path.match(/\/users/)) {
        backTarget = window.location.origin + `/users`;

        if (document.referrer !== '' && document.referrer === backTarget) {
          newUrl = 'javascript:history.back()';
        } else if (document.referrer !== '') {
          newUrl = 'javascript:history.back()';
        } else {
          newUrl = `/users`;
        }

        this.setState({
          show: true,
          url: newUrl,
        });
      }
    }
  };

	render() {
    let showClass = this.state.show === true ? "" : "hidden";

    return (
      <div>
        <div className={ showClass }><a className="top-back-button init-loading" href={ this.state.url }>{ renderBack() }</a></div>
      </div>
    );
  }
}
