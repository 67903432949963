'use strict';
import PropTypes from 'prop-types';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import GroupParticipant from './GroupParticipant';
import BaseComponent from '../../libs/components/BaseComponent';

export default class GroupParticipantList extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

	render() {
    var _this = this;

    var participantNodes = this.props.participants.map(function(participant) {
      return (
        <GroupParticipant groupConversation={ _this.props.groupConversation } selectable={ _this.props.selectable } locale={ _this.props.locale } key={participant.id}>
          { participant }
        </GroupParticipant>
      );
    });

		return (
      <div className="margin-bottom-20">
        { participantNodes }
        <div className="clearfix"></div>
      </div>
		);
	}
}
