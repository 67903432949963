/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
require('normalize.css');
require('../assets/stylesheets/microposts.scss');
require('../assets/stylesheets/grid.scss');
require('../assets/stylesheets/main.scss');
require('../assets/stylesheets/custom.scss');
require('../assets/stylesheets/responsive-nav.scss');
require('../assets/stylesheets/users.scss');
require('../assets/stylesheets/static_pages.scss');
require('../assets/stylesheets/seo_pages.scss');
require('../assets/stylesheets/conversations.scss');
require('../assets/stylesheets/slick.scss');
require('../assets/stylesheets/slick-theme.scss');
require('../assets/stylesheets/application.scss');
require('../assets/stylesheets/leaderboard.scss');
require('../assets/stylesheets/pwa.scss');
// require('../assets/stylesheets/instagram.scss');

import ReactOnRails from 'react-on-rails';
import I18n from 'i18n-js';

if (typeof window !== 'undefined') {
  // const { DirectUpload } = require('activestorage');
  const imagesLoaded = require('imagesloaded');

  imagesLoaded.makeJQueryPlugin( $ );
}

import $ from 'jquery';
typeof window !== 'undefined' ? window.jQuery = $ : null;
typeof window !== 'undefined' ? require('jquery-ujs') : null;

// const Turbolinks = typeof window !== 'undefined' ? require("turbolinks") : null;
// typeof window !== 'undefined' ? Turbolinks.start() : null;

if(typeof navigator !== 'undefined' && 'serviceWorker' in navigator) {
  navigator.serviceWorker
           .register('/sw.js')
           .then(function() { console.log("Service Worker Registered"); });
}

import MicropostsIndexView from '../bundles/BuddyMe/components/Microposts/MicropostsIndexView';
import MicropostsShowView from '../bundles/BuddyMe/components/Microposts/MicropostsShowView';
import MicropostsEditView from '../bundles/BuddyMe/components/Microposts/MicropostsEditView';
import MicropostsNewView from '../bundles/BuddyMe/components/Microposts/MicropostsNewView';
import Micropost from '../bundles/BuddyMe/components/Microposts/Micropost';
import MicropostFeed from '../bundles/BuddyMe/components/Microposts/MicropostFeed';
import FlashMessages from '../bundles/BuddyMe/components/Elements/FlashMessages';
import PremiumTeaser from '../bundles/BuddyMe/components/Elements/PremiumTeaser';
import RangeSlider from '../bundles/BuddyMe/components/Elements/RangeSlider';
import FeedIndexView from '../bundles/BuddyMe/components/Feed/FeedIndexView';
import Filter from '../bundles/BuddyMe/components/Feed/Filter';
import PremiumTeaserModal from '../bundles/BuddyMe/components/Elements/PremiumTeaserModal';
import CookieConsentModal from '../bundles/BuddyMe/components/Elements/CookieConsentModal';
import BackButton from '../bundles/BuddyMe/components/Elements/BackButton';
import InfoIcon from '../bundles/BuddyMe/components/Elements/InfoIcon';
import RenderLogo from '../bundles/BuddyMe/components/svgIcons/logo';
import RenderBell from '../bundles/BuddyMe/components/svgIcons/bell';
import RenderStar from '../bundles/BuddyMe/components/svgIcons/star';
import RenderBuddyAvatar from '../bundles/BuddyMe/components/svgIcons/buddyAvatar';
import RenderSearchIcon from '../bundles/BuddyMe/components/svgIcons/i/search';
import RenderUsers from '../bundles/BuddyMe/components/svgIcons/users';
import RenderCommentFilled from '../bundles/BuddyMe/components/svgIcons/commentFilled';
import RenderFollow from '../bundles/BuddyMe/components/svgIcons/i/follow';
import RenderPlane from '../bundles/BuddyMe/components/svgIcons/i/plane';
import UserProfileImage from '../bundles/BuddyMe/components/Elements/UserProfileImage';
import UsersIndexView from '../bundles/BuddyMe/components/Users/UsersIndexView';
import UserVisitorsView from '../bundles/BuddyMe/components/Users/UserVisitorsView';
import UsersShowView from '../bundles/BuddyMe/components/Users/UsersShowView';
import UsersNewView from '../bundles/BuddyMe/components/Users/UsersNewView';
import UsersEditView from '../bundles/BuddyMe/components/Users/UsersEditView';
import UsersPreferencesView from '../bundles/BuddyMe/components/Users/UsersPreferencesView';
import UserImagesUpload from '../bundles/BuddyMe/components/Users/UserImagesUpload';
import UserImagesThumbnails from '../bundles/BuddyMe/components/Users/UserImagesThumbnails';
import UserImagesThumbnailItems from '../bundles/BuddyMe/components/Users/UserImagesThumbnailItems';
import HashtagsShowView from '../bundles/BuddyMe/components/Hashtags/HashtagsShowView';
import MixedHashtagsShowView from '../bundles/BuddyMe/components/Hashtags/MixedHashtagsShowView';
import UsersDeleteView from '../bundles/BuddyMe/components/Users/UsersDeleteView';
import ConversationsShowView from '../bundles/BuddyMe/components/Conversations/ConversationsShowView';
import SessionsNewView from '../bundles/BuddyMe/components/Sessions/SessionsNewView';
import LandingPage from '../bundles/BuddyMe/components/StaticPages/LandingPage';
import SearchUsersIndexView from '../bundles/BuddyMe/components/StaticPages/SearchUsersIndexView';
import CookieHelpers from '../bundles/BuddyMe/helper/CookieHelpers';
import MobileBottomMenu from '../bundles/BuddyMe/components/Elements/MobileBottomMenu';

import de from '../../config/locales/de.yml';
import en from '../../config/locales/en.yml';
import { getParams } from '../bundles/BuddyMe/helper/GetParams';

I18n.defaultLocale = "de-AT";
var params = {};
if (typeof window !== 'undefined') {
  params = getParams( window.location.href );
}

I18n.translations['de-AT'] = de['de-AT'];
I18n.translations['en-AT'] = en['en-AT'];
I18n.translations['de-DE'] = I18n.translations['de-AT'];
I18n.translations['en-DE'] = I18n.translations['en-AT'];
typeof window !== 'undefined' ? require('linkifyjs/jquery')($, document) : null;

ReactOnRails.register({
  FeedIndexView,
  MicropostsIndexView,
  MicropostsShowView,
  MicropostsEditView,
  MicropostsNewView,
  Micropost,
  MicropostFeed,
  FlashMessages,
  PremiumTeaser,
  RangeSlider,
  PremiumTeaserModal,
  CookieConsentModal,
  InfoIcon,
  RenderLogo,
  RenderBell,
  RenderStar,
  RenderBuddyAvatar,
  RenderUsers,
  RenderCommentFilled,
  RenderFollow,
  RenderPlane,
  BackButton,
  Filter,
  CookieHelpers,
  RenderSearchIcon,
  UserProfileImage,
  UsersIndexView,
  UserVisitorsView,
  UsersShowView,
  UsersNewView,
  UsersEditView,
  UsersDeleteView,
  UsersPreferencesView,
  UserImagesUpload,
  UserImagesThumbnails,
  UserImagesThumbnailItems,
  ConversationsShowView,
  SessionsNewView,
  HashtagsShowView,
  MixedHashtagsShowView,
  LandingPage,
  SearchUsersIndexView,
  MobileBottomMenu,
});


function startRefreshAnimation() {
  // Enabling active state support on iOS
  window.onload = function() {
    if(/iP(hone|ad)/.test(window.navigator.userAgent)) {
      var elements = document.querySelectorAll('button');
      var emptyFunction = function() {};
      for(var i = 0; i < elements.length; i++) {
        // an empty commit ;)
        elements[i].addEventListener('touchstart', emptyFunction, {passive: true});
      }
    }
  };
  const sleep = (timeout) => new Promise(resolve => setTimeout(resolve, timeout));
  const transitionEnd = function(propertyName, node) {
    return new Promise(resolve => {
      function callback(e) {
        e.stopPropagation();
        if (e.propertyName === propertyName) {
          node.removeEventListener('transitionend', callback);
          resolve(e);
        }
      }
      node.addEventListener('transitionend', callback);
    });
  }

  const refresher = document.querySelector('.refresher');

  document.body.classList.add('refreshing');

  refresher.classList.add('shrink');

  refresher.classList.add('done');
  refresher.classList.remove('shrink');
  document.body.classList.remove('refreshing');

  refresher.classList.remove('done');
}

var customScripts = function() {
  // Send screen width to backend
  var width  = window.innerWidth;
  // console.log('debug width!!!!', width);
  CookieHelpers.setCookie('screenWidth', width, 1);

  // Footer
  $('.footer-v-accordion').click(function(e){
    $(this).toggleClass('open');
  });

  // Hide notification list on "outside-click"
  $(document).click(function(e) {
    if ($(e.target).parents('.choose-conversation').length || $(e.target).hasClass('choose-conversation')) {
        return;
    }

    if(!$(e.target).closest('.conversation-list-container').length && $(e.target).parents("ul.desktop-top-nav").length == 0) {
      if($('.conversation-list-container').is(":visible")) {
        e.preventDefault();
        e.stopPropagation();
        $('.conversation-list-container').addClass("hidden");
      }
    }
  });

  // Prevent scrolling of body if the notification list is already
  // scrolled to top/bottom
  // see: https://stackoverflow.com/questions/5802467/prevent-scrolling-of-parent-element
  $(document).on('DOMMouseScroll mousewheel', '.notification-list', function(ev) {
    var $this = $(this),
        scrollTop = this.scrollTop,
        scrollHeight = this.scrollHeight,
        height = $this.innerHeight(),
        delta = (ev.type == 'DOMMouseScroll' ?
            ev.originalEvent.detail * -40 :
            ev.originalEvent.wheelDelta),
        up = delta > 0;

    var prevent = function() {
        ev.stopPropagation();
        ev.preventDefault();
        ev.returnValue = false;
        return false;
    }

    if (!up && -delta > scrollHeight - height - scrollTop) {
        // Scrolling down, but this will take us past the bottom.
        $this.scrollTop(scrollHeight);
        return prevent();
    } else if (up && delta > scrollTop) {
        // Scrolling up, but this will take us past the top.
        $this.scrollTop(0);
        return prevent();
    }
  });

  ////////////////////////////////////////////////////////////////////////////
  // For the error Page -> Form to submit Error report
  ////////////////////////////////////////////////////////////////////////////
  var $errorReportForm = $('#error_report_form');
  $errorReportForm.on('ajax:success',function(e, data, status, xhr){
    $('#error_report_form').fadeOut(200);
    $('#thx').fadeIn(200).removeClass("hidden");
  }).on('ajax:error',function(e, xhr, status, error){
    $('#fail').fadeIn(200).removeClass("hidden");
  });

  if( $errorReportForm.length > 0){
    document.getElementById("url").value = window.location.href;
  }

  ////////////////////////////////////////////////////////////////////////////
  // For the static pages: LandingPage, UnionsPage,...
  ////////////////////////////////////////////////////////////////////////////


  ////////////////////////////////////////////////////////////
  var $animation_elements = $('.animate-on-scroll');
  var $window = $(window);

  function check_if_in_view() {
    var window_height = $window.height();
    var window_top_position = $window.scrollTop();
    var window_bottom_position = (window_top_position + window_height);

    $.each($animation_elements, function() {
      var $element = $(this);
      var element_height = $element.outerHeight();
      var element_top_position = $element.offset().top;
      var element_bottom_position = (element_top_position + element_height);

      //check to see if this current container is within viewport
      if ((element_bottom_position >= window_top_position) &&
        (element_top_position <= (window_bottom_position - window_height*0.3) )) {
        $element.addClass('animated fadeInUp');
      } else {
        // $element.removeClass('in-view');
      }
    });
  }

  $window.on('scroll resize', check_if_in_view);
  $window.trigger('scroll');

  // Google AdSense
  var consent = CookieHelpers.getCookie('cookieConsent');

  // if (typeof window.adsbygoogle !== 'undefined' && window.adsbygoogle.loaded === true) {
    if (consent === false) {
      (adsbygoogle=window.adsbygoogle||[]).requestNonPersonalizedAds=1
    }

    if (typeof consent !== 'undefined') {
      var numberOfAdElements = document.querySelectorAll('.adsbygoogle').length;

      for (var i = 0; i < numberOfAdElements; i++) {
        (adsbygoogle = window.adsbygoogle || []).push({});
      }
    }
  // }
};

if (typeof window !== 'undefined') {
  ////////////////////////////////////////////////////////////////////////////
  // Custom Event Polyfill from
  // https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
  ////////////////////////////////////////////////////////////////////////////
  (function () {
    function CustomEvent ( event, params ) {
      params = params || { bubbles: false, cancelable: false, detail: undefined };
      var evt = document.createEvent( 'CustomEvent' );
      evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
      return evt;
     }

    CustomEvent.prototype = window.Event.prototype;

    window.CustomEvent = CustomEvent;
  })();

  function unloadingWebsite() {
    // Do something just before the user leaves:
    // Eg: Hide/close your menu/reset settings/clear cookies etc, whatever you need to do.
    document.body.classList.add('refreshing');
  }

  window.addEventListener("pageshow", function() {
    // VERY IMPORTANT for removing loading animation in iOS PWA
    document.body.classList.remove('refreshing');
  });

  $(document).ready(function() {
    customScripts();

    $(".init-loading").on("click", function() {
      if (document.body.classList.contains('guest')) return;
      var offset = $(document).scrollTop();
      $('.refresher').css({ top: offset + 'px' });
      // console.log("time to show animation!!!!!");
      document.body.classList.add('refreshing');
    });

    document.body.classList.remove('refreshing');
  });
}
