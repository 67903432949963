'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import getLocaleString from '../../helper/GetLocaleString';
import BaseComponent from '../../libs/components/BaseComponent';
import I18n from 'i18n-js';

export default class UsersDeleteView extends BaseComponent {
  render() {

    var userUrl = getLocaleString(this.props.initialData.locale) + "/users/" + this.props.initialData.currentUser.id;
    var userDeleteUrl = getLocaleString(this.props.initialData.locale) + "/users/" + this.props.initialData.currentUser.id + "/deleteAccount";

    return (
      <div className="row">
        <div className="full column center">
          <h1 className="bebas font-size-30 blue margin-auto max-width">{ I18n.t('users.danger_zone', {locale: this.props.initialData.locale}) }</h1>
          <div className="container margin-auto max-width-800">
            <div className="danger-zone text-left margin-top-25">
              <p>{ I18n.t('users.delete_text', {locale: this.props.initialData.locale}) }</p>
              <div className="center no-margin">
                <a href={ userUrl } data-confirm={ I18n.t('users.confirm_delete', {locale: this.props.initialData.locale}) } title={ I18n.t('users.delete', {locale: this.props.initialData.locale}) } className="margin-top-30 margin-bottom-70 big-submit-button white display-inline bebas init-loading" rel="nofollow" data-method="delete">
                  { I18n.t('users.delete', {locale: this.props.initialData.locale}) }
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
