'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import getLocaleString from '../../helper/GetLocaleString';
import BaseComponent from '../../libs/components/BaseComponent';
import getFirstName from '../../helper/GetFirstName';
import renderPowerOffIcon from '../svgIcons/powerOff';
import renderCogIcon from '../svgIcons/cog';
import renderPencilIcon from '../svgIcons/pencil';
import $ from 'jquery';
import I18n from 'i18n-js';

export default class UsersPreferencesView extends BaseComponent {
  constructor() {
    super();
    this.state = {
     passwordDisabled: true
    };
  }

  enablePasswordFields = (e) => {
    e.preventDefault();

    $( this.pwdContainer ).toggleClass("hidden");
    var newPasswordState = !this.state.passwordDisabled;
    this.setState({ passwordDisabled: newPasswordState });
  };

  render() {
    // Set css styles the react way
    var imageUploadStyle = {
      height: '0px',
      width: '0px',
      overflow: 'hidden',
      visisbility: 'hidden',
      float: 'right'
    };

    var inputValues = {
      name: this.props.initialData.formData.name ? this.props.initialData.formData.name : "",
      email: this.props.initialData.formData.email ? this.props.initialData.formData.email : "",
      about: this.props.initialData.formData.about ? this.props.initialData.formData.about : "",
      location: this.props.initialData.formData.location ? this.props.initialData.formData.location : ""
    };

    var userUrl = getLocaleString(this.props.initialData.locale) + "/users/" + this.props.initialData.currentUser.id;
    var userDeleteUrl = getLocaleString(this.props.initialData.locale) + "/users/" + this.props.initialData.currentUser.id + "/deleteAccount";
    var editUrl = getLocaleString(this.props.initialData.locale) + "/users/" + this.props.initialData.currentUser.id + "/edit";
    var preferencesUrl = getLocaleString(this.props.initialData.locale) + "/users/" + "preferences/" + this.props.initialData.currentUser.id;
    var formID = "edit_user_" + this.props.initialData.currentUser.id;
    var firstName = getFirstName( this.props.initialData.currentUser.name );
    var passwordChange = "";
    var editTab = "";

    // Check the right radio button depending on the language
    if( this.props.initialData.currentUser.language == "de" ) {
      var checkedDE = true;
      var checkedEN = false;
    }
    else {
      var checkedDE = false;
      var checkedEN = true;
    }

    // Changing the password only makes sense for
    // users not logged in via facebook
    if( !this.props.initialData.currentUser.fb_login ) {
      var passwordChange = (
        <span>
          <a href="javascript: false" className="bebas text-left white-bg-link" onClick={ this.enablePasswordFields }>{ I18n.t('users.edit.password', {locale: this.props.initialData.locale}) }</a>
          <span ref={ pwdContainer => this.pwdContainer = pwdContainer } className="hidden">
            <h2 className="bebas text-left margin-top-30">{ I18n.t('users.edit.password', {locale: this.props.initialData.locale}) }</h2>
            <label className="bebas text-left" htmlFor="new_user_password">{ I18n.t('users.edit.new_password', {locale: this.props.initialData.locale}) }</label>
            <input disabled={this.state.passwordDisabled } className="full-width" type="password" name="user[password]" id="user_password" autoComplete="off"/>
            <label className="bebas text-left" htmlFor="new_user_password_confirmation">{ I18n.t('users.edit.new_password_confirmation', {locale: this.props.initialData.locale}) }</label>
            <input disabled={this.state.passwordDisabled } className="full-width" type="password" name="user[password_confirmation]" id="user_password_confirmation" autoComplete="off"/>
          </span>
        </span>
      );
    }

    var sendMailNewUser = this.props.initialData.currentUser.notify_new_user;
    var sendMailNewMessage = this.props.initialData.currentUser.notify_new_message;

    if( this.props.initialData.currentUser.id == this.props.initialData.profileUser.id ) {
      editTab = (
        <li className="tabs-menu-item bebas is-active"><a href={ editUrl } className="init-loading" data-tab-id="3">{ I18n.t('users.edit_profile', {locale: this.props.initialData.locale}) }</a></li>
      );
    }

    return (
      <div>
        <div className="row">
          <div className="column full container dark-blue-bg bold header-bar" style={{padding: "0 1em"}}>
            <div className="max-width-1260">
              <h1 className="left white bebas font-size-12 header-bar-title display-inline"><a href={ userUrl } className="white-link init-loading">{ I18n.t('menu.profile', {locale: this.props.initialData.locale}) }</a></h1>
                <a title={ I18n.t('users.show.signout_link_title', {locale: this.props.initialData.locale}) } id="sign_out" rel="nofollow" data-method="delete" href={getLocaleString(this.props.initialData.locale) + "/signout"}>
                  <div className="red-bg signout-button post-infos right white display-inline">
                    { renderPowerOffIcon() }
                  </div>
                </a>

                <a title={ I18n.t('users.show.settings_link_title', {locale: this.props.initialData.locale}) } href={ preferencesUrl }>
                  <div className="signout-button post-infos right white display-inline" style={{borderRight: "none"}}>
                    { renderCogIcon() }
                  </div>
                </a>

                <a title={ I18n.t('users.show.edit_link_title', {locale: this.props.initialData.locale}) } href={ editUrl }>
                  <div className="signout-button post-infos right white display-inline" style={{borderRight: "none"}}>
                    { renderPencilIcon() }
                  </div>
                </a>
            </div>
          </div>
        </div>
                <div className="row">
                  <div className="full column user-profile-infos">
                    <div className="container margin-auto max-width-form center">
                      <form className="edit_user" id={ formID } encType="multipart/form-data" autoComplete="off" action={ userUrl } acceptCharset="UTF-8" method="post">
                        <h2 className="bebas text-left">{ I18n.t('users.edit.language', {locale: this.props.initialData.locale}) }</h2>
                        <div className="column half-small display-inline text-left">
                          <input type="radio" id="radio_de" name="user[language]" value="de" checked={checkedDE} /><label className="display-inline bebas bold" htmlFor="radio_de"> Deutsch</label>
                        </div>
                        <div className="column half-small display-inline text-left">
                          <input type="radio" id="radio_en" name="user[language]" value="en" checked={checkedEN} /><label className="display-inline bebas bold" htmlFor="radio_en"> English</label>
                        </div>

                        <div className="clearfix"></div>


                        <h2 className="bebas text-left margin-top-30">{ I18n.t('users.edit.email_settings', {locale: this.props.initialData.locale}) }</h2>

                        <p className="font-size-09 text-left">{ I18n.t('users.edit.mail_new_message', {locale: this.props.initialData.locale}) }</p>
                        <div style={ {paddingTop: "0"} } className="column half-small display-inline text-left">
                          <input type="radio" id="radio_de" name="user[mail_new_message_in_convo]" value="true" checked={sendMailNewMessage} />
                          <label className="display-inline bebas bold" htmlFor="radio_de"> { I18n.t('users.edit.yes_text', {locale: this.props.initialData.locale}) }</label>
                        </div>
                        <div style={ {paddingTop: "0"} } className="column half-small display-inline text-left">
                          <input type="radio" id="radio_en" name="user[mail_new_message_in_convo]" value="false" checked={!sendMailNewMessage} />
                          <label className="display-inline bebas bold" htmlFor="radio_en"> { I18n.t('users.edit.no_text', {locale: this.props.initialData.locale}) }</label>
                        </div>

                        <div className="clearfix"></div>
                        <div className="margin-bottom-30"></div>


                        <div className="margin-bottom-30"></div>

                        <input ref={ updateUser => this.updateUser = updateUser } type="submit" name="commit" value={ I18n.t('update.save', {locale: this.props.initialData.locale}) } className="big-submit-button white bebas" style={{fontSize: "1em"}} data-disable-with="Please wait..."/>

                        <input type="hidden" name="utf8" value="✓"/>
                        <input type="hidden" name="_method" value="patch"/>
                        <input name="locale" type="hidden" value={ this.props.initialData.locale } />
                        <input type="hidden" name={ this.props.initialData.userForm.csrf_param } value={ this.props.initialData.userForm.csrf_token } />
                      </form>

                      <div className="margin-top-30"></div>
                      <div className="column full text-right">
                        <a href={ userDeleteUrl } className="bebas white-bg-link">{ I18n.t('users.delete', {locale: this.props.initialData.locale}) }</a>
                      </div>

                    </div>
                  </div>
                </div>
      </div>
    );
  }
}
