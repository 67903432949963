'use strict';
import PropTypes from 'prop-types';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import BaseComponent from '../../libs/components/BaseComponent';
import $ from 'jquery';
const queryString = require('query-string');

export default class Filter extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      page: props.page,
      queryString: typeof window !== 'undefined' ? queryString.parse(location.search) : false,
    };
  }

  componentDidMount = () => {
    if (typeof window !== 'undefined') {

    }
  };

  handleCity = (e) => {
    e.preventDefault();

    if (this.props.currentUser.id === -1) {
      e.preventDefault();
			e.stopPropagation();
			var customEvent = new CustomEvent("openModal", { bubbles: true });
      e.target.dispatchEvent( customEvent );
      return null;
    }

    var city = $(e.target).data('city');

    var newQueryString = queryString.parse(location.search);

    if (typeof this.state.queryString.city !== 'undefined' && city.toLowerCase() === this.state.queryString.city.toLowerCase()) {
      delete newQueryString['city'];
    } else {
      newQueryString.city = city.toLowerCase();
    }

    this.updateUrl(newQueryString);
  };

  handleType = (e) => {
    e.preventDefault();

    if (this.props.currentUser.id === -1) {
      e.preventDefault();
			e.stopPropagation();
			var customEvent = new CustomEvent("openModal", { bubbles: true });
      e.target.dispatchEvent( customEvent );
      return null;
    }

    var type = $(e.target).data('type');
    var newQueryString = queryString.parse(location.search);

    if (typeof this.state.queryString.type !== 'undefined' && type.toLowerCase() === this.state.queryString.type.toLowerCase()) {
      delete newQueryString['type'];
    } else {
      newQueryString.type = type.toLowerCase();
    }

    this.updateUrl(newQueryString);
  };

  handleAge = (e) => {
    e.preventDefault();

    if (this.props.currentUser.id === -1) {
      e.preventDefault();
			e.stopPropagation();
			var customEvent = new CustomEvent("openModal", { bubbles: true });
      e.target.dispatchEvent( customEvent );
      return null;
    }

    var age = $(e.target).data('age');
    var newQueryString = queryString.parse(location.search);

    if (typeof this.state.queryString.age !== 'undefined' && age.toLowerCase() === this.state.queryString.age.toLowerCase()) {
      delete newQueryString['age'];
    } else {
      newQueryString.age = age.toLowerCase();
    }

    this.updateUrl(newQueryString);
  };

  searchReset = (e) => {
    e.preventDefault();

    this.updateUrl({
      q: this.state.queryString.q,
      utf8: this.state.queryString.utf8,
    })
  };

  updateUrl = (newQueryString) => {
    var fullCurrentUrl = window.location.href;
    var currentQueryString = window.location.search;
    var partToKeep = fullCurrentUrl.replace(currentQueryString, '');
    var newStringified = queryString.stringify(newQueryString);
    var joinChar = newStringified.indexOf('?') === -1 && partToKeep.indexOf('?') === -1 ? '?' : '';
    var newFullUrl = partToKeep + joinChar + newStringified;
    this.setState({ queryString: newQueryString });

    window.location.href = newFullUrl;
  };

	render() {
    var supportedCities = ['Wien', 'Graz', 'Linz', 'Salzburg', 'Sankt Pölten', 'St. Pölten', 'Innsbruck', 'Klagenfurt', 'Bregenz', 'München', 'Berlin'];
    var filters = "";
    var activeFilters = "";
    var reset = "";
    var userCity = "";
    var showFiltersOnSearch = this.state.page === 'search' && this.props.q !== '' && this.props.q !== 'null';
    var showFiltersOnExplore = this.state.page === 'explore';
    var cityParameter = this.state.page === 'explore' ? this.props.active : this.state.queryString.city;
    if (this.state.page === 'explore' && cityParameter) {
      reset = <a className="dark-blue chip init-loading" href="/explore" style={{ position: 'relative', top: '1px', whiteSpace: 'nowrap' }}>{ I18n.t('explorepage.reset_all_filters', {locale: this.props.locale}) }</a>;
    }
    if (this.state.page === 'search' && typeof this.state.queryString.type !== 'undefined' && this.state.queryString.type !== '') {
      reset = <a className="dark-blue chip init-loading" href="#" onClick={ this.searchReset } style={{ position: 'relative', top: '1px', whiteSpace: 'nowrap' }}>{ I18n.t('explorepage.reset_all_filters', {locale: this.props.locale}) }</a>;
    }

    if (this.props.currentUser.id > 0 && supportedCities.indexOf(this.props.currentUser.location) === -1) {
      userCity = <a className={ "chip init-loading " + (cityParameter === 'current_user_location' ? 'active' : '') } href="#" data-city="current_user_location" onClick={ this.handleCity }>{ this.props.currentUser.location }</a>;
    }

    var types = (
      <span>
        <a className={ "chip init-loading " + (this.state.queryString.type === 'micropost' ? 'active' : '') } data-type="micropost" href="#" onClick={ this.handleType }>{ I18n.t('users.toggler_activities', {locale: this.props.locale}) }</a>
        <a className={ "chip init-loading " + (this.state.queryString.type === 'user' ? 'active' : '') } data-type="user" href="#" onClick={ this.handleType }>{ I18n.t('users.toggler_buddies', {locale: this.props.locale}) }</a>
      </span>
    );
    var cities = (
      <span>
        { userCity }
        <a className={ "chip init-loading " + (cityParameter === 'wien' ? 'active' : '') } href="#" data-city="wien" onClick={ this.handleCity }>{ I18n.t('welcome.city_vienna', {locale: this.props.locale}) }</a>
        <a className={ "chip init-loading " + (cityParameter === 'graz' ? 'active' : '') } href="#" data-city="graz" onClick={ this.handleCity }>Graz</a>
        <a className={ "chip init-loading " + (cityParameter === 'linz' ? 'active' : '') } href="#" data-city="linz" onClick={ this.handleCity }>Linz</a>
        <a className={ "chip init-loading " + (cityParameter === 'salzburg' ? 'active' : '') } href="#" data-city="salzburg" onClick={ this.handleCity }>Salzburg</a>
        <a className={ "chip init-loading " + (cityParameter === 'sankt-poelten' ? 'active' : '') } href="#" data-city="sankt-poelten" onClick={ this.handleCity }>St. Pölten</a>
        <a className={ "chip init-loading " + (cityParameter === 'innsbruck' ? 'active' : '') } href="#" data-city="innsbruck" onClick={ this.handleCity }>Innsbruck</a>
        <a className={ "chip init-loading " + (cityParameter === 'klagenfurt' ? 'active' : '') } href="#" data-city="klagenfurt" onClick={ this.handleCity }>Klagenfurt</a>
        <a className={ "chip init-loading " + (cityParameter === 'bregenz' ? 'active' : '') } href="#" data-city="bregenz" onClick={ this.handleCity }>Bregenz</a>
        <a className={ "chip init-loading " + (cityParameter === 'muenchen' ? 'active' : '') } href="#" data-city="muenchen" onClick={ this.handleCity }>{ I18n.t('welcome.munich', {locale: this.props.locale}) }</a>
        <a className={ "chip init-loading " + (cityParameter === 'berlin' ? 'active' : '') } href="#" data-city="berlin" onClick={ this.handleCity }>Berlin</a>
      </span>
    );
    var ages = (
      <span>
        <a className={ "chip init-loading " + (this.state.queryString.age === '0-15' ? 'active' : '') } href="#" data-age="0-15" onClick={ this.handleAge }>bis 15</a>
        <a className={ "chip init-loading " + (this.state.queryString.age === '16-19' ? 'active' : '') } href="#" data-age="16-19" onClick={ this.handleAge }>16-19</a>
        <a className={ "chip init-loading " + (this.state.queryString.age === '20-25' ? 'active' : '') } href="#" data-age="20-25" onClick={ this.handleAge }>20-25</a>
        <a className={ "chip init-loading " + (this.state.queryString.age === '26-30' ? 'active' : '') } href="#" data-age="26-30" onClick={ this.handleAge }>26-30</a>
        <a className={ "chip init-loading " + (this.state.queryString.age === '31-35' ? 'active' : '') } href="#" data-age="31-35" onClick={ this.handleAge }>31-35</a>
        <a className={ "chip init-loading " + (this.state.queryString.age === '36-40' ? 'active' : '') } href="#" data-age="36-40" onClick={ this.handleAge }>36-40</a>
        <a className={ "chip init-loading " + (this.state.queryString.age === '40-49' ? 'active' : '') } href="#" data-age="40-49" onClick={ this.handleAge }>40-49</a>
        <a className={ "chip init-loading " + (this.state.queryString.age === '50-99' ? 'active' : '') } href="#" data-age="50-99" onClick={ this.handleAge }>50+</a>
      </span>
    );

    if (this.state.page === 'explore') {
      activeFilters = (
        <span>
          { types }
          { cities }
          { ages }
        </span>
      );
    } else if (this.state.page === 'search') {
      activeFilters = (
        <span>
          { types }
          { cities }
          { ages }
        </span>
      );
    }

    if (showFiltersOnSearch || showFiltersOnExplore) {
      filters = (
        <div className="row filter-options-container gray-feed-bg">
          <div className="container full" style={{ maxWidth: '40rem' }}>
            { activeFilters }
            { reset }
          </div>
        </div>
      );
    }

    return (
      filters
    );
  }
}
