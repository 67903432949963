import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';

var renderMap = function() {
	return(
		<svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22">
		  <g fill="none" fillRule="evenodd" transform="translate(1 1)">
		    <path d="M8.26665,19.66665 L13.83465,13.81865 C13.83515,13.81815 13.83565,13.81765 13.83615,13.81715 L14.10165,13.53865 L14.11065,13.51515 C15.36915,12.11965 16.14165,10.27715 16.14165,8.25015 C16.14165,3.90065 12.60765,0.37515 8.25815,0.37515 C3.90915,0.37515 0.37515,3.90065 0.37515,8.25015 C0.37515,10.27715 1.14765,12.11965 2.40615,13.51515 L2.41515,13.53865 L2.68015,13.81715 C2.68065,13.81765 2.68165,13.81815 2.68215,13.81865 L8.25015,19.66665 L8.26665,19.66665 L8.26665,19.66665 Z"/>
		    <path d="M11.0083,8.125 C11.0083,9.644 9.7773,10.875 8.2583,10.875 C6.7393,10.875 5.5083,9.644 5.5083,8.125 C5.5083,6.606 6.7393,5.375 8.2583,5.375 C9.7773,5.375 11.0083,6.606 11.0083,8.125 L11.0083,8.125 Z"/>
		  </g>
		</svg>
  )
}

export default renderMap;
