'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import getLocaleString from '../../helper/GetLocaleString';
import FacebookLogin from '../Elements/FacebookLogin';
import BaseComponent from '../../libs/components/BaseComponent';
import $ from 'jquery';
import I18n from 'i18n-js';

export default class SignUpAndLogin extends BaseComponent {
  constructor() {
    super();
  }

  componentDidMount = () => {
    var that = this;
    $('#standaloneLoginForm').submit(function(){
      $( that.submitLogin ).prop('disabled', true);
    });

    $('#standaloneSignupForm').submit(function(){
      $( that.submitSignUp ).prop('disabled', true);
    });

    $('#standaloneLoginForm').on('ajax:success',function(e, data, status, xhr){
      window.location.reload();
      $( that.submitLogin ).prop('disabled', false);
  	}).on('ajax:error',function(e, xhr){
      $( that.submitLogin ).prop('disabled', false);
      var errors = $.parseJSON(xhr.responseText).errors;
      $("#response-login").html(errors);
  	});

    $('#standaloneSignupForm').on('ajax:success',function(e, data, status, xhr){
      window.location.reload();
      $( that.submitSignUp ).prop('disabled', false);
  	}).on('ajax:error',function(e, xhr){

      $( that.submitSignUp ).prop('disabled', false);
      var errors = $.parseJSON(xhr.responseText).errors;

      $("#response-signup").html(errors.join("<br/>"));
  	});
  };

  handleChangeAccept = () => {
    var $checkbox = $( this.accept );
  };

  handleChangeRememberMe = () => {
  	var $checkbox = $( this.rememberMe );
  };

  toggleForms = (e) => {
    e.preventDefault();
    // console.log("toggle")
    $( this.loginContainer).toggleClass("hidden");
    $( this.signUpContainer).toggleClass("hidden");
  };

  render() {
    // Login
    var signupUrl = getLocaleString(this.props.locale) + "/signup/";

    // SignUp
    var termsUrl = getLocaleString(this.props.locale) + "/terms/";

    return (
      <div className="row">
        {/*//////////////////// Login ////////////////////*/}
        <div ref={ loginContainer => this.loginContainer = loginContainer } className="column full hidden">
		      <div className="container margin-auto max-width-form center">
            <h2 className="dark-blue margin-bottom-10" style={{ fontWeight: 300 }}>{ I18n.t('loginpage.login_heading', {locale: this.props.locale}) }</h2>
            <FacebookLogin locale={ this.props.locale } register={ false } />

						<fieldset className="hr-line"><legend className="dark-blue">{ I18n.t('createaccount.or', {locale: this.props.locale}) }</legend></fieldset>

            <div id="response-login" className="column full red"></div>

			      <form id="standaloneLoginForm" action={this.props.loginForm.action} data-remote="true" acceptCharset="UTF-8" method="post">
              <label className="text-left" htmlFor="session_email">Email</label>
					    <input className="full-width" type="email" name="session[email]" placeholder={ I18n.t('createaccount.your_email', {locale: this.props.locale}) } value={ this.props.loginForm.emailInput } id="session_email" />

					    <label className="text-left" htmlFor="session_password">{ I18n.t('loginpage.password', {locale: this.props.locale}) }</label>
					    <input className="full-width" type="password" placeholder={ I18n.t('createaccount.your_password', {locale: this.props.locale}) } name="session[password]" id="session_password" />

					    <div className="tc-checkbox-container">
					    	<input type="checkbox" ref={ rememberMe => this.rememberMe = rememberMe } name="session[remember_me]" id="remember_me" checked={ true } value="1" onChange={this.handleChangeRememberMe} />
						    <label className="text-left" htmlFor="remember_me">{ I18n.t('loginpage.remember_me', {locale: this.props.locale}) }</label>
						  </div>

              <input name="locale" type="hidden" value={ this.props.locale } />
					    <input type="hidden" name="utf8" value="✓" />
					    <input type="hidden" name={ this.props.loginForm.csrf_param } value={ this.props.loginForm.csrf_token } />
					    <button type="submit" ref={ submitLogin => this.submitLogin = submitLogin } name="commit" className="big-submit-button white display-inline margin-top-25">{ I18n.t('loginpage.login', {locale: this.props.locale}) }</button>
						</form>

            <div className="margin-top-30">
  						<span className="text-center font-size-09 margin-top-30">{ I18n.t("loginpage.no_account", { locale: this.props.locale }) } </span> 
  				    <a href="javascript: false" className="text-center font-size-09 dark-link" onClick={ this.toggleForms }>{ I18n.t("loginpage.createaccount", {locale: this.props.locale}) }</a>
            </div>
					</div>
				</div>



        {/*//////////////////// SignUp ////////////////////*/}
        <div ref={ signUpContainer => this.signUpContainer = signUpContainer } className="column full">
          <div className="container margin-auto max-width-form">
            <h2 className="dark-blue margin-bottom-10" style={{ fontWeight: 300 }}>{ I18n.t('loginpage.create_account_heading', {locale: this.props.locale}) }</h2>
            <div className="center">
              <FacebookLogin locale={ this.props.locale } standalone={ this.props.standalone } register={ true }/>
            </div>
            <fieldset className="hr-line"><legend className="dark-blue">{ I18n.t('createaccount.or', {locale: this.props.locale}) }</legend></fieldset>

            <div id="response-signup" className="column full red"></div>

            <form id="standaloneSignupForm" autoComplete="off" data-remote="true" action={this.props.userForm.action} acceptCharset="UTF-8" method="post">
              <label className="text-left" htmlFor="user_name">Name</label>
              <input className="full-width" type="text" name="user[name]" placeholder={ I18n.t('createaccount.your_name', {locale: this.props.locale}) } value={ this.props.userForm.name } id="user_name" autoComplete="off"/>

              <label className="text-left" htmlFor="user_email">{ I18n.t('sessions.email', {locale: this.props.locale}) }</label>
              <input className="full-width" type="email" name="user[email]" placeholder={ I18n.t('createaccount.your_email', {locale: this.props.locale}) } value={ this.props.userForm.email } id="user_email" autoComplete="off"/>

              <label className="text-left" htmlFor="user_password">{ I18n.t('createaccount.password', {locale: this.props.locale}) }</label>
              <input className="full-width" type="password" name="user[password]" placeholder={ I18n.t('createaccount.your_password', {locale: this.props.locale}) } id="user_password" autoComplete="off"/>

              <div className="tc-checkbox-container">
                <input type="checkbox" ref={ accept => this.accept = accept } value="1" name="user[accepted]" id="user_accepted" onChange={this.handleChangeAccept} />
                <label htmlFor="user_accepted" className="white-bg-link"  dangerouslySetInnerHTML={{__html: I18n.t("loginpage.accept_terms_html", { locale: this.props.locale,
                                                            terms: I18n.t("loginpage.terms_href", {locale: this.props.locale}), link: termsUrl }) }}>
                </label>
              </div>

              <input type="hidden" name="user[language]" value={ this.props.locale } />
              <input type="hidden" name={ this.props.userForm.csrf_param } value={ this.props.userForm.csrf_token } />
              <input type="hidden" name="utf8" value="✓" />
              <div className="center">
                <button type="submit" name="commit" ref={ submitSignUp => this.submitSignUp = submitSignUp } className="big-submit-button margin-top-10 white display-inline bebas">{ I18n.t('loginpage.create_account', {locale: this.props.locale}) }</button>
              </div>
            </form>

            <div className="center">
              <div className="margin-top-30 display-inline font-size-09">{I18n.t('createaccount.already',{locale: this.props.locale})} </div>
              <a className="white-bg-link font-size-09 dark-link" href="javascript: false" onClick={ this.toggleForms }>{I18n.t('loginpage.log_in_here',{locale: this.props.locale})}</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
