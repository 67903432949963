import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';

var renderPlane = function() {
	return(
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 20" width="25" height="20">
			<defs>
				<clipPath clipPathUnits="userSpaceOnUse" id="cp1">
					<path d="M-96 -762L340 -762L340 110L-96 110Z" />
				</clipPath>
			</defs>
			<g clip-path="url(#cp1)">
				<g >
					<g>
						<g>
							<g>
								<path style="fill: none;stroke: #020101;stroke-width: 1;" d="M2.01 2.24L22.58 2.24L9.27 17.97L8.51 9.71L2.01 2.24ZM22.58 2.24L8.51 9.71" />
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
  )
}

export default renderPlane;
