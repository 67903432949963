'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import getLocaleString from '../../helper/GetLocaleString';
import BaseComponent from '../../libs/components/BaseComponent';
import InfoIcon from '../Elements/InfoIcon';
import getFirstName from '../../helper/GetFirstName';
import renderEditIcon from '../svgIcons/edit';
import renderPowerOffIcon from '../svgIcons/powerOff';
import renderCogIcon from '../svgIcons/cog';
import renderPencilIcon from '../svgIcons/pencil';
import MBP from '../../helper/MobileBoilerplate';
import $ from 'jquery';
import I18n from 'i18n-js';

export default class UsersEditView extends BaseComponent {
  constructor(props) {
    super();
    this.state = {
     passwordDisabled: true,
     lat: '',
     lon: '',
     suggestedLocations: [],
    };
  }

  componentDidMount = () => {
    let geolocationCookie = JSON.parse(this.getCookie('geolocation'));
    let that = this;

    $( this.previewImage ).css({'background-image': 'url(' + this.props.initialData.currentUser.avatar + ')'});
    this.fileInput.addEventListener('change', this.handleFileSelect, false);

    var textarea = this.userAbout;
    new MBP.autogrow( textarea );
    var customEvent = new CustomEvent("input", { bubbles: true });
    textarea.dispatchEvent( customEvent );

    if (typeof geolocationCookie.latitude !== 'undefined' && typeof geolocationCookie.longitude !== 'undefined') {
      this.state.lat = geolocationCookie.latitude;
      this.state.lon = geolocationCookie.longitude;
    }

    if (typeof window !== 'undefined') {
      $(this.autocompleteField).on('input', function(event) {
        that.handleAutocomplete();
      });

      this.handleAutocomplete();

      $(this.autocompleteField).on('focus', function() {
        $(that.resultList).show();
      }).on('blur', function() {
        $(that.resultList).hide();
      });

      $(this.resultList).on('mousedown', function(event) {
        event.preventDefault();
      }).on('click', 'li', function() {
        if ($(this).attr("data-text") !== 'false') {
          $("#user_latitude").val($(this).attr("data-lat"));
          $("#user_longitude").val($(this).attr("data-lon"));
          $(that.autocompleteField).val($(this).attr("data-text")).blur();
        }
      });
    }
  };

  componentWillUnmount = () => {
    this.fileInput.removeEventListener("change", this.handleFileSelect, false);
  };

  chooseImage = (e) => {
    e.preventDefault();
    $( this.fileInput ).click();
  };

  handleFileSelect = (e) => {
    if(typeof FileReader == "undefined") return true;
    var self = this;
    var chooseElement = this.choose;
    var reader = new FileReader();
    var file = e.target.files[0];
    var fileName = file.name.replace( /C:\\fakepath\\/i, '' );
    fileName = this.truncateString( fileName, 20 );

    $( chooseElement ).text(fileName);

    var addImage = $( chooseElement ).text();
    $( chooseElement ).data('text', addImage);

    reader.onload = function(file) {
      self.setState({
        data_uri: file.target.result,
      });

      $( self.previewImage ).css({'background-image': 'url(' + file.target.result + ')',
                                                            'background-size': 'center',
                                                            'background-repeat': 'no-repeat' });
    }.bind(this);

    reader.readAsDataURL(file);
    $( this.cancelImg ).toggleClass('hidden');

    $( this.previewImage ).toggleClass('overlay');
    if( this.micropostTitle ) {
      $( this.micropostTitle ).toggleClass('dark-blue white');
    }
  };

  truncateString = (n, len) => {
    var ext = n.substring(n.lastIndexOf(".") + 1, n.length).toLowerCase();
    var filename = n.replace('.' + ext,'');
    if(filename.length <= len) {
        return n;
    }
    filename = filename.substr(0, len) + (n.length > len ? '[...]' : '');

    return filename + '.' + ext;
  };

  // Cancel an uploaded image
  handleCancel = () => {
    if( $( this.previewImage ).hasClass("previewUserAvatar") ) {
      $( this.previewImage ).css({'background-image': 'url(' + this.props.initialData.currentUser.avatar + ')',
                                                    'background-size': 'center'
                                                  });
    }
    else {
      $( this.previewImage ).css( { 'background': '',
                                                      'background-size': '',
                                                      'background-position': '' } );
    }

    $( this.cancelImg ).toggleClass('hidden');

    $( this.choose ).text( I18n.t('microposts.change_image', {locale: this.props.initialData.locale}) );
    // Deleting this input field deletes the image from the upload queue
    // of the jquery image upload plugin
    $( this.uploadButtonContainer ).empty();

    // This clears and clones the input field. Just to be sure, probably not needed.
    // To Do: Check if it can be removed
    // $( this.fileInput ).replaceWith($( this.fileInput ).val('').clone(true));

    $( this.previewImage ).toggleClass('overlay');
    if( this.micropostTitle ) {
      $( this.micropostTitle ).toggleClass('dark-blue white');
    }
  };

  enablePasswordFields = (e) => {
    e.preventDefault();

    $( this.pwdContainer ).toggleClass("hidden");
    var newPasswordState = !this.state.passwordDisabled;
    this.setState({ passwordDisabled: newPasswordState });
  };

  handleAutocomplete = async() => {
    if (typeof window !== 'undefined') {
      let token = 'pk.eyJ1IjoidGhlYnVkZHkiLCJhIjoiY2p5N2h2b2pkMDEyczNvbnhxZWpmaWpvZSJ9.kl2-D2BS3llvvD5_Qlsfzg';
      let that = this;

      const source = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${that.autocompleteField.value}.json?autocomplete=true&fuzzyMatch=true&proximity=${that.state.lon},${that.state.lat}&access_token=${token}&types=place&language=${that.props.initialData.locale}`);
      const data = await source.json();      
      this.setAutocompletePosition();
      
      $(this.resultList).empty();

      if (typeof data.features !== 'undefined') {
        // this.setState({ suggestedLocations: data.features });
        data.features.forEach((item) => {
          $(that.resultList).append(`<li class="autocomplete-result" data-text="${item.text}" data-lon="${item.geometry.coordinates[0]}" data-lat="${item.geometry.coordinates[1]}">${item.place_name}</li>`);
        });
      } else {
        $(that.resultList).append(`<li class="autocomplete-result" data-text="false">No Results...</li>`);
      }
    }
  };

  getCookie = (c_name) => {
    if (document.cookie.length > 0 ) {
      var c_start = document.cookie.indexOf(c_name + "=");
      if (c_start != -1){
        c_start = c_start + c_name.length + 1;
        var c_end = document.cookie.indexOf(";",c_start);
        if( c_end == -1 ) {
          c_end = document.cookie.length;
        }
        return unescape( document.cookie.substring( c_start, c_end ) );
      }
    }
    return '';
  };

  setAutocompletePosition = () => {
    let that = this;

    $(document).ready(function() {
      let position = $(that.autocompleteField).position();
      let height = $(that.autocompleteField).outerHeight(true);
      let width = $(that.autocompleteField).outerWidth(true);

      $(that.resultList).css({
        top: position.top + height + 3,
        left: position.left,
        width: width,
      });
    });
  };

  select = (e) => {
    // e.preventDefault();
    var input = this.autocompleteField;
    input.focus();
    setTimeout(function(){
      input.selectionStart = 0;
      input.selectionEnd = 999;
    }, 10);

  };

  handleLocationBlur = (e) => {
    // console.log('this is the blur event!!!!', e.target.value);
    const lat = $('#user_latitude').val();
    const lon = $('#user_longitude').val();
    // console.log('values!!!', lat, lon);

    if (e.target.value && e.target.value.length > 0 && !(lat.length > 0) && !(lon.length >0)) {
      // console.log('empty!!!');
      alert(I18n.t('users.edit.choose_city', {locale: this.props.initialData.locale}));
    }
  }

  render() {
    var membership = "";
    // Set css styles the react way
    var imageUploadStyle = {
      height: '0px',
      width: '0px',
      overflow: 'hidden',
      visisbility: 'hidden',
      float: 'right'
    };

    var inputValues = {
      name: this.props.initialData.formData.name ? this.props.initialData.formData.name : "",
      age: this.props.initialData.formData.age ? this.props.initialData.formData.age : "",
      gender: this.props.initialData.formData.gender ? this.props.initialData.formData.gender : "",
      email: this.props.initialData.formData.email ? this.props.initialData.formData.email : "",
      about: this.props.initialData.formData.about ? this.props.initialData.formData.about : "",
      location: this.props.initialData.formData.location ? this.props.initialData.formData.location : "",
      latitude: this.props.initialData.formData.latitude ? this.props.initialData.formData.latitude : "",
      longitude: this.props.initialData.formData.longitude ? this.props.initialData.formData.longitude : "",
    };

    var userUrl = getLocaleString(this.props.initialData.locale) + "/users/" + this.props.initialData.currentUser.id;
    var userDeleteUrl = getLocaleString(this.props.initialData.locale) + "/users/" + this.props.initialData.currentUser.id + "/deleteAccount";
    var editUrl = getLocaleString(this.props.initialData.locale) + "/users/" + this.props.initialData.currentUser.id + "/edit";
    var preferencesUrl = getLocaleString(this.props.initialData.locale) + "/users/" + "preferences/" + this.props.initialData.currentUser.id;
    var formID = "edit_user_" + this.props.initialData.currentUser.id;
    var firstName = getFirstName( this.props.initialData.currentUser.name );
    var passwordChange = "";
    var editTab = "";

    // Check the right radio button depending on the language
    if( this.props.initialData.currentUser.language == "de" ) {
      var checkedDE = true;
      var checkedEN = false;
    }
    else {
      var checkedDE = false;
      var checkedEN = true;
    }

    var now = new Date();
    now = new Date(now.getTime() + (now.getTimezoneOffset() * 60000));
    var premiumDate = new Date(this.props.initialData.currentUser.premium);
    var premiumDay = premiumDate.getDate().toString().length === 1 ? '0' + premiumDate.getDate().toString() : premiumDate.getDate().toString();
    var premiumMonth = premiumDate.getMonth().toString().length === 1 ? '0' + (premiumDate.getMonth() + 1).toString() : premiumDate.getMonth().toString();
    var premiumYear = premiumDate.getFullYear().toString();
    var premiumHours = premiumDate.getHours().toString().length === 1 ? '0' + premiumDate.getHours() : premiumDate.getHours().toString();
    var premiumMinutes = premiumDate.getMinutes().toString().length === 1 ? '0' + premiumDate.getMinutes() : premiumDate.getMinutes().toString();

    if (premiumDate < now) {
      membership = (
        <div className="full-width" style={{textAlign: "left"}}>{ I18n.t('users.edit.standard', {locale: this.props.initialData.locale}) } <a className="bebas red" href="/premium" style={{textDecoration: 'underline'}}>{ I18n.t('users.edit.show_premium', {locale: this.props.initialData.locale}) }</a></div>
      );
    } else {
      membership = (
        <div className="full-width" style={{textAlign: "left"}}>
          <span className="red">PREMIUM</span> ({ I18n.t('users.edit.valid_until', {locale: this.props.initialData.locale}) } {`${premiumDay}.${premiumMonth}.${premiumYear} ${premiumHours}:${premiumMinutes}`}) <InfoIcon text={ I18n.t('users.edit.no_auto', {locale: this.props.initialData.locale}) } />
        </div>
      );
    }

    // Changing the password only makes sense for
    // users not logged in via facebook
    if( !this.props.initialData.currentUser.fb_login ) {
      var passwordChange = (
        <span>
          <a href="javascript: false" className="bebas text-left white-bg-link" onClick={ this.enablePasswordFields }>{ I18n.t('users.edit.password', {locale: this.props.initialData.locale}) }</a>
          <span ref={ pwdContainer => this.pwdContainer = pwdContainer } className="hidden">
            <h2 className="bebas text-left margin-top-30">{ I18n.t('users.edit.password', {locale: this.props.initialData.locale}) }</h2>
            <label className="bebas text-left" htmlFor="new_user_password">{ I18n.t('users.edit.new_password', {locale: this.props.initialData.locale}) }</label>
            <input disabled={this.state.passwordDisabled } className="full-width" type="password" name="user[password]" id="user_password" autoComplete="off"/>
            <label className="bebas text-left" htmlFor="new_user_password_confirmation">{ I18n.t('users.edit.new_password_confirmation', {locale: this.props.initialData.locale}) }</label>
            <input disabled={this.state.passwordDisabled } className="full-width" type="password" name="user[password_confirmation]" id="user_password_confirmation" autoComplete="off"/>
          </span>
        </span>
      );
    }

    var sendMailNewUser = this.props.initialData.currentUser.notify_new_user;
    var sendMailNewMessage = this.props.initialData.currentUser.notify_new_message;

    if( this.props.initialData.currentUser.id == this.props.initialData.profileUser.id ) {
      editTab = (
        <li className="tabs-menu-item bebas is-active"><a href={ editUrl } className="init-loading" data-tab-id="3">{ I18n.t('users.edit_profile', {locale: this.props.initialData.locale}) }</a></li>
      );
    }

    return (
      <div>
        <div className="row">
          <div className="column full container dark-blue-bg bold header-bar" style={{padding: "0 1em"}}>
            <div className="max-width-1260">
              <h1 className="left white bebas font-size-12 header-bar-title display-inline"><a href={ userUrl } className="white-link init-loading">{ I18n.t('menu.profile', {locale: this.props.initialData.locale}) }</a></h1>
                <a title={ I18n.t('users.show.signout_link_title', {locale: this.props.initialData.locale}) } id="sign_out" rel="nofollow" data-method="delete" href={getLocaleString(this.props.initialData.locale) + "/signout"}>
                  <div className="red-bg signout-button post-infos right white display-inline">
                    { renderPowerOffIcon() }
                  </div>
                </a>

                <a title={ I18n.t('users.show.settings_link_title', {locale: this.props.initialData.locale}) } href={ preferencesUrl }>
                  <div className="signout-button post-infos right white display-inline" style={{borderRight: "none"}}>
                    { renderCogIcon() }
                  </div>
                </a>

                <a title={ I18n.t('users.show.edit_link_title', {locale: this.props.initialData.locale}) } href={ editUrl }>
                  <div className="signout-button post-infos right white display-inline" style={{borderRight: "none"}}>
                    { renderPencilIcon() }
                  </div>
                </a>
            </div>
          </div>
        </div>


                <div className="row">
                  <div className="full column user-profile-infos profile-shadow">
                    <div className="container margin-auto max-width-form center">
                      <form className="edit_user" id={ formID } encType="multipart/form-data" autoComplete="off" action={ userUrl } acceptCharset="UTF-8" method="post">

                        <label className="bebas text-left">{ I18n.t('users.avatar', {locale: this.props.initialData.locale}) }</label>
                        <div ref={ previewImage => this.previewImage = previewImage } className="previewUserAvatar"></div>
                        <div ref={ cancelImg => this.cancelImg = cancelImg } className="cancel-avatar hidden bebas-bold white dark-blue-bg" onClick={this.handleCancel}></div>

                        <div className="clearfix"></div>
                        <a ref={ choose => this.choose = choose } href="javascript: false" className="bebas white-bg-link choose-avatar post-infos" onClick={ this.chooseImage }><br />{ renderEditIcon() } { I18n.t('microposts.change_image', {locale: this.props.initialData.locale}) }</a>

                        <label className="bebas text-left margin-top-25">{ I18n.t('users.edit.gender', {locale: this.props.initialData.locale}) }</label>
                        <fieldset>
                          <div className="column third">
                            <input type="radio" id="male" name="user[gender]" value="M" checked={inputValues.gender === "M"} />
                            <label htmlFor="male" style={{display: 'inline-block', }}> { I18n.t('users.edit.male', {locale: this.props.initialData.locale}) }</label>
                          </div>
                          <div className="column third">
                            <input type="radio" id="female" name="user[gender]" value="F" checked={inputValues.gender == "F"} />
                            <label htmlFor="female" style={{display: 'inline-block'}}> { I18n.t('users.edit.female', {locale: this.props.initialData.locale}) }</label>
                          </div>
                          <div className="column third">
                            <input type="radio" id="female" name="user[gender]" value="X" checked={inputValues.gender == "X"} />
                            <label htmlFor="female" style={{display: 'inline-block'}}> { I18n.t('users.edit.non_binary', {locale: this.props.initialData.locale}) }</label>
                          </div>
                        </fieldset>

                        <label className="bebas text-left" htmlFor="user_name">{ I18n.t('users.edit.name', {locale: this.props.initialData.locale}) }</label>
                        <input className="full-width" type="text" value={ inputValues.name } name="user[name]" id="user_name"/>

                        <label className="bebas text-left" htmlFor="user_age">{ I18n.t('users.edit.age', {locale: this.props.initialData.locale}) }</label>
                        <input className="full-width" type="number" value={ inputValues.age } name="user[age]" id="user_age"/>

                        <label className="bebas text-left" htmlFor="user_location">{ I18n.t('users.edit.location', {locale: this.props.initialData.locale}) }</label>

                        <input ref={ autocompleteField => this.autocompleteField = autocompleteField } className="full-width" type="text" value={ inputValues.location } onFocus={ this.select } onBlur={ this.handleLocationBlur } name="user[location]" id="user_location"/>
                        <ul ref={ resultList => this.resultList = resultList } id="autocomplete-results-list"></ul>

                        <label className="bebas text-left" htmlFor="user_about">{ I18n.t('createaccount.about', {locale: this.props.initialData.locale}) }</label>
                        <textarea ref={ userAbout => this.userAbout = userAbout } className="full-width" rows="3" name="user[about]" id="user_about" placeholder={ I18n.t('users.edit.about_placeholder', {locale: this.props.initialData.locale}) }>
                          { inputValues.about }
                        </textarea>

                        <label className="bebas text-left">{ I18n.t('users.edit.membership', {locale: this.props.initialData.locale}) }</label>
                        { membership }

                        <label className="bebas text-left" htmlFor="user_email">{ I18n.t('users.edit.email', {locale: this.props.initialData.locale}) }</label>
                        <input className="full-width" type="email" value={ inputValues.email } name="user[email]" id="user_email"/>

                        <div className="margin-bottom-10"></div>

                        { passwordChange }

                        <div className="margin-bottom-10"></div>

                        <input ref={ updateUser => this.updateUser = updateUser } type="submit" style={ {fontSize: "1.2em"} } name="commit" value={ I18n.t('update.save', {locale: this.props.initialData.locale}) } className="big-submit-button white margin-bottom-70 bebas" data-disable-with="Please wait..."/>

                        <div ref={ uploadButtonContainer => this.uploadButtonContainer = uploadButtonContainer } className="hidden uploadButtonContainer"></div>
                        <input ref={ fileInput => this.fileInput = fileInput } style={imageUploadStyle} className="hidden" type="file" name="user[avatar]" id="user_avatar" />
                        <input type="hidden" name="user[latitude]" id="user_latitude" value={ inputValues.latitude } />
                        <input type="hidden" name="user[longitude]" id="user_longitude" value={ inputValues.longitude } />
                        <input type="hidden" name="utf8" value="✓"/>
                        <input type="hidden" name="_method" value="patch"/>
                        <input name="locale" type="hidden" value={ this.props.initialData.locale } />
                        <input type="hidden" name={ this.props.initialData.userForm.csrf_param } value={ this.props.initialData.userForm.csrf_token } />
                      </form>
                    </div>
                  </div>
                </div>



      </div>
    );
  }
}
