'use strict';
import React from 'react';
import BaseComponent from '../../libs/components/BaseComponent';
import render from 'preact-render-to-string';
import { h } from 'preact';
import HeaderBar from '../Elements/HeaderBar';
import Feed from './Feed';
import Modal from '../Elements/Modal';
import getFirstName from '../../helper/GetFirstName';
// import SmallHeaderDisplayAd from '../Elements/Ads/SmallHeaderDisplayAd';
import I18n from 'i18n-js';

export default class FeedIndexView extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
			isModalOpen: false
    };
  }

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    // console.log('debug raw data!!!!!!!!', this.props.initialData.affiliates);
    var headerBar = '';
    if (this.props.hideHeader !== true) {
      headerBar = <HeaderBar latitude={ this.props.initialData.lat } longitude={ this.props.initialData.lon }
                 location={ this.props.initialData.location } locale={ this.props.initialData.locale }
                 hashtag={ this.props.initialData.hashtag } hiddenOnSmall={ true }
                 micropostForm={ this.props.initialData.micropostForm } hideLocation={ this.props.hideLocation }
                 loginForm={ this.props.initialData.loginForm } userForm={ this.props.initialData.userForm }
                 loginPath={ this.props.initialData.loginPath } currentUser={ this.props.initialData.currentUser } />
    }

    return (
      <div className="row">
        <Modal isOpen={this.state.isModalOpen} closeFunction={ this.closeModal }>
          <div className="new-features">
            <img src="assets/new_features_header.jpg" className="margin-bottom-20"/>
            <p className="text-left dark-blue" dangerouslySetInnerHTML={ {__html: I18n.t('new_features.intro', {locale: this.props.initialData.locale, user: getFirstName(this.props.initialData.currentUser.name)}) }}></p><br/>

            <ul>
              <li dangerouslySetInnerHTML={ {__html: I18n.t('new_features.first', {locale: this.props.initialData.locale}) }}></li>
              <li dangerouslySetInnerHTML={ {__html: I18n.t('new_features.second', {locale: this.props.initialData.locale}) }}></li>
              <li dangerouslySetInnerHTML={ {__html: I18n.t('new_features.third', {locale: this.props.initialData.locale}) }}></li>
              <li dangerouslySetInnerHTML={ {__html: I18n.t('new_features.fourth', {locale: this.props.initialData.locale}) }}></li>
            </ul>

            <button className="big-submit-button bebas margin-top-10" style={{padding: "0em"}} onClick={ this.closeModal }>{ I18n.t('new_features.button', {locale: this.props.initialData.locale}) }</button>
            <p className="font-size-08 margin-bottom-20" style={{marginTop: "5px"}}>{  }</p>
          </div>
        </Modal>

        { headerBar }

        {/*<div className="margin-top-15" style={{ maxWidth: "45rem", margin: "0 auto" }}>
            <div className="column container full">
              <SmallHeaderDisplayAd />
            </div>
        </div> */}

        <div className="row">
          <div className="column full gray-feed-bg">
            <Feed microposts={ this.props.initialData.mixedResults } micropostForm={ this.props.initialData.micropostForm }
                           latitude={ this.props.initialData.lat } longitude={ this.props.initialData.lon } currentUrl={ this.props.initialData.currentUrl }
                           location={ this.props.initialData.location } lastPage={ this.props.initialData.lastPage } filters={ false } affiliates={ this.props.initialData.affiliates }
                           locale={ this.props.initialData.locale } currentUser={ this.props.initialData.currentUser } loginForm={ this.props.initialData.loginForm }
                           hideSearch={ this.props.hideSearch } userForm={ this.props.initialData.userForm } loginPath={ this.props.initialData.loginPath }
                           hideLocation={ this.props.hideLocation } contactRequestForm={ this.props.initialData.contactRequestForm } hideToggler={ true }
                           screenWidth={ this.props.screenWidth } totalPages={ this.props.initialData.totalPages }
                           currentPage={ this.props.initialData.currentPage } />

          </div>

          <div className="clearfix"></div>
        </div>

        <div className="clearfix"></div>
      </div>
    );
  }
}
