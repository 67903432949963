import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';

var renderSearchFullIcon = function() {
	return(
		<svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22">
		  <path fillRule="evenodd" stroke="#1e3646" d="M102.098353,22.8380523 C102.098353,18.2937457 105.793746,14.5993794 110.337026,14.5993794 C114.879279,14.5993794 118.574672,18.2937457 118.574672,22.8380523 C118.574672,27.3823589 114.879279,31.0767252 110.337026,31.0767252 C105.793746,31.0767252 102.098353,27.3823589 102.098353,22.8380523 M117.304894,29.0319412 C118.774839,27.3803059 119.673025,25.2143952 119.673025,22.8380523 C119.673025,17.6891383 115.483887,13.5 110.337026,13.5 C105.190165,13.5 101,17.6891383 101,22.8380523 C101,27.9869663 105.190165,32.1761046 110.337026,32.1761046 C112.713369,32.1761046 114.879279,31.2738128 116.527835,29.8079737 L121.059728,34.3398663 C121.168537,34.4466221 121.309167,34.5 121.449797,34.5 C121.589401,34.5 121.730031,34.4466221 121.836787,34.3398663 C122.051325,34.1253282 122.051325,33.7783719 121.836787,33.5638338 L117.304894,29.0319412 Z" transform="translate(-100 -13)"/>
		</svg>
  )
}

export default renderSearchFullIcon;
