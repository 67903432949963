'use strict';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import getLocaleString from '../../helper/GetLocaleString';
import User from './User';
import MicropostForm from '../Microposts/MicropostForm';
import DummyPostAnimated from '../Microposts/DummyPostAnimated';
import Modal from '../Elements/Modal'
import renderLogo from '../svgIcons/logo';
import renderSearchIcon from '../svgIcons/search';
import BaseComponent from '../../libs/components/BaseComponent';
import RangeSlider from '../Elements/RangeSlider';
import SignUpAndLogin from '../Elements/SignUpAndLogin';
import FeedToggler from '../Elements/FeedToggler';
// import NativeFeedAd from '../Elements/Ads/NativeFeedAd';
import I18n from 'i18n-js';
import Masonry from 'react-masonry-css';
import { getParams } from '../../helper/GetParams';
const breakpointColumnsObj = {
  default: 1,
  10000: 4,
  1150: 3,
  850: 2,
  545: 1
};

var timeoutID = undefined;

export default class UserFeed extends BaseComponent {
	constructor(props) {
    super(props);

    var q = "";
		if( typeof window !== 'undefined' ) {
			if( window.location.href.indexOf("q=") !== -1 ) {
				var params = getParams( window.location.href );
				q = params.q;
			}
		}

    this.state = {
			users: props.users ? props.users : [],
			url: props.currentUrl ? props.currentUrl : "",
			isModalOpen: false,
			layoutComplete: false,
      searchQuery: q,
    };
  }

	componentDidMount = () => {
		document.addEventListener("setConversationId", this.setConversationId, false);

		document.addEventListener("locationChanged", this.handleLocationChange, false);

		document.addEventListener("openModal", this.openModal, false);
    document.addEventListener("closeModal", this.closeModal, false);

    this.setState({ layoutComplete: true });
	};

	componentWillUnmount = () => {
		window.removeEventListener("setConversationId", this.setConversationId, false);

		window.removeEventListener("locationChanged", this.handleLocationChange, false);

	  window.removeEventListener("openModal", this.openModal, false);
	  window.removeEventListener("closeModal", this.closeModal, false);

		// Clear setIntervall, otherwise present page polls forever when using turbolinks
		window.clearInterval(timeoutID);
	};

	setConversationId = (e) => {
		var data = e.detail.data;
		var users = this.state.users;

		users = users.map( function( micropost ) {
			if( micropost.id == data.micropost_id ) {
				micropost.conversation_infos.id = data.id;
			}

      return micropost
		});

		this.setState({ users: users });
	};

	handleLocationChange = (e) => {
    var url = this.state.url.split("?")[0];

    var pushStatePath = url.replace(window.location.origin, '');
    window.history.pushState({}, null, pushStatePath);

    // window.location.reload(false);
	};

	openModal = (e) => {
    e.preventDefault();
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

	render() {
		var hashtag = "";
		var showLoader = "";
		var showFeed = " hidden";
    var toggler = "";
    var youToo = "";
    var usersNotice = "";
    var editOwnUrl = getLocaleString(this.props.locale) + "/users/" + this.props.currentUser.id + "/edit";

    if(!this.props.currentUser.about ||
       !this.props.currentUser.gender ||
       !this.props.currentUser.age ||
       !this.props.currentUser.location ||
       !this.props.currentUser.user_infos.avatar
    ) {
      youToo = (
        <span>
          { I18n.t('users.notice_you_too', {locale: this.props.locale}) } <a href={editOwnUrl}>{ I18n.t('users.show.edit', {locale: this.props.locale}) }</a>
        </span>
        );
    }

    if (this.props.hideNotice !== true && false) {
      usersNotice = (
        <div className="" style={{textAlign: "center"}}>
          <div style={{display: "inline-block", backgroundColor: "rgba(247, 245, 123, 0.3)", marginTop: "1rem", padding: "0.5rem", border: "1px solid #F7F57B"}}>
            { I18n.t('users.show_notice', {locale: this.props.locale}) } { youToo }
          </div>
        </div>
      );
    }

    if (this.props.hideToggler !== true) {
      toggler = (
        <FeedToggler currentPage="buddies" locale={this.props.locale} isSearch={ this.props.isSearch } currentUser={ this.props.currentUser } />
      );
    }

		// Replace "no title" with default title
		if( this.state.users ) {
      var adIndex = 1;
			var userNodes = this.state.users.map( function ( user ) {
				if(!user.title) {
					user.title = I18n.t('microposts.no_title', {locale: this.props.locale});
				}

        if ([3, 8, 15, 20].indexOf(adIndex + 1) !== -1) {
          adIndex++;

          return (
            <div>
              {/* <NativeFeedAd key={adIndex} /> */ }
              <User user={ user }
    			  									locale={ this.props.locale }
    			                    key={user.id}
    													standalone={ this.props.standalone }
    			                    currentUser={ this.props.currentUser }
    			                    contactRequestForm={ this.props.contactRequestForm } />
            </div>
          );
        } else {
          adIndex++;

          return <User user={ user }
  			  									locale={ this.props.locale }
  			                    key={user.id}
  													standalone={ this.props.standalone }
  			                    currentUser={ this.props.currentUser }
  			                    contactRequestForm={ this.props.contactRequestForm } />
        }
			}.bind(this));
		}

		if( this.props.currentUser.guest ) {
      var micropostFormOrSignup = (
        <span>
          <SignUpAndLogin  standalone={ true }
	                         loginForm={ this.props.loginForm }
	                         userForm={ this.props.userForm }
	                         loginPath={ this.props.loginPath }
													 locale={ this.props.locale } />
        </span>
      );
    }
    else {
      var micropostFormOrSignup = (
        <span>
          <MicropostForm latitude={ this.props.latitude }
                         longitude={ this.props.longitude }
                         location={ this.props.location }
                         locale={ this.props.locale }
                         hashtag={ this.props.hashtag }
                         micropostForm={ this.props.micropostForm }
                         currentUser={ this.props.currentUser } />
        </span>
      );
    }

		if( this.props.hashtag ) {
			var hashtag = "#" + this.props.hashtag;
		}

		if( this.state.layoutComplete ) {
			showFeed = "";
			showLoader = " hidden"
		}

    var search = '';

    if (this.props.hideSearch !== true && false) {
      search = (
        <div>
          <div className="column full">
            <div id="search-container">

              <form action="/searchUsers" acceptCharset="UTF-8" method="get">
                <h5 style={{marginLeft: "4px"}}>{ I18n.t('users.search_for_members', {locale: this.props.locale}) }</h5>
                <span className="icon" dangerouslySetInnerHTML={ renderSearchIcon() }></span>
                <input ref={ searchBox => this.searchBox = searchBox } type="text" name="q" id="search" style={{fontWeight: "normal"}} placeholder={ I18n.t('users.search_placeholder', {locale: this.props.locale}) } value={this.state.searchQuery} />
                <input type="submit" className="bebas submit-search" value={ I18n.t('explorepage.search', {locale: this.props.locale}) } />
                <input name="locale" type="hidden" value={ this.props.locale } />
                <input name="utf8" type="hidden" value="✓" />
              </form>

              {/* <RangeSlider /> */}
            </div>
          </div>

          <div className="clearfix"></div>
        </div>
      );
    }

		// There are microposts to show, render them
		if( userNodes.length > 0 ){
		  return (
		  	<div>
					<Modal isOpen={this.state.isModalOpen} closeFunction={ this.closeModal }>
		        { micropostFormOrSignup }
		      </Modal>

          { toggler }
          { search }


					<div ref={ feedContainer => this.feedContainer = feedContainer } className="max-width-1260" style={{marginBottom: "2em"}} key={ 0 }>
						<div ref={ loader => this.loader = loader } className={ "container" + showLoader}>
							{/* <DummyPostAnimated locale={ this.props.locale } />
							<DummyPostAnimated locale={ this.props.locale } />
							<DummyPostAnimated locale={ this.props.locale } />
              <DummyPostAnimated locale={ this.props.locale } /> */}
              <div className="clearfix"></div>
						</div>

						<Masonry
						  breakpointCols={breakpointColumnsObj}
						  className={ "micropost-list" + showFeed }
						  columnClassName="">
					    { userNodes }
						</Masonry>

            { usersNotice }
			    </div>
			  </div>
		  );
		}
		// No posts here, render dummy posts and a nice notice
		else {
			return (
				<div className="center">
					<Modal isOpen={this.state.isModalOpen} closeFunction={ this.closeModal }>
		        { micropostFormOrSignup }
		      </Modal>

          { search }

					<div className="row">
		        <div className="column full container">
							<div className="margin-top-25 gray margin-bottom-20 center">{ renderLogo() }</div>
						</div>
					</div>

          { usersNotice }
			  </div>
		  );
		}
	}
}
