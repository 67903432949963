'use strict';
import PropTypes from 'prop-types';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import renderBuddyAvatar from '../svgIcons/buddyAvatar';
import PremiumTeaser from '../Elements/PremiumTeaser';
import Modal from './Modal';
import BaseComponent from '../../libs/components/BaseComponent';
import $ from 'jquery';

export default class UserProfileImage extends BaseComponent {
  static propTypes = { width: PropTypes.number, height: PropTypes.number };
  static defaultProps = { width: 40, height: 40, micropost: {user_infos: []} };

  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      modalEnabled: props.modalEnabled ? props.modalEnabled : false,
      avatarUrl: typeof this.props.micropost.user_infos != "undefined" ? this.props.micropost.user_infos.avatar : ""
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
		if (this.props.avatarUrl !== nextProps.avatarUrl || this.props.isModalOpen !== nextProps.isModalOpen) {
			return true;
		}
		
		if (this.state.avatarUrl !== nextState.avatarUrl || this.state.isModalOpen !== nextState.isModalOpen) {
			return true;
		}

		return false;
	}

  handleError = (e) => {
    $(e.target).addClass("hidden");
    $(this.svgFallback).removeClass("hidden");
    $(this.backgroundContainer).css({backgroundColor: "#1e3646"});
    this.setState({modalEnabled: false});
  };

  openModal = (e) => {
    if( this.state.modalEnabled && this.state.avatarUrl != "" ) {
      e.preventDefault();
      this.setState({ isModalOpen: true });
    }
    else {
      return
    }
  };

  closeModal = (e) => {
    if( this.state.modalEnabled ) {
      e.preventDefault();
      this.setState({ isModalOpen: false });
    }
    else {
      return
    }
  };

  render() {
    var now = new Date();
    now = new Date(now.getTime() + (now.getTimezoneOffset() * 60000));
    var premiumDate = this.props.micropost ? new Date(this.props.micropost.user_infos.premium) : new Date(0);
    var premiumDateCurrentUser = this.props.currentUser ? new Date(this.props.currentUser.user_infos.premium) : new Date(0);
    var premiumNotice = "";
    var premiumStyle = {
      width: this.props.width + 'px',
      height: this.props.height + 'px',
    };

    if( this.props.micropost.user_infos ) {
      var imageWidth = '100%';
      // avatar in full size for everyone!
      /* if (premiumDateCurrentUser < now) {
        imageWidth = '80px';
      } */

      var modal = (
        <div style={{width: "100%", maxWidth: "680px", backgroundColor: "white", padding: "2rem 0"}}>
          <img style={{maxWidth: imageWidth, height: "auto"}} src={this.props.micropost.user_infos.avatar_original} />
        </div>
      );
    }

    if( this.state.avatarUrl != "" ) {
      var userAvatarStyle = {
        width: this.props.width + 'px',
        height: this.props.height + 'px'
      };

      if( this.state.modalEnabled ) {
        userAvatarStyle["cursor"] = "pointer";

        // avatar in full size for everyone!
        /* if (premiumDateCurrentUser < now) {
          premiumNotice = (
            <PremiumTeaser locale={ this.props.locale || "de" } highlight="picSize" />
          );
        } */
      }

      if (premiumDate >= now) {
        premiumStyle = {
            border: '3px solid #E8D176',
            width: this.props.width + 'px',
            height: this.props.height + 'px',
        };
      }

      return (
        <span>
          <Modal isOpen={this.state.isModalOpen} closeFunction={ this.closeModal }>
            { modal }
            { premiumNotice }
          </Modal>

          <div className="user-image-container convo-shadow" onClick={ this.openModal }>
            <div ref={ backgroundContainer => this.backgroundContainer = backgroundContainer } className="user-avatar" style={userAvatarStyle}>
              <span>
                <img ref={ userAvatar => this.userAvatar = userAvatar } className="user-image" style={ premiumStyle } src={ this.state.avatarUrl } onError={ this.handleError } />
                <div ref={ svgFallback => this.svgFallback = svgFallback } className="gray hidden" style={{ width: this.props.width + 'px', height: this.props.height + 'px' }}>
                  <div style={{ width: this.props.width + 'px', height: this.props.height + 'px'  }}>{ renderBuddyAvatar() }</div>
                </div>
              </span>
            </div>
          </div>
        </span>
      )
    }
    else {
      var background = this.props.dummy === true ? '#f4f4f4' : '#1e3646';
      var userAvatarStyle = {
        width: this.props.width + 'px',
        height: this.props.height + 'px',
        backgroundColor: background,
      };
      return (
        <span>
          <Modal isOpen={this.state.isModalOpen} closeFunction={ this.closeModal }>
            { modal }
          </Modal>

          <div className="user-image-container convo-shadow" onClick={ this.openModal }>
            <div ref={ backgroundContainer => this.backgroundContainer = backgroundContainer } className="user-avatar" style={userAvatarStyle}>
              <span>
                <div ref={ svgFallback => this.svgFallback = svgFallback } className="gray" style={{ width: this.props.width + 'px', height: this.props.height + 'px' }}>
                  <div style={{ width: this.props.width + 'px', height: this.props.height + 'px'  }}>{ renderBuddyAvatar() }</div>
                </div>
              </span>
            </div>
          </div>
        </span>
      )
    }
  }
}
