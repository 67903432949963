import getLocaleString from './GetLocaleString';

var LinkHashtags = function( locale, text, standalone ) {
	// Hashtag regexp translated from the ruby version in /vendor/gems/simple_hashtag-0.1.9/lib/simple_hashtag/hashtag.rb
	// Don't forget to change both regexp in case the current regexp doesn't fit your needs
	// Differences between the expression for ruby and javascript:
	// https://stackoverflow.com/questions/8783721/differences-between-ruby-1-9-and-javascript-regexp
	var hashtag_regexp = /(?:\s|^)(#(?!(?:\d+|\w+?_|_\w+?)(?:\s|$))([A-Za-z0-9-_äöüÄÖÜß]*))/gi;
	function escapeHtml(unsafe) {
		if (typeof unsafe === 'undefined' || unsafe === null) {
		    return '';
		}

    return unsafe
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#x27;")
      .replace(/\//g, "&#x2F;");
	}

	return escapeHtml(text).replace( hashtag_regexp, function(match){
		var text = escapeHtml(match);
		var tag = escapeHtml(match.replace("#",""));
		var openTarget = standalone ? "_blank" : "_self";
		var element = ' <a class="my-links" href="' + getLocaleString(locale) + '/hashtags/' + tag.trim() + '" target="' + openTarget + '">' + text.trim() + '</a>';

    return element;
	});
};

export default LinkHashtags;
