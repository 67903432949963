'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import getLocaleString from '../../helper/GetLocaleString';
import FacebookLogin from '../Elements/FacebookLogin';
import BaseComponent from '../../libs/components/BaseComponent';
import $ from 'jquery';
import I18n from 'i18n-js';

export default class SessionsNewView extends BaseComponent {
	constructor() {
    super();
  }

  handleChange = () => {
  	var $checkbox = $( this.rememberMe );
  };

	render() {
    var signupUrl = getLocaleString(this.props.initialData.locale) + "/signup/";

		return (
			<div className="row">
				<div className="full container" style={{ maxWidth: '850px'}}>
					<div className="column half text-center hide-for-small" style={{ padding: '15px 0'}}>
						<h3 className="bebas dark-blue">{ I18n.t('createaccount.facts_heading', {locale: this.props.initialData.locale}) }</h3><br />
						<ul className="signup-check" style={{listStyle: ''}}>
							<li>{ I18n.t('createaccount.free', {locale: this.props.initialData.locale}) }</li>
							<li>{ I18n.t('createaccount.real_people', {locale: this.props.initialData.locale}) }</li>
							<li>{ I18n.t('createaccount.explore', {locale: this.props.initialData.locale}) }</li>
							<li>{ I18n.t('createaccount.no_ads', {locale: this.props.initialData.locale}) }</li>
							<li>{ I18n.t('createaccount.privacy', {locale: this.props.initialData.locale}) }</li>
						</ul>
					</div>

		      <div className="column half">
			      <div className="container margin-auto max-width-form center">


				      <form action={getLocaleString(this.props.initialData.locale) + this.props.initialData.loginForm.action} data-remote="false" acceptCharset="UTF-8" method="post">
						    <label className="text-left" htmlFor="session_email">{ I18n.t('sessions.email', {locale: this.props.initialData.locale}) }</label>
						    <input className="full-width" type="email" name="session[email]" placeholder={ I18n.t('createaccount.your_email', {locale: this.props.initialData.locale}) } value={ this.props.initialData.loginForm.emailInput } id="session_email" />

						    <label className="text-left" htmlFor="session_password">{ I18n.t('loginpage.password', {locale: this.props.initialData.locale}) }</label>
						    <input className="full-width" type="password" placeholder={ I18n.t('createaccount.your_password', {locale: this.props.initialData.locale}) } name="session[password]" id="session_password" />

						    <div className="tc-checkbox-container">
						    	<input type="checkbox" ref={ rememberMe => this.rememberMe = rememberMe } name="session[remember_me]" id="remember_me" checked={ true } value="1" onChange={this.handleChange} />
							    <label className="text-left" htmlFor="remember_me">{ I18n.t('loginpage.remember_me', {locale: this.props.initialData.locale}) }</label>
							  </div>

						    <input type="hidden" name="utf8" value="✓" />
								<input name="locale" type="hidden" value={ this.props.initialData.locale } />
						    <input type="hidden" name={ this.props.initialData.loginForm.csrf_param } value={ this.props.initialData.loginForm.csrf_token } />
						    <button type="submit" name="commit" className="bebas big-submit-button white display-inline margin-top-05">{ I18n.t('loginpage.login', {locale: this.props.initialData.locale}) }</button>
							</form>

							{/* <p className="text-center font-size-12 margin-top-30 margin-bottom-10"	dangerouslySetInnerHTML={{__html: I18n.t("loginpage.newhere_html", { locale: this.props.initialData.locale,
																																																			create: I18n.t("loginpage.createaccount", {locale: this.props.initialData.locale}), link: signupUrl }) }}></p> */}
							<p className="full-width dark-blue text-center font-size-09 margin-top-10"><a className="dark-blue" style={{ textDecoration: 'underline' }} href={getLocaleString(this.props.initialData.locale) + "/password_resets/new"}>{ I18n.t('loginpage.forgotp', {locale: this.props.initialData.locale}) }</a></p>
						</div>

						<div className="container margin-auto max-width-form center" style={{ margin: '4rem auto' }}>
							<fieldset className="hr-line"><legend className="dark-blue">{ I18n.t('createaccount.or', {locale: this.props.initialData.locale}) }</legend></fieldset>
							<br />

							<FacebookLogin locale={ this.props.initialData.locale } register={ false } />
						</div>
					</div>
				</div>
	    </div>
		);
	}
}
