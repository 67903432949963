'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import UserProfileImage from '../Elements/UserProfileImage';
import AutocompleteField from '../Elements/AutocompleteField';
import DateList from './DateList';
import BaseComponent from '../../libs/components/BaseComponent';
import MBP from '../../helper/MobileBoilerplate';
import $ from 'jquery';
import I18n from 'i18n-js';

export default class MicropostForm extends BaseComponent {
  constructor() {
    super();
    this.state = {
      data_uri: null, // URI for an uploaded post image
      micropostFormErrors: '',
      checkable: false,
      sending: false,
      dateIndex: 0,
      timestamp: Math.floor(Date.now() / 1000) // Set timestamp to mark an old version of the view
    };
  }

  componentDidMount = () => {
    var textarea = this.micropostFormContent;
    var bffCheckbox = this.bffCheckbox;
    typeof window !== 'undefined' ? new MBP.autogrow( textarea ) : null;
    var customEvent = new CustomEvent("input", { bubbles: true });
    this.fileInput.addEventListener('change', this.handleFileSelect, false);
    textarea.dispatchEvent( customEvent );

    document.addEventListener("locationChanged", this.handleLocationChange, false);
  };

  componentWillUnmount = () => {
    if (this.fileInput) {
      this.fileInput.removeEventListener('change', this.handleFileSelect, false);
    }

    document.removeEventListener("locationChanged", this.handleLocationChange, false);
  };

  handleLocationChange = (e) => {
    this.micropostLatitude.value = e.detail.data.latitude;
    this.micropostLongitude.value = e.detail.data.longitude;
    this.micropostCity.value = e.detail.data.cityName;
  };

  getDistanceBetween = (lat1, lon1, lat2, lon2) => {
    var R = 6371;
    var dLat = this.deg2rad(lat2-lat1);
    var dLon = this.deg2rad(lon2-lon1);
    var a =
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon/2) * Math.sin(dLon/2)
      ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    var d = R * c; // Distance in km
    return d;
  };

  deg2rad = (deg) => {
    return deg * (Math.PI/180)
  };

  countChars = () => {
    var hashtagLength = this.props.hashtag ? this.props.hashtag.length+2 : 0;
    var x = $( this.micropostFormContent ).val();
    var newLines = x.match(/(\r\n|\n|\r)/g);
    var addition = 0;

    if (newLines != null) {
      addition = newLines.length;
    }

    $(this.charCounter).text(x.length + hashtagLength + addition);
  };

  // Submit the micropost create form
  handleFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ sending: true });

    var data = new FormData( this.micropostForm );

    $.ajax({
      data: data,
      url: "/microposts",
      type: "POST",
      processData: false,
      contentType: false,
      timeout: 20000
    })
    .done( function( data ) {
      var customEvent = new CustomEvent("postToFacebook", { detail: { data: data }}, { bubbles: true });
      document.dispatchEvent( customEvent );
      this.setState({ sending: false, micropostFormErrors: '' });
      this.resetForm(data);
    }.bind(this))
    .fail( function( xhr ) {
      console.log(xhr);
      $("html, body").animate({ scrollTop: 0 });

      this.setState({
        micropostFormErrors: $.parseJSON( xhr.responseText ).errors,
        sending: false
      });
    }.bind(this));
  };

  resetForm = (data) => {
    $( this.formContainer ).addClass("hidden");

    this.setState({ data_uri: null, micropostFormErrors: '', checkable: false,
                    sending: false, dateIndex: 0, timestamp: Math.floor(Date.now() / 1000) });

    var customEvent1 = new CustomEvent("closeModal", { bubbles: true });
    document.dispatchEvent( customEvent1 );
    if (window.location.href.indexOf('/explore') !== -1) {
      // console.log('reset form and add to feed', data);
      var customEvent2 = new CustomEvent("addToFeed", { detail: { data }, bubbles: true });

      document.dispatchEvent( customEvent2 );
    }    
  };

  chooseImage = (e) => {
    e.preventDefault();
    $( this.fileInput ).click();
  };

  handleFileSelect = (e) => {
    if(typeof FileReader == "undefined") return true;
    var self = this;
    var chooseElement = this.choose ;
    var reader = new FileReader();
    var file = e.target.files[0];
    var fileName = file.name.replace( /C:\\fakepath\\/i, '' );
    fileName = this.truncateString( fileName, 20 );

    $( chooseElement ).text(fileName);

    var addImage = $( chooseElement ).text();
    $( chooseElement ).data('text', addImage);

    reader.onload = function(file) {
      self.setState({
        data_uri: file.target.result,
      });

      $( self.previewImage ).css({'background-image': 'url(' + self.state.data_uri + ')',
                                                            'background-size': '100% auto',
                                                            'background-repeat': 'no-repeat',
                                                            'min-height': '110px' });
    };

    reader.readAsDataURL(file);
    $( this.previewImage ).after( this.choose );
    $( this.cancelImg ).removeClass('hidden');
    if( this.saveNotice ) {
      $( this.saveNotice ).toggleClass('hidden');
    }
    $( this.previewImage ).toggleClass('overlay');
  };

  truncateString = (n, len) => {
    var ext = n.substring(n.lastIndexOf(".") + 1, n.length).toLowerCase();
    var filename = n.replace('.' + ext,'');
    if(filename.length <= len) {
        return n;
    }
    filename = filename.substr(0, len) + (n.length > len ? '[...]' : '');

    return filename + '.' + ext;
  };

  // Cancel an uploaded image
  handleCancel = () => {
    $( this.previewImage ).html( this.choose );
    $( this.previewImage ).css( { 'background': '',
                                                      'background-size': '',
                                                      'background-position': '',
                                                      'min-height': 'auto' } );

    $( this.cancelImg ).toggleClass('hidden');
    if( this.saveNotice ) {
      $( this.saveNotice ).toggleClass('hidden');
    }

    $( this.choose ).text( I18n.t('microposts.change_image', {locale: this.props.locale}) );
    // Deleting this input field deletes the image from the upload queue
    // of the jquery image upload plugin
    $( this.uploadButtonContainer ).empty();

    // This clears and clones the input field. Just to be sure, probably not needed.
    // To Do: Check if it can be removed
    // $( this.fileInput ).replaceWith($( this.fileInput ).val('').clone(true));

    $( this.previewImage ).toggleClass('overlay');
    if( this.micropostTitle ) {
      //$( this.micropostTitle ).toggleClass('blue white');
    }
  };

  prevDates = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if( this.state.dateIndex > 0 ) {
      this.setState({ dateIndex: this.state.dateIndex - 1 });
    }
  };

  nextDates = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if( this.state.dateIndex < 3 ) {
      this.setState({ dateIndex: this.state.dateIndex + 1 });
    }
  };

  handleBff = (e) => {
    var now = new Date();
    now = new Date(now.getTime() + (now.getTimezoneOffset() * 60000));
    var premiumDate = new Date(this.props.currentUser.user_infos.premium);

    if (premiumDate < now) {
      e.preventDefault();
      var customEvent = new CustomEvent("triggerPremiumModal", { detail: {highlight: 'bff'}, bubbles: false });
      document.dispatchEvent( customEvent );
    }
  }

  render() {
    var formLat = this.props.latitude;
    var formLon =  this.props.longitude;
    var formLocation = this.props.location;

    var titleValue = "";
    var prefillText = "";
    var bffFeature = "";

    // Set css styles the react way
    var imageUploadStyle = {
      height: '0px',
      width: '0px',
      overflow: 'hidden',
      visisbility: 'hidden',
      float: 'right'
    };

    var userAvatar = <UserProfileImage micropost={ { user_infos: this.props.currentUser } } />;
    var sendingText = this.state.sending ? "Loading..." : I18n.t('microposts.send', {locale: this.props.locale});
    var hashtagNotification = "";

    // Show a warning if user location is not the same
    // as the location the user is going to post to
    // console.log( formLat, formLon, this.props.userLat, this.props.userLon )
    if( formLat && formLon && this.props.userLat && this.props.userLon ) {
      // if same city (means 20Km surrounding)
      if( this.getDistanceBetween( formLat, formLon, this.props.userLat, this.props.userLon ) > 20 ) {
        var locationWarning = <div className="red location-missmatch">{ I18n.t('explorepage.location_missmatch', {locale: this.props.locale}) }</div>;
      }

      else {
        var locationWarning = "";
      }
    }

    // Headings for explore page (explore the city)
    // and for a hashtag sub-page
    if( this.props.hashtag ) {
      var hashtagName = this.props.hashtag;

      var where = <span>{ I18n.t('explorepage.post_for', {locale: this.props.locale}) } <span className="red">#{ hashtagName }</span>?</span>;
      var hashtagField = <input type="hidden" name="hashtag" id="hashtag" value={hashtagName} />;
      hashtagNotification = (
        <div className="blue hashtag-notification">#{ hashtagName } <span className="red">{ I18n.t('hashtags.form_notification', {locale: this.props.locale}) }</span></div>
      );
    }
    else {
      var where = <span>{ I18n.t('explorepage.post_in', {locale: this.props.locale}) } <span className="red">{ formLocation }</span>?</span>;
      var hashtagField = "";
    }

    if( this.state.micropostFormErrors.length > 0 ) {
      var errors = this.state.micropostFormErrors.join("<br />");
    }

    if( this.props.events ) {
      titleValue = this.props.eventTitle;
      prefillText = this.props.prefillText;
    }

    if (this.props.currentUser.gender === 'F') {
      bffFeature = (
        <div>
          <label className="bebas" style={{textAlign: "left", color: "#f56363"}}>
            <input ref={ bffCheckbox => this.bffCheckbox = bffCheckbox } type="checkbox" name="micropost[bff]" value="true" onClick={this.handleBff} style={{marginRight: "0.5rem"}} />
            { I18n.t('microposts.bff_feature_text', {locale: this.props.locale}) }
          </label>
        </div>
      );
    }

    return (
      <div key={this.state.timestamp} className="column full">
        <div ref={ formContainer => this.formContainer = formContainer } className="form-container animated fadeInUp" style={{marginTop: "1.5em"}}>
          <div className="feed-item margin-auto" style={ {width: "100%", maxWidth: "100%"} }>
            { locationWarning }
            <form ref={ micropostForm => this.micropostForm = micropostForm } style={ {border: "none"} } className="micropostForm" encType="multipart/form-data" action={ this.props.micropostForm.action } acceptCharset="UTF-8" method="post">
              <div  style={ {border: "none"} } className="top white-bg container">
                <div ref={ previewImage => this.previewImage = previewImage } style={{border: "1px dashed #22a8b5", padding: "0.2em 0"}} className="">
                  <a ref={ choose => this.choose = choose } href="javascript: false" className="bebas choose-image" onClick={this.chooseImage}>{ I18n.t('microposts.choose_image', {locale: this.props.locale}) }</a>
                </div>
                <div ref={ cancelImg => this.cancelImg = cancelImg } className="cancel-img hidden bebas-bold red close" onClick={this.handleCancel}></div>

                <span className="content-error red bold" dangerouslySetInnerHTML={{__html: errors }}></span>

                <label className="bebas text-left" htmlFor="micropost_title">{ I18n.t('microposts.form_title', {locale: this.props.locale}) }</label>
                <input id="micropost_title" ref={ micropostTitle => this.micropostTitle = micropostTitle } title={ I18n.t('microposts.set_title', {locale: this.props.locale}) } name="micropost[title]" value={ titleValue } className="micropost-form-titel bebas" placeholder={ I18n.t('microposts.form_title_placeholder', {locale: this.props.locale})} />
              {/*<div className="clearfix"></div>*/}
              </div>

              <div ref={ formBody => this.formBody = formBody } style={ {border: "none"} } className="body side-shadow">
                <div className="bebas dark-blue" title={ I18n.t('location.change', {locale: this.props.locale}) }>
                  <label className="bebas text-left" htmlFor="form_autocomplete">{ I18n.t('microposts.form_city', {locale: this.props.locale}) }</label>
                  <AutocompleteField location={ this.props.location } element={"autocomplete-form"} locale={this.props.locale} />
                </div>
                <div className="clearfix"></div>

                <label className="bebas text-left" htmlFor="micropost_content">
                  { I18n.t('microposts.form_desc', {locale: this.props.locale}) } <div ref={ charCounter => this.charCounter = charCounter } className="text-left display-inline"> 0</div>
                  <div className="text-left display-inline">/600</div>
                </label>


                <textarea ref={ micropostFormContent => this.micropostFormContent = micropostFormContent } onKeyUp={ this.countChars } className="white" title={ I18n.t('microposts.set_content', {locale: this.props.locale}) } placeholder={ I18n.t('new_post.placeholder', {locale: this.props.locale})} rows="3" name="micropost[content]" id="micropost_content">{ prefillText }</textarea>
                <span>{ hashtagNotification }</span>
                { bffFeature }
              </div>

              <DateList dateNodeArrayIndex={ this.state.dateIndex }
                        nextDates={ this.nextDates }
                        prevDates={ this.prevDates }
                        checkable={ true }
                        checkDates={ false }
                        dates={ this.props.micropostForm.dates }
                        isMicropostForm={ true }
                        locale={ this.props.locale } />

              <div className="bottom bebas">
                <button ref={ createMicropost => this.createMicropost = createMicropost } type="submit" style={ {fontSize: "1.3em", padding: "1em 0 0 0"} } disabled={ this.state.sending } onClick={ this.handleFormSubmit } className="commit-micropost display-inline bebas" id="commit_micropost" name="commit">
                  { sendingText }
                </button>
              </div>

              { hashtagField }

              <div ref={ uploadButtonContainer => this.uploadButtonContainer = uploadButtonContainer } className="hidden uploadButtonContainer"></div>
              <input name="locale" type="hidden" value={ this.props.locale } />
              <input type="hidden" ref={ micropostLatitude => this.micropostLatitude = micropostLatitude } name="latitude" id="micropost_latitude" value={ formLat } />
              <input type="hidden" ref={ micropostLongitude => this.micropostLongitude = micropostLongitude } name="longitude" id="micropost_longitude" value={ formLon } />
              <input type="hidden" ref={ micropostCity => this.micropostCity = micropostCity } name="city" id="micropost_city" value={ formLocation } />
              <input ref={ fileInput => this.fileInput = fileInput } type="file" className="hidden" name="micropost[post_img]" id="micropost_post_img" onChange={ this.handleFileSelect }/>
              <input type="hidden" name={ this.props.micropostForm.csrf_param } value={ this.props.micropostForm.csrf_token } />
              <input type="hidden" name="utf8" value="✓" />
            </form>
          </div>
        </div>
      </div>
    );
  }
}
