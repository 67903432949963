// require ../mixins/HandleShare.mixin
'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import getLocaleString from '../../helper/GetLocaleString';
import ContactRequestOrDateForm from './ContactRequestOrDateForm';
import UserProfileImage from '../Elements/UserProfileImage';
import BaseComponent from '../../libs/components/BaseComponent';
import UpvoteButton from './UpvoteButton';
import linkHashtags from '../../helper/LinkHashtags';
import renderShowConversationIcon from '../svgIcons/showConversation';
import renderMapIcon from '../svgIcons/i/map';
import renderProfileIcon from '../svgIcons/i/profile';
import renderFollowIcon from '../svgIcons/i/follow';
import renderCalendarIcon from '../svgIcons/calendar';
import renderAngleRight from '../svgIcons/angleRight';
import initTooltip from '../../helper/Tooltip';

import $ from 'jquery';
import I18n from 'i18n-js';

export default class Micropost extends BaseComponent {
	constructor(props) {
    super(props);

		var currentUserParticipates = props.micropost.conversation_infos.participants !== false ? props.micropost.conversation_infos.participants.indexOf( props.currentUser.id ) != '-1' : false;
    var micropostExDate = new Date(props.micropost.ex_date);
    var timeNow = Date.now();
    var expired = micropostExDate < timeNow;
    this.state = {
			currentUserParticipates: currentUserParticipates,
  		expired: expired,
  		editable: false,
  		participantsCounter: props.micropost.conversation_infos.participants.length > 0 ? props.micropost.conversation_infos.participants.length-1 : 0
    };
  }

	componentDidMount = () => {
    $( this.micropostContent ).linkify({
		  tagName: 'a',
		  target: '_blank',
		  newLine: '\n',
		  linkClass: 'my-links',
		  linkAttributes: null
		});

		initTooltip();

		var	backgroundSize = "100% auto";
		if( this.props.micropost.post_img_path == "/assets/icons/loader.svg" ) {
		var	backgroundSize = "auto";
		}

		if( this.props.micropost.post_img_path ) {
  		$( this.postImage ).css({'background-image': 'url(' + this.props.micropost.post_img_path + ')',
                                                 'background-size': backgroundSize, "background-repeat": "no-repeat"});
  	}

    var micropost = this.micropost;
    micropost.addEventListener("unfollowAll", this.handleParticipation, false);
		micropost.addEventListener("layoutNeedsChange", this.handleLayoutNeedsChange, false);
	};

	componentWillUnmount = () => {
		var micropost = this.micropost;
    micropost.removeEventListener("unfollowAll", this.handleParticipation, false);
		micropost.removeEventListener("layoutNeedsChange", this.handleLayoutNeedsChange, false);
	};

	handleLayoutNeedsChange = () => {
		if( this.props.handlePackeryFit ) {
			this.props.handlePackeryFit( this.micropost, this.state.layoutChange );
		}
	};

	handleParticipation = () => {
		this.setState({ participantsCounter: this.state.participantsCounter - 1, currentUserParticipates: false });
	};

	onSuccessfulReply = () => {
		this.setState({ participantsCounter: this.state.participantsCounter + 1, currentUserParticipates: true });
	};

	onPostHasBeenDeleted = (nodeId) => {
		this.props.removeFromFeed( nodeId );
	};

	userOrSignup = (e) => {
		if( this.props.currentUser.guest ) {
			e.preventDefault();
			e.stopPropagation();
			var customEvent = new CustomEvent("openModal", { bubbles: true });
      this.userLink.dispatchEvent( customEvent );
		}
	};

  render() {
  	var userUrl = getLocaleString(this.props.locale) + "/users/" + this.props.micropost.user_infos.id;
		var micropostUrl = getLocaleString(this.props.locale) + "/microposts/" + this.props.micropost.seo_url;
  	var micropostClasses = this.state.expired ? "feed-item expired" : "feed-item";
		var expiredHeading = "";
		var conversationsLink = "";
		var userAvatar = <UserProfileImage micropost={ {user_infos: this.props.micropost.user_infos} } width={ 45 } height={ 45 } />;
		var titleClass = "";
		var titleHeight = "";
		var overlay = "";
		var calendar = "";
		var postImg = "";
		var numberOfDates = this.props.micropost.dates.length;
		var postLocation = this.props.micropost.location ? this.props.micropost.location : "";
		var openTarget = this.props.standalone ? "_blank" : "_self";
		var datesInfo = "";
		var participantsInfo = "";
		var likesInfo = "";

		if( this.props.micropost.post_img_path ) {
			// titleClass = "white title-shadow";
			// titleHeight = "post-with-pic";
			// overlay = "overlay";
			postImg = (
				<figure className="">
					<img className="post-img" src={ this.props.micropost.post_img_path } />
				</figure>
			);
		}

		if( numberOfDates == 0 ) {
			var calendar = (
				<div className="post-infos calendar-icon bebas" style={{position: "relative", left: "2px", fontSize: "0.9em"}}><span style={{marginRight: "0.3em"}}>{ renderCalendarIcon() }</span> { I18n.t('microposts.no_fixed_date', {locale: this.props.locale}) }</div>
			);
		}
		else if( numberOfDates == 1 ) {
			var calendar = (
				<div className="post-infos calendar-icon bebas" style={{position: "relative", left: "2px", fontSize: "0.9em"}}><span style={{marginRight: "0.3em"}}>{ renderCalendarIcon() }</span> { this.props.micropost.dates[0].dayInWords }, { this.props.micropost.dates[0].dayInNumbers }</div>
			);
		}
		else if ( numberOfDates > 1 ) {
			var calendar = (
				<div className="post-infos calendar-icon bebas" style={{position: "relative", left: "2px", fontSize: "0.9em"}}><span style={{marginRight: "0.3em"}}>{ renderCalendarIcon() }</span> { numberOfDates } { I18n.t('microposts.multiple_dates', {locale: this.props.locale}) }</div>
			);
		}

		// Not on conversation show page, only if more than 1 participant (and only if current user is post owner)
		if( this.state.participantsCounter > 0 ) {


			if( (this.props.micropost.conversation_infos.participants.indexOf(this.props.currentUser.id) > -1) || this.state.currentUserParticipates ) {
				conversationsLink = (
					<span className="convo-icon noselect right">
						{ renderShowConversationIcon() }
						<span className="reply-counter blue">{ this.state.participantsCounter }</span>
					</span>
				);
			}
			else {
				conversationsLink = (
					<span className="convo-icon inactive noselect right" title={ I18n.t('microposts.not_in_convo', {locale: this.props.locale}) }>
						{ renderShowConversationIcon() }
						<span className="reply-counter noselect">
							{ this.state.participantsCounter }
						</span>
					</span>
				);
			}
		}

		if( this.state.expired ) {
			var micropostClasses = "feed-item expired";
			var expiredHeading = (
				<div style={{ position: 'absolute', left: '50%' }}>
					<h1 className="expired-heading bebas bold white font-size-20">
						{ I18n.t('microposts.expired_heading', {locale: this.props.locale}) }
					</h1>
				</div>
			);
		}

		likesInfo = (
			<div className="bebas gray" style={{display: "block", textAlign: "center", float: "left", position: 'relative', top: '0.2rem'}}>
				<UpvoteButton micropostId={ this.props.micropost.id } votes={ this.props.micropost.votes }/>
			</div>
		);

		if (this.state.currentUserParticipates === true && this.props.currentUser.id !== this.props.micropost.user_infos.id) {
			participantsInfo = (
				<div className="bebas gray follow active-icon" rel="tooltip" title={ I18n.t('microposts.number_of_participants', {locale: this.props.locale, count: this.state.participantsCounter }) } style={{display: "block", textAlign: "center", float: "left", position: 'relative', top: '0.2rem', marginLeft: '0.75rem'}}>
					{ renderFollowIcon() }
				</div>
			);
		} else if (this.props.currentUser.id !== this.props.micropost.user_infos.id) {
			if (this.state.participantsCounter > 0) {
				participantsInfo = (
					<div className="bebas dark-blue" rel="tooltip" title={ I18n.t('microposts.number_of_participants', {locale: this.props.locale, count: this.state.participantsCounter }) } style={{display: "block", textAlign: "center", float: "left", position: 'relative', top: '0.2rem', marginLeft: '0.75rem'}}>
						{ renderProfileIcon() }
					</div>
				);
			} else {
				participantsInfo = (
					<div className="bebas gray" rel="tooltip" title={ I18n.t('microposts.number_of_participants', {locale: this.props.locale, count: this.state.participantsCounter }) } style={{display: "block", textAlign: "center", float: "left", position: 'relative', top: '0.2rem', marginLeft: '0.75rem'}}>
						{ renderProfileIcon() }
					</div>
				);
			}
		}

		if (numberOfDates > 0) {
			datesInfo = (
				<div className="bebas dark-blue" rel="tooltip" title={ I18n.t('microposts.number_of_dates', {locale: this.props.locale, count: numberOfDates }) } style={{display: "block", textAlign: "center", float: "left", position: 'relative', top: '0.2rem', marginLeft: '1rem'}}>
					{ renderCalendarIcon() }
				</div>
			);
		} else {
			datesInfo = (
				<div className="bebas gray calendar" rel="tooltip" title={ I18n.t('microposts.no_number_of_dates', {locale: this.props.locale, count: numberOfDates }) } style={{display: "block", textAlign: "center", float: "left", position: 'relative', top: '0.2rem', marginLeft: '1rem'}}>
					{ renderCalendarIcon() }
				</div>
			);
		}

    return (
      <article ref={ micropost => this.micropost = micropost } id={ this.props.micropost.id } className={ micropostClasses }>
	      <div className="inner">
					<div className="body" style={{ paddingBottom: '0.8em' }}>
						{ expiredHeading }
						<div className="user-infos text-left">
					    <a ref={ userLink => this.userLink = userLink } href={ userUrl } target={ openTarget } onClick={ this.userOrSignup } className="white-bg-link no-underline init-loading">
					    	{ userAvatar }
					    	<span className="user-name dark-blue" style={{ fontSize: '1.1rem' }}>{ this.props.micropost.user_infos.name }</span>
					    </a>
					  </div>
						{/* conversationsLink */}
					  <div className="micropost_city_text">
							<div className="" style={{ height: '17px' }}>{/*<span style={{marginRight: "2px"}}>{ renderMapIcon() }</span>*/}{ postLocation }</div>
							{/* calendar */}
							<div className="right"></div>
						</div>
					</div>
				</div>
				<div className="inner">
						{ postImg }
						</div>
				<div className="inner">
					<div className="body" style={{ paddingTop: '0' }}>
						{/*<div ref={ postImage => this.postImage = postImage } className={ "white-bg container " + overlay } ></div>*/}

						<h3 className={ "micropost-titel bebas " + titleClass + " " + titleHeight }>
							{this.props.micropost.title}
						</h3>

					  <div ref={ micropostContent => this.micropostContent = micropostContent } className="micropost-content margin-bottom-05" dangerouslySetInnerHTML={{__html:  linkHashtags( this.props.locale, this.props.micropost.content, this.props.standalone ) }}></div>

						<div className="clearfix"></div>
					  {/*
							<div style={{height: "1px"}}>
								<div style={{width: "100%", height: "1px", borderTop: "1px solid #ddd", borderLeft: "1px solid #ddd", borderRight: "1px solid #ddd", margin: "0 auto"}}></div>
							</div>*/}

							<div style={{display: "block", textAlign: "center", margin: "0", maxWidth: "100%", backgroundColor: 'white' }}>
								<div className="post-icons" style={{display: "block", textAlign: "center", margin: "0 auto", maxWidth: "100%" }}>
									{/* <div className="bebas gray" style={{display: "inline-block", textAlign: "center", float: "left"}} title={ I18n.t('users.posts_explain', {locale: this.props.locale}) }>
										{ 3 } <br /> { 'Likes' }
									</div> */}
									{ likesInfo }
									{ participantsInfo }
									{ datesInfo }

									<a href={ micropostUrl } className={"bebas text-right right dark-blue more init-loading"} style={{ position: 'relative', top: '3px' }}>
		                {/* I18n.t('microposts.more', {locale: this.props.locale}) */}{ renderAngleRight() }
		              </a>

									<div className="clearfix"></div>
								</div>

									<div className="clearfix"></div>
							</div>
					</div>



					<ContactRequestOrDateForm micropost={ this.props.micropost } handleParticipation={ this.handleParticipation } expired={ this.state.expired }
																		editable={ this.state.editable } participants={ this.props.participants } standalone={ this.props.standalone }
																		participantsCounter={ this.state.participantsCounter } contactRequestForm={ this.props.contactRequestForm }
																		currentUser={ this.props.currentUser } onSuccessfulReply={ this.onSuccessfulReply }
																		onPostHasBeenDeleted={ this.onPostHasBeenDeleted } currentUserParticipates={ this.state.currentUserParticipates }
																		layoutNeedsChange={ this.handleLayoutNeedsChange } locale={ this.props.locale } />
				</div>
			</article>
    )
  }
}
