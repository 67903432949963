'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import BaseComponent from '../../../libs/components/BaseComponent';

export default class FriendlyAdBanner extends BaseComponent {
  constructor() {
    super();

    this.state = {
      display: true,
    };
  };

  componentDidMount = () => {
    /* const iOS = /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());
    const standalone = "standalone" in window.navigator && window.navigator.standalone;

    if (iOS && !standalone) {
      this.setState({ display: true });
    } */
  };

  render() {
    // console.log('debug ad!!!!', this.props.ad);
    var html = (
      <li className="message">
        <div className="">
          <div className="friendly-ad">
            <div className="gray profile-detail-icons location" style={{ float: 'right' }}>
              <a href="#" className="friendly-ad-badge dark-blue" style={{ top: '0px' }}>friendly ad</a>
            </div>

            <div className="clearfix"></div>

            <a href={ this.props.ad.link } target="_blank" rel="nofollow">
              <div className="product-card-image">
                <img src={ this.props.ad.image_url } />
              </div>
            </a>
          </div>
        </div>
      </li>
    );
    var ad = this.state.display === true ? html : "";

    return ad;
  }
}
