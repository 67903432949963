'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import getLocaleString from '../../helper/GetLocaleString';
import BaseComponent from '../../libs/components/BaseComponent';
import UpvoteButton from './UpvoteButton';
import renderCancelIcon from '../svgIcons/cancel';
import renderHeartIcon from '../svgIcons/heart';
import renderPlaneIcon from '../svgIcons/i/plane';
import renderFullHeartIcon from '../svgIcons/heartFull';
import renderAngleRight from '../svgIcons/angleRight';
import renderShowConversationIcon from '../svgIcons/showConversation';
import $ from 'jquery';
import I18n from 'i18n-js';

export default class ReplyButton extends BaseComponent {
  constructor() {
    super();
    this.state = {
      upvotes: 3
    };
  }

  handleDeletePost = (e) => {
    e.preventDefault();

    var node = this.deletePost;
    this.props.deletePost( e, node );
  };

  handleSendForm = (e) => {
    e.preventDefault();
    var node = this.sendContact;
    this.props.sendForm( e, node );
  };

  /* toggleForm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    this.props.toggleForm(e);
  }; */

  render() {
    var content = false;
    var showWithForm = this.props.showRequestForm ? '' : 'hidden';
    var hideWithForm = this.props.showRequestForm ? 'hidden' : '';
    var micropostUrl = getLocaleString(this.props.locale) + "/microposts/" + this.props.micropost.seo_url;
    var micropostEditUrl = getLocaleString(this.props.locale) + "/microposts/" + this.props.micropost.seo_url + "/edit";
    var micropostRenewUrl = getLocaleString(this.props.locale) + "/microposts/" + this.props.micropost.seo_url + "/renew";
    var sendText = this.props.sending ? "Loading..." : I18n.t('conversations.new.send', {locale: this.props.locale});
    var leaveText = this.props.sending ? "Leaving..." : I18n.t('conversations.leave', {locale: this.props.locale});
    var deleteText = this.props.sending ? "Deleting..." : I18n.t('microposts.delete', {locale: this.props.locale});
    var hrefConvo = "/conversations/" + this.props.micropost.conversation_infos.id;
    var target = this.props.standalone ? "_blank" : "_self";

    if( this.props.expired ) {
      if( this.props.currentUser.id == this.props.micropost.user_infos.id ) {
        content = (
          <div className="bottom bebas font-size-11 container">
            <a href="javascript: false" style={{lineHeight: '2.6em', height: '3.1rem'}} ref={ deletePost => this.deletePost = deletePost } disabled={ this.props.sending } className="closeContact display-inline bebas left" onClick={ this.handleDeletePost } data-id={ this.props.micropost.id } id="delete_post">
              { deleteText }
            </a>

            <a ref={ renewPost => this.renewPost = renewPost } href={ micropostRenewUrl } className="sendContact display-inline bebas right text-right">
              { I18n.t('microposts.renew', {locale: this.props.locale}) }
            </a>
            <div className="clearfix"></div>
          </div>
        );
      }
      else {
        content = false;
      }
    }
    else {
      // Logged in, current_user == micropost user: Delete post
      if( this.props.currentUser.id == this.props.micropost.user_infos.id ) {
        content = (
          <div className="bottom bebas font-size-11 container">
            <div style={{width: "44%", display: "inline-block"}}></div>

            {/* <UpvoteButton micropostId={ this.props.micropost.id } votes={ this.props.micropost.votes }/> */}

            {/* <a href={ micropostUrl } className={"sendContact display-inline bebas text-right post-infos right " + hideWithForm}>
              { I18n.t('microposts.more', {locale: this.props.locale}) } { renderAngleRight() }
            </a> */}

            <div className="clearfix"></div>
          </div>
        );
      }
      else {
        // Logged in, current_user != micropost user, already sent contact request: Got to conversation
        if( this.props.currentUserParticipates ) {
          content = (
            <div className="bottom bebas font-size-11 container">
              <div style={{width: "44%", display: "inline-block"}}></div>

              {/* <UpvoteButton micropostId={ this.props.micropost.id } votes={ this.props.micropost.votes }/> */}

              {/* <a href={ micropostUrl } className={"sendContact display-inline bebas text-right post-infos right " + hideWithForm}>
                { I18n.t('microposts.more', {locale: this.props.locale}) } { renderAngleRight() }
              </a> */}
              <div className="clearfix"></div>
            </div>
          );
        }
        else {
          // Logged in, current_user != micropost user -> can send contact request: Reply or close
          content = (
            <div className="bottom bebas container font-size-11">
              {/* <a href="javascript: false" disabled={ this.props.sending } className={"sendContact text-left display-inline bebas left " + hideWithForm} onClick={ this.props.toggleForm }>
                { sendText }
              </a> */}

              {/* <UpvoteButton micropostId={ this.props.micropost.id } votes={ this.props.micropost.votes }/> */}

              {/* <a href={ micropostUrl } className={"sendContact display-inline bebas text-right post-infos right " + hideWithForm}>
                { I18n.t('microposts.more', {locale: this.props.locale}) } { renderAngleRight() }
              </a> */}

              {/* <a href="javascript: false" disabled={ this.props.sending } className={"sendContact text-left display-inline bebas left " + showWithForm} onClick={ this.props.toggleForm }>
                { I18n.t('microposts.cancel', {locale: this.props.locale}) }
              </a> */}

              {/* <a href="javascript: false" ref={ sendContact => this.sendContact = sendContact } disabled={ this.props.sending } style={{ color: "#22a8b5"}} className={"sendContact text-right display-inline bebas right " + showWithForm} data-id={ this.props.micropost.id } name="commit" onClick={ this.handleSendForm }>
                {/* sendText */}
                {/* renderPlaneIcon() }
              </a> */}

              <div className="clearfix"></div>
            </div>
          );
        }
      }
    }
    return content
  }
}
