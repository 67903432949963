'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import getLocaleString from '../../helper/GetLocaleString';
import UserProfileImage from '../Elements/UserProfileImage';
import getFirstName from '../../helper/GetFirstName';
import BaseComponent from '../../libs/components/BaseComponent';

export default class GroupParticipant extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      participates: false,
      selectable: props.selectable == false ? props.selectable : true
    };
  }

  componentDidMount = () => {
    var _this = this;
    if( this.props.groupConversation[0] ) {
      this.props.groupConversation[0].get_participants.filter(function(participant) {
        if( participant.id == _this.props.children.id ) {
          _this.setState({ participates: true });
        }
      });
    }
  };

  handleChangeUser = (e) => {
    e.preventDefault();


    if( this.state.selectable ) {
      this.setState({ participates: !this.state.participates })
    }
    else {
      // Turbolinks.visit(getLocaleString(this.props.locale) + "/users/" + this.props.children.id);
      window.location.href = getLocaleString(this.props.locale) + "/users/" + this.props.children.id;
    }
  };

	render() {
    var micropost = { user_infos: this.props.children };
    var activeClass = this.state.participates ? "blue-bg " : "";
    var activeText = this.state.participates ? "white " : "";

    return (
      <div className={ "group-participant " + activeClass }>
        <span ref={ participant => this.participant = participant } className={ "cursor-pointer display-inline " + activeText } onClick={ this.handleChangeUser }>
          <UserProfileImage micropost={ micropost } width={25} height={25} />
          <span style={{marginLeft:"0.3em"}}>{ getFirstName( this.props.children.name ) }</span>
        </span>
        <input type="hidden" className="animated fadeInUp" name={ "conversation[users][][id]" } value={ this.props.children.id } />
        <input type="hidden" className="animated fadeInUp" name={ "conversation[users][][add]" } value={ this.state.participates } />
      </div>
    );
	}
}
