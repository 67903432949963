'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';

var renderLogo = function() {
	return(
    <svg className="logo-svg" version="1.1"  width="120px" height="33.238px" viewBox="352.601 277.169 120 33.238">
      <g>
      	<path d="M401.581,299.028c0,1.558-0.695,2.118-1.798,2.118s-1.796-0.561-1.796-2.118v-16.825h-3.471v16.606
      		c0,3.49,1.766,5.485,5.172,5.485c3.405,0,5.173-1.995,5.173-5.485v-16.606h-3.281L401.581,299.028L401.581,299.028z"/>
      	<path d="M412.711,282.203h-5.487v21.811h5.487c3.47,0,5.173-1.901,5.173-5.393v-11.027
      		C417.884,284.104,416.181,282.203,412.711,282.203z M414.414,298.779c0,1.558-0.663,2.118-1.765,2.118h-1.956v-15.579h1.956
      		c1.102,0,1.765,0.562,1.765,2.119V298.779z"/>
      	<path d="M425.578,282.203h-5.488v21.811h5.488c3.469,0,5.172-1.901,5.172-5.393v-11.027
      		C430.75,284.104,429.046,282.203,425.578,282.203z M427.28,298.779c0,1.558-0.662,2.118-1.767,2.118h-1.954v-15.579h1.954
      		c1.104,0,1.767,0.562,1.767,2.119V298.779z"/>
      	<polygon points="438.287,292.142 438.222,292.142 435.605,282.203 431.979,282.203 436.364,296.783
      		436.364,304.013 439.832,304.013 439.832,296.783 444.215,282.203 440.904,282.203 	"/>
      	<polygon points="452.982,297.688 452.917,297.688 450.396,282.203 445.572,282.203 445.572,304.013
      		448.598,304.013 448.598,288.589 448.661,288.589 451.182,304.013 454.462,304.013 456.798,288.372 456.86,288.372 456.86,304.013
      		460.14,304.013 460.14,282.203 455.316,282.203 	"/>
      	<polygon points="472.123,285.318 472.123,282.203 462.662,282.203 462.662,304.013 472.123,304.013
      		472.123,300.897 466.13,300.897 466.13,294.509 470.893,294.509 470.893,291.394 466.13,291.394 466.13,285.318 	"/>
      	<path d="M390.008,292.422v-0.062c1.544-0.717,2.238-2.12,2.238-4.363v-0.778c0-3.366-1.545-5.016-5.141-5.016
      		h-5.234v0.319c1.509,1.484,2.7,3.281,3.468,5.285v-2.489h1.672c1.23,0,1.766,0.686,1.766,2.244v1.214
      		c0,1.745-0.788,2.307-2.081,2.307h-0.516c0.092,0.663,0.143,1.336,0.143,2.023c0,0.368-0.019,0.73-0.044,1.092h0.605
      		c1.577,0,2.239,0.623,2.239,2.648v1.9c0,1.62-0.63,2.15-1.797,2.15h-1.988v-2.489c-0.769,2.003-1.959,3.801-3.468,5.286v0.319
      		h5.456c3.468,0,5.266-1.809,5.266-5.173v-1.776C392.593,294.758,391.869,293.139,390.008,292.422z"/>
      	<path d="M368.719,308.044c8.351,0,15.118-6.687,15.118-14.938c0-8.249-6.767-14.937-15.118-14.937
      		s-15.118,6.688-15.118,14.937C353.601,301.358,360.368,308.044,368.719,308.044z M359.076,296.264
      		c0.508-2.093,1.901-3.409,3.902-4.151c0.176-0.066,0.349-0.143,0.525-0.214c-0.14-0.146-0.265-0.306-0.418-0.433
      		c-1.455-1.154-1.644-3.239-0.414-4.591c1.241-1.362,3.442-1.375,4.703-0.029c1.249,1.329,1.087,3.423-0.354,4.596
      		c-0.16,0.127-0.324,0.254-0.584,0.461c0.324,0.104,0.543,0.161,0.755,0.239c0.819,0.309,1.515,0.743,2.106,1.278
      		c0.562-0.555,1.262-0.987,2.093-1.294c0.153-0.057,0.304-0.122,0.457-0.186c-0.122-0.125-0.229-0.267-0.363-0.375
      		c-1.265-1.006-1.429-2.821-0.361-3.994c1.081-1.186,2.997-1.196,4.094-0.026c1.084,1.157,0.945,2.978-0.309,3.997
      		c-0.139,0.115-0.281,0.223-0.51,0.404c0.285,0.087,0.477,0.138,0.659,0.207c1.577,0.591,2.696,1.652,3.231,3.238
      		c0.298,0.888,0.215,1.727-0.693,2.292c-0.076,0.046-0.172,0.114-0.195,0.19c-0.225,0.81-0.926,0.947-1.601,1.111
      		c-1.912,0.469-3.814,0.435-5.707-0.118c-0.087-0.024-0.151-0.07-0.232-0.102c-0.275,0.88-1.057,1.044-1.816,1.231
      		c-2.197,0.538-4.385,0.498-6.559-0.136c-0.556-0.161-1.037-0.397-1.231-1.006c-0.052-0.162-0.222-0.317-0.376-0.414
      		C359.043,297.912,358.866,297.123,359.076,296.264z"/>
      </g>
    </svg>
	)
};

export default renderLogo;
