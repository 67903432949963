'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import ReplyButton from './ReplyButton';
import UserProfileImage from '../Elements/UserProfileImage';
import BaseComponent from '../../libs/components/BaseComponent';
import getFirstName from '../../helper/GetFirstName';
import MBP from '../../helper/MobileBoilerplate';
import $ from 'jquery';
import I18n from 'i18n-js';
import renderPlaneIcon from '../svgIcons/i/plane';

export default class ContactRequestOrDateForm extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      showRequestForm: true,
      showRequestMessage: true,
      sending: false,
      checkable: false,
      errors: ''
    };
  }

  componentDidMount = () => {
    var element = this.conversationBody;

    if( element ) {
      new MBP.autogrow( element );
      var customEvent = new CustomEvent("input", { bubbles: true });
      element.dispatchEvent( customEvent );
    }
  };

  componentDidUpdate = () => {
    this.props.layoutNeedsChange();
  };

  toggleForm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    var showRequestMessage = !this.state.showRequestMessage;
    this.setState({ errors: '', showRequestForm: !this.state.showRequestForm, showRequestMessage: showRequestMessage, checkable: !this.state.checkable });
  };

  sendForm = (e,node) => {
    e.preventDefault();
    e.stopPropagation();

    var that = this;
    var form = this.contactForm;
    var body = this.conversationBody;
    var data = new FormData( form );
    var bufferContent = body.value;

    if( body.value.length > 0 ) {
      $.ajax({
        url: form.getAttribute('action'),
        type: 'POST',
        data: data,
        processData: false,
        contentType: false
      })
      .done(function(data) {
        if (data.body.indexOf('[email address removed]') !== -1 || data.body.indexOf('[phone number removed]') !== -1) {
          var customEvent = new CustomEvent("triggerPremiumModal", { detail: {highlight: 'contact_infos'}, bubbles: false });
          document.dispatchEvent( customEvent );
        }

        $( that.error_messages ).text("");
        that.setState({errors: "", sending: false});
        var customEvent = new CustomEvent("setConversationId", { detail: { data }, bubbles: true });
        that.contactForm.dispatchEvent( customEvent );
        if( that.props.currentUser.guest ) {
          that.setState({ sending: false });
        }
        else {
          that.setState({ errors: '', sending: false, showRequestMessage: false, showRequestForm: false });
          body.value = "";
          that.props.onSuccessfulReply();
        }
      })
      .fail(function( xhr, status, err ) {
        console.log(xhr);

        setTimeout(function() {
          that.setState({ sending: false });
          body.value = bufferContent;
        }, 300);

        if (xhr.responseJSON.errors === 'premium_feature_unlimited') {
          var customEvent = new CustomEvent("triggerPremiumModal", { detail: {highlight: 'unlimited'}, bubbles: false });
          document.dispatchEvent( customEvent );
        }
      });
    }
    else {
      this.props.layoutNeedsChange();
      this.setState({ errors: I18n.t('conversations.empty_message', {locale: this.props.locale}), sending: false });
    }
  };

  deletePost = (e,node) => {
    e.preventDefault();
    e.stopPropagation();

    if( window.confirm( I18n.t('microposts.confirm_delete', {locale: this.props.locale}) ) ) {
      this.setState({ sending: true });

      var that = this;
      var nodeId = $(node).data("id");

      $.ajax({
        url: "/microposts/" + nodeId,
        type: 'DELETE',
        processData: false,
        contentType: false
      })
      .done(function(response, status, xhr) {
        that.setState({ sending: false });
        that.props.onPostHasBeenDeleted( nodeId );
      })
      .fail(function() {
        console.log("error, post not deleted")
      });
    }
  };

  handleSendForm = (e) => {
    e.preventDefault();
    if (this.state.sending === true) return;

    this.setState({ sending: true });
    var node = this.sendContact;
    this.sendForm( e, node );
  };

  signUpOrToggleForm = (e) => {
    e.preventDefault();

    if( this.props.currentUser.guest == true ) {
      var customEvent = new CustomEvent("openModal", { bubbles: true });
      document.dispatchEvent( customEvent );
    }
    else {
      this.toggleForm(e);
    }
  };

  handleOnFocus = (e) => {
    // console.log('textarea got focus!!!!');
    $(this.profilePic).hide();
    $(this.sendContact).show();
    $(this.conversationBody).css({ width: '88%'});
  }

  handleOnBlur = (e) => {
    var input = $(this.conversationBody).val();
    // console.log('textarea lost focus!!!!', e);
    if (input.length === 0) {
      $(this.profilePic).show();
      $(this.sendContact).hide();
      $(this.conversationBody).css({ width: '100%'});
    }
  }

  render() {
    var content = false;
    var showRequestMessage = this.state.showRequestMessage ? '' : 'hidden';
    var buttons = <ReplyButton locale={ this.props.locale } showRequestForm={ this.state.showRequestForm } sending={ this.state.sending }
                               sendForm={ this.sendForm } deletePost={ this.deletePost } expired={ this.props.expired }
                               currentUserParticipates={ this.props.currentUserParticipates }
                               currentUser={ this.props.currentUser } micropost={ this.props.micropost } toggleForm={ this.signUpOrToggleForm } />

    // Contact form, posts message to conversations
    if( (this.props.currentUser.id != this.props.micropost.user_id) && !this.props.currentUser.guest && this.props.currentUserParticipates === false) {
      content = (
        <form ref={ contactForm => this.contactForm = contactForm } className="sendContactForm side-shadow" action={ this.props.contactRequestForm.action } acceptCharset="UTF-8" method="post">
          <div ref={ micropostMessaging => this.micropostMessaging = micropostMessaging } style={{display: "table", width: "100%"}} className={"micropostMessaging " + showRequestMessage }>
            <div style={{display: "table-cell", width: "38px", height: "100%", verticalAlign: "top"}} ref={ profilePic => this.profilePic = profilePic }>
              <UserProfileImage width={30} height={30} micropost={ {user_infos: this.props.currentUser.user_infos} } />
            </div>
            <div className={ showRequestMessage } style={{display: "table-cell", width: "auto"}}>
              <textarea ref={ conversationBody => this.conversationBody = conversationBody }  className="conversation_body" style={{ borderRadius: '5px' }} onInput={ this.props.layoutNeedsChange } placeholder={ I18n.t('microposts.message_placeholder', {locale: this.props.locale}) } onFocus={ this.handleOnFocus } onBlur={ this.handleOnBlur } rows="2" name="message[body]"></textarea>
              <a href="javascript: false" ref={ sendContact => this.sendContact = sendContact } disabled={ this.state.sending } style={{ color: "#22a8b5", display: 'none'}} className={"sendingButton text-right display-inline bebas right"} data-id={ this.props.micropost.id } name="commit" onClick={ this.handleSendForm }>
                {/* sendText */}
                { renderPlaneIcon() }
              </a>
            </div>
          </div>

          <input name="locale" type="hidden" value={ this.props.locale } />
          <input type="hidden" name={ this.props.contactRequestForm.csrf_param } value={ this.props.contactRequestForm.csrf_token } />
          <input value={ this.props.currentUser.id } type="hidden" name="conversation[sender_id]" />
          <input value={ this.props.micropost.user_id } type="hidden" name="conversation[recipient_id]" />
          <input value={ this.props.micropost.id } type="hidden" name="conversation[micropost_id]" />

          { buttons }
        </form>
      );
    }
    else {
      content = (
        <div className="sendContactForm">
          { buttons }
        </div>
      );
    }

    return content
  }
}
