import I18n from 'i18n-js';

var LevelInWords = function( level, locale ) {
    if( level == 1 ) {
      return I18n.t('users.level1', {locale: locale})
    }

    else if( level == 2 ) {
      return I18n.t('users.level2', {locale: locale})
    }

    else if( level == 3 ) {
      return I18n.t('users.level3', {locale: locale})
    }

    else if( level == 4 ) {
      return I18n.t('users.level4', {locale: locale})
    }

    else if( level == 5 ) {
      return I18n.t('users.level5', {locale: locale})
    }

    else if( level == 6 ) {
      return I18n.t('users.level6', {locale: locale})
    }

    else if( level == 7 ) {
      return I18n.t('users.level7', {locale: locale})
    }

    else {
      return I18n.t('users.level1', {locale: locale})
    }
    end
};

export default LevelInWords;
