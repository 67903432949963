'use strict';
import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';
import BaseComponent from '../../libs/components/BaseComponent';
import $ from 'jquery';

export default class FlashMessages extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      messages: props.messages
    };
  }

  componentDidMount = () => {
    this.displayMessage();
    // Trigger flash display on ajax complete event
    $(document).ajaxComplete(function(event, xhr, settings) {
      // Trigger flash messages only for requests to BuddyMe
      // (no google.com or telize.com)
      if( !settings.crossDomain ) {
        this.handleFlashMessageHeader(xhr);
      }
    }.bind(this));
  };

  displayMessage = () => {
    // Check if a flash message is present
    // if so, display flash-container, otherwise not
    if( this.state.messages.length > 0 ) {
      $( this.flashContainer ).removeClass("hidden");

      // Fade out the alert and enable the execution again
      /* setTimeout( function(){
        // if-case prevents setState(...) error (related to turbolinks)
        //if( this.isMounted() ) {
          this.setState({ messages: [] });
        //}
      }.bind(this), 30000); */
    } else if ( this.state.messages.length == 0 ) {
      // $( this.flashContainer ).addClass("hidden");
    }
  };

  handleFlashMessageHeader = (xhr) => {
    var _message_array = new Array();
    // Umlaute are transmitted url encoded (in the request header).
    // decodeURIComponent( escape(string) ) works as utf8_decode
    // returns null as string if empty!
    var _raw_messages = decodeURIComponent( escape( xhr.getResponseHeader("X-FlashMessages") ) );

    // Make an Array like [["success"]["Aktivität erfolgreich erstellt."]]
    if ( _raw_messages && _raw_messages != "null" ) {
      var _json_messages = JSON.parse(_raw_messages);
      var count = 0

      for (var key in _json_messages) {
        _message_array[count] = new Array();
        _message_array[count][0] = key;
        _message_array[count][1] = _json_messages[key];
        count += 1;
      }
      // if-case prevents setState(...) error (related to turbolinks)
      //if( this.isMounted() ) {
        this.setState({ messages: _message_array });
        this.displayMessage();
      //}
    }
  };

  _flash_class = (level) => {
    var _result = 'alert alert-error';
    if (level === 'notice') {
      _result = 'alert alert-info';
    } else if (level === 'success') {
      _result = 'alert alert-success';
    } else if (level === 'error') {
      _result = 'alert alert-error';
    } else if (level === 'alert') {
      _result = 'alert alert-error';
    }
    return _result;
  };

  handleClose = () => {
    // Close flash message on click
    $( this.flashContainer ).addClass("hidden");
  };

  render() {
    if(this.state.messages.length == 0) {
      var containerClasses = "full flash-container no-margin container hidden";
    }

    else {
      var containerClasses = "full flash-container no-margin container";
    }

    return (
      <div className="row">
        <div ref={flashContainer => this.flashContainer = flashContainer} className={ containerClasses } onClick={this.handleClose}>
          <a href="#" className="close left"></a>
          <div className="flash-msg center">
            <div id="flash_messages" className='center' style={{ maxWidth: '40rem', margin: '0 auto' }}>
              { this.state.messages.map( function(message, index) {
                  var _level = message[0];
                  var _text = "";

                  if (Array.isArray(message[1])) {
                    _text  = message[1].join('. ');
                  } else {
                    _text  = message[1];
                  }

                  var icon = "";
                  var color = "white";

                  if( _level == "success" || _level == "badge" ) {
                    //flashContainerElement.className = flashContainerElement.className + ("blue-bg");
                    //icon = (
                      // <svg className="iconic" width="23.983px" height="23.158px" viewBox="0 0 23.983 23.158"><path fill="#FFFFFF" d="M3.919,11.829c0,0-0.95-0.668-1.847,0.866l-1.998,2.638 c0,0-0.444,0.992,0.835,1.714l6.819,5.251c0,0,0.866,0.858,1.838,0.858c0.749-0.005,1.545-0.01,1.545-0.01s1.74-0.072,2.557-1.75 L23.87,3.28c0,0,0.568-1.01-0.852-1.732l-1.723-1.01c0,0-1.421-1.3-2.415,0.144l-9.065,14.92c-0.986,1.407-2.087-0.226-2.087-0.226 L3.919,11.829z"></path></svg>
                    //);
                  }
                  else if( _level == "error" || _level == "notice" || _level == "danger" ) {
                    //flashContainerElement.className = flashContainerElement.className + ("red-bg");
                    // <svg  className="iconic iconic-x" width="128" height="128" viewBox="0 0 128 128"><g className="iconic-metadata"><title>X</title></g><g data-width="128" data-height="128" className="iconic-x-lg iconic-container iconic-lg" display="inline"><path strokeWidth="16" strokeLinecap="square" className="iconic-property-stroke" d="M13 13l102 102m0-102l-102 102" fill="none" /></g><g data-width="32" data-height="32" className="iconic-x-md iconic-container iconic-md" display="none" transform="scale(4)"><path strokeWidth="6" strokeLinecap="square" className="iconic-property-stroke" d="M4.5 4.5l23 23m0-23l-23 23" fill="none" /></g><g data-width="16" data-height="16" className="iconic-x-sm iconic-container iconic-sm" display="none" transform="scale(8)"><path strokeWidth="4" strokeLinecap="square" className="iconic-property-stroke" d="M3 3l10 10m0-10l-10 10" fill="none" /></g></svg>
                    color = "red";
                  }

                  if( _level != "analytics" ) {
                    return (
                      <div key={ index } className={ this._flash_class(_level) + " " + color }>
                        { icon }
                        { _text }
                      </div>
                    );
                  }
                  else {
                    <span></span>
                  }
                }.bind(this)) }
            </div>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
    )
  }
}
