import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';

var renderFollow = function() {
	return(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 19" width="22" height="19">
    	<defs>
    		<clipPath clipPathUnits="userSpaceOnUse">
    			<path d="M-333 -411L42 -411L42 256L-333 256Z" />
    		</clipPath>
    	</defs>
    	<g clip-path="url(#cp1)">
    		<g>
    			<g>
    				<g>
    					<g>
    						<g id="Group 3">
    							<path d="M9 11C6.79 11 5 9.21 5 7C5 4.79 6.79 3 9 3C11.21 3 13 4.79 13 7C13 9.21 11.21 11 9 11Z" />
    							<path d="M16 15.32C16 14.05 14.84 13 13.43 13L4.57 13C3.16 13 2 14.05 2 15.32L2 17L16 17L16 15.32Z" />
    							<path d="M17.4 5L15.11 2.71C14.96 2.57 14.96 2.33 15.11 2.19C15.25 2.04 15.49 2.04 15.63 2.19L17.37 3.93L19.94 1.12C20.07 0.97 20.31 0.96 20.46 1.1C20.61 1.23 20.62 1.47 20.48 1.62L17.4 5Z" />
    						</g>
    					</g>
    				</g>
    			</g>
    		</g>
    	</g>
    </svg>
  )
}

export default renderFollow;
