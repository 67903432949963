import React from 'react';
import render from 'preact-render-to-string';
import { h } from 'preact';

var renderCommentFullIcon = function() {
	return(
		<svg xmlns="http://www.w3.org/2000/svg" width="25" height="21" viewBox="0 0 25 21">
		  <path fillRule="evenodd" stroke="#1e3646" strokeWidth="1.2" d="M8.36855,18.9639 C8.1909,18.89075 8.075,18.7169 8.075,18.525 L8.075,15.2 L7.6,15.2 C3.40955,15.2 0,11.79045 0,7.6 C0,3.40955 3.40955,0 7.6,0 L15.2,0 C19.39045,0 22.8,3.40955 22.8,7.6 C22.8,11.79045 19.39045,15.2 15.2,15.2 L12.54665,15.2 L8.88535,18.8613 C8.7951,18.95155 8.6735,19 8.55,19 C8.48825,19 8.42745,18.9886 8.36855,18.9639 Z" transform="translate(1 1)"/>
		</svg>
  )
}

export default renderCommentFullIcon;
